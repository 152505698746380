<template>
  <div class="characters-slider">
    <CharactersSliderLoading
      v-if="loading"
      @click-outside="handleClickOutside"
      @close="handleClose"
    />

    <Swiper
      v-else-if="(items.length > 0)"
      ref="swiper"
      class="characters-slider__swiper"
      :options="swiperOptions"
    >
      <CharactersSliderSlide
        v-for="(item, index) in items"
        :key="item.id"
        :item="item"
        :index="index"
        :character="character"
        @click-outside="handleClickOutside"
        @close="handleClose"
      />
    </Swiper>


    <CharactersSliderEmpty
      v-else
      @click-outside="handleClickOutside"
      @close="handleClose"
    />

   <div v-show="!loading">
      <CharactersSliderControls :slides-count="items.length" />
      <CharactersSliderBullets :slides-count="items.length" />
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import CharactersSliderBullets from './CharactersSliderBullets.vue';
import CharactersSliderControls from './CharactersSliderControls.vue'
import CharactersSliderLoading from './CharactersSliderLoading.vue';
import CharactersSliderEmpty from './CharactersSliderEmpty.vue';
import CharactersSliderSlide from './CharactersSliderSlide.vue';

export default {
  name: "CharactersSlider",

  components: {
    CharactersSliderControls,
    CharactersSliderBullets,
    CharactersSliderLoading,
    CharactersSliderEmpty,
    CharactersSliderSlide
},

  props: {
    items: {
      type: Array,
      required: true
    },
    character: { type: Object, default: null },
    loading: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      swiperOptions: {
        slidesPerView: 1,
        loop: !this.loading,
        watchOverflow: true,
        navigation: {
          prevEl: ".characters-slider__prev",
          nextEl: ".characters-slider__next",
          disabledClass: "is-disabled"
        },
        pagination: {
          el: ".characters-slider__bullets",
          type: "bullets",
          bulletClass: "characters-slider__bullet",
          bulletActiveClass: "characters-slider__bullet_active",
          clickable: true
        }
      }
    }
  },

  methods: {
    handleClickOutside: _.debounce(function (e) {
      if (e.target.classList.contains("characters-slider__slide")) {
        this.$emit("click-outside");
        return;
      }
    }, 50),
    handleClose() {
      this.$emit("close");
    }
  }
}
</script>

<style lang="scss">
@import "../scss/base/includes.scss";

.characters-slider {
  position: relative;
  height: 100%;
  overflow: hidden;

  &__swiper {
    height: 100%;
  }

  &__message {
    width: 100vw;
    height: 100%;
    position: relative;
    z-index: 100;
  }

  &__empty-message {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-weight: 600;
    font-size: 18px;
    text-align: center;
  }

  &__slide {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;
  }

  &__slide-content {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: 836px;
    width: 576px;
    max-width: 100%;
    background: #FFFFFF;
    border-radius: 32px;
    padding: 30px 6px 20px 30px;
  }

  &__slide-image-container {
    flex-grow: 1;
    // overflow-y: auto;
    position: relative;
    padding-right: 24px;
  }

  &__slide-image {
    width: 100%;
    height: 729px;
    background: #000;
    border-radius: 16px;
    cursor: pointer;
    transition: filter 0.3s ease;

    &:hover {
      filter: brightness(0.95);
    }
  }

  &__slide-button-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 19px;
  }

  &__slide-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 143px;
    height: 38px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }

  @include mobile {
    &__slide-content {
      max-height: 540px;
      width: 345px;
      border-radius: 32px;
      padding: 18px 6px 28px 18px;
    }

    &__slide-image-container {
      padding-right: 12px;
    }

    &__slide-image {
      height: 437px;
    }
  }
}
</style>
