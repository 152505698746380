<template>
  <div :class="{'loading': store.isLoading}">
    <slot/>
  </div>
</template>

<script>
import { useRecipesStore } from "../stores/recipes";

export default {
  name: 'RecipesInner',
  setup() {
    return {
      store: useRecipesStore()
    }
  },
}
</script>
