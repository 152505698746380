<script>
import Vue from "vue";
import {useExpertsStore} from "../stores/experts";

export default {
  name: 'ExpertsMain',
  props: {},
  setup() {
    return {
      store: useExpertsStore()
    }
  },
  computed: {

  },
  methods: {
    wrapTemplate(template) {
      return `<experts-inner>${template}</experts-inner>`
    }
  },
  render(createElement) {
    return createElement(Vue.compile(
      this.store.template ?
        this.wrapTemplate(this.store.template) :
        this.wrapTemplate('<slot/>')), this.$slots.default)
  }
}
</script>

