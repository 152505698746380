<template>
  <div
    class="donut-chart"
    :class="[{ 'donut-chart_reverse': reverse }]"
    :style="`color: ${color};`"
  >
    <svg viewBox="0 0 36 36" class="donut-chart__circle">
      <circle
        class="donut-chart__bg"
        stroke-width="4.5"
        fill="none"
        cx="18"
        cy="18"
        r="15.8"
      ></circle>
      <circle
        class="donut-chart__line"
        stroke="currentColor"
        stroke-width="4.5"
        :stroke-dasharray="`${percentage}, 100`"
        stroke-linecap="round"
        fill="none"
        cx="18"
        cy="18"
        r="15.8"
      ></circle>
      <text x="18" y="21" class="donut-chart__percentage">
        {{ percentage }}%
      </text>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'DonutChart',
  props: {
    percentage: {
      type: Number,
      default: 50,
    },
    color: {
      type: String,
      default: '#0275D8',
    },
    reverse: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      text: '',
      inputValue: this.value,
      isOpen: false,
    };
  },
  methods: {},
};
</script>

<style lang="scss">
@import '../scss/base/includes.scss';

$b: '.donut-chart';

#{$b} {
  display: flex;

  &__circle {
    width: 100%;
    height: 100%;
  }

  &__bg {
    stroke: #ebebeb;
  }

  &__line {
    transform: rotate(-90deg);
    transform-origin: center;
    animation: progress 1s ease-out forwards;

    #{$b}_reverse & {
      transform: rotate(-90deg) scale(1, -1);
    }
  }

  &__percentage {
    font-family: 'Rotonda', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 0.5em;
    line-height: 1;
    fill: currentColor;
    text-anchor: middle;

    @include mobile {
      font-size: 0.66em;
    }
  }

  @keyframes progress {
    0% {
      stroke-dasharray: 0 100;
    }
  }
}
</style>
