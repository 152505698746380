<template>
  <div class="generator-start-buttons">
    <a :href="`${baseLink}/options/`" class="button button_small"
      >Выбрать имя</a
    >
    <p>или</p>
    <ul class="generator-start-buttons-group">
      <li>
        <a
          :href="`${baseLink}/popular/`"
          class="button button_small button_bordered"
          >Популярные имена</a
        >
      </li>
      <li>
        <a
          :href="`${baseLink}/store/`"
          class="button button_small button_bordered"
          >Энциклопедия имен</a
        >
      </li>
      <li v-if="hasFavorites">
        <a
          :href="`${baseLink}/user/`"
          class="button button_small button_bordered"
          >Понравившиеся имена</a
        >
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'GeneratorStartButtons',
  props: {
    baseLink: {
      type: String,
      default: '/services/name-generator',
    },
  },
  data() {
    return {
      userData: null,
    };
  },
  computed: {
    hasFavorites() {
      return (
        this.userData &&
        this.userData.favorites &&
        this.userData.favorites.length
      );
    },
  },
  created() {
    this.userData = this.getUserData();
  },
  methods: {
    getUserData() {
      return JSON.parse(localStorage.getItem('generatorUserData'));
    },
  },
};
</script>

<style lang="scss">
@import '../../scss/base/includes.scss';

$b: '.generator-start-buttons';

#{$b} {
  display: block;
  max-width: 500px;
  margin: 0 auto;

  &-group {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    & > li {
      margin: 0 8px 16px;

      @include mobile {
        margin: 0 0 16px;
      }
    }
  }

  .button {
    @include mobile {
      width: 100%;
    }
  }

  p {
    margin: 0;

    &:not(:first-child) {
      margin-top: 16px;
    }

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
}
</style>
