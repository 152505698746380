<script>
import Vue from "vue";
import { useRecipesStore } from "../stores/recipes";

export default {
  name: 'RecipesWrap',
  setup() {
    return {
      store: useRecipesStore()
    }
  },
  methods: {
    wrapTemplate(template) {
      return `<recipes-inner>${template}</recipes-inner>`
    }
  },
  render(createElement) {
    return createElement(Vue.compile(
      this.store.template ?
      this.wrapTemplate(this.store.template) :
      this.wrapTemplate('<slot/>')), this.$slots.default)
  }
}
</script>
