<template>
  <div class="composition-tooltip">
    <div class="composition-tooltip__wrapper">
      <div ref="tooltip" class="composition-tooltip__icon" @click="toggleMobileTooltip"></div>
    </div>
    <div v-if="showMobileTooltip" class="composition-tooltip__overlay" @click="hideTooltip"></div>
    <div v-if="showMobileTooltip" class="composition-tooltip__mobile">
      <button @click="hideTooltip" class="composition-tooltip__close">
        <span class="visually-hidden">Close</span>
      </button>
      {{ text }}
    </div>
  </div>
</template>

<style>
.composition-tooltip, .composition-tooltip__wrapper {
  display: inline-block;
}

.composition-tooltip__icon {
  position: relative;
  display: inline-block;
  width: 16px;
  height: 16px;
  background-color: #9FD4EF;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMiIgaGVpZ2h0PSIxMCIgdmlld0JveD0iMCAwIDIgMTAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxyZWN0IHk9IjQiIHdpZHRoPSIyIiBoZWlnaHQ9IjYiIHJ4PSIxIiBmaWxsPSJ3aGl0ZSIvPgo8cmVjdCB3aWR0aD0iMiIgaGVpZ2h0PSIyIiByeD0iMSIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==");
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 50%;
  vertical-align: middle;
  margin: -2px 2px 0 0;
  cursor: pointer;
  z-index: 2;
}

.composition-tooltip__icon:hover {
  background-color: #00B5ED;
}

.composition-tooltip__mobile {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  background-color: white;
  padding: 0px 30px 20px;
  text-align: left;
}

.composition-tooltip__mobile::before {
  content: '';
  position: absolute;
  bottom: 99%;
  left: 0;
  width: 100%;
  min-height: 40px;
  background-image: url("/frontend/assets/img/tooltip-top.svg");
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.composition-tooltip__mobile::after {
  content: '';
  position: absolute;
  top: -72px;
  left: 58px;
  width: 56px;
  height: 52px;
  background-image: url("/frontend/assets/img/cows-tooltip.webp");
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.composition-tooltip__close {
  position: absolute;
  top: -55px;
  right: 15px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background: none;
  border: none;
  font-size: 16px;
  box-shadow: 0px 4px 8px 0px rgba(0, 93, 170, 0.25);
  background-color: white;
  cursor: pointer;
}

.composition-tooltip__close::before,
.composition-tooltip__close::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 16px;
  height: 2px;
  background-color: #005DAA;
  transform-origin: center;
  border-radius: 10px;
}

.composition-tooltip__close::before {
  transform: translate(-50%, -50%) rotate(45deg);
}

.composition-tooltip__close::after {
  transform: translate(-50%, -50%) rotate(-45deg);
}

.composition-tooltip__overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 4;
}


.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: auto;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
}

.tooltip-open .other-products,
.tooltip-open .other-products-footer,
.tooltip-open .footer2,
.tooltip-open .shops,
.tooltip-open .product-card__content,
.tooltip-open .holder img {
  z-index: 0;
}

/* Tooltip.js styles */
.tippy-box[data-theme~='cloud'] {
  position: relative;
  padding: 30px 35px;
  filter: drop-shadow(0px 0px 10px rgba(159, 212, 239, 0.6));
}

.tippy-box[data-theme~='cloud']::before {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-image: url("/frontend/assets/img/cloud-tooltip-opt.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  z-index: 3;
}

.tippy-box[data-theme~='cloud'] .tippy-content {
  white-space: wrap;
  text-align: center;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  z-index: 4;
}

.tippy-box[data-theme~='cloud'][data-placement^='top'] > .tippy-arrow::before {
  border-top-color: #E4F9FF;
  transform: scale(1.5) translateY(-5px);
}

.tippy-box[data-theme~='cloud'][data-placement^='bottom'] > .tippy-arrow::before {
  border-bottom-color: #E4F9FF;
  transform: scale(5) translateY(7px);
}

</style>

<script>
import Vue from 'vue';
import tippy from 'tippy.js';
const EventBus = new Vue();

export default {
  name: 'ProductCompositionTooltip2',
  props: {
    text: { type: String, default: '' },
  },
  data() {
    return {
      screenWidth: window.innerWidth,
      showMobileTooltip: false,
    };
  },
  computed: {
    isMobile() {
      return this.screenWidth < 768;
    }
  },
  watch: {
    screenWidth() {
      this.initializeTooltip();
    }
  },
  methods: {
    checkScreenWidth() {
      this.screenWidth = window.innerWidth;
    },
    initializeTooltip() {
      if (!this.isMobile && !this.$refs.tooltip._tippy) {
        tippy(this.$refs.tooltip, {
          content: this.text,
          appendTo: () => document.body,
          trigger: 'click',
          theme: 'cloud',
          zIndex: 10,
        });
      } else if (this.isMobile && this.$refs.tooltip._tippy) {
        this.$refs.tooltip._tippy.destroy();
      }
    },
    toggleMobileTooltip() {
      if (this.isMobile) {
        if (this.showMobileTooltip) {
          this.showMobileTooltip = false;
          document.documentElement.classList.remove('tooltip-open');
        } else {
          EventBus.$emit('hideAllTooltips');
          this.showMobileTooltip = true;
          document.documentElement.classList.add('tooltip-open');
        }
      }
    },
    hideTooltip() {
      this.showMobileTooltip = false;
      document.documentElement.classList.remove('tooltip-open');
    }
  },
  mounted() {
    this.initializeTooltip();
    window.addEventListener('resize', this.checkScreenWidth);
    EventBus.$on('hideAllTooltips', this.hideTooltip);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkScreenWidth);
    if (this.$refs.tooltip._tippy) {
      this.$refs.tooltip._tippy.destroy();
    }
    EventBus.$off('hideAllTooltips', this.hideTooltip);
  }
}
</script>
