var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'catalog-filter__option',
    { 'catalog-filter__option_selected': _vm.isActiveCategory() },
    { 'catalog-filter__option--loading': _vm.catalog.isLoading }
  ]},[_c('div',{staticClass:"catalog-filter__checkbox",on:{"click":function($event){$event.preventDefault();return (function () { return _vm.toggleType(_vm.params, false); }).apply(null, arguments)}}}),_c('div',{class:[
      'catalog-filter__option-title',
      { 'catalog-filter__option-title_opened': _vm.opened } ]},[_c('a',{on:{"click":function($event){$event.preventDefault();return (function () { return _vm.toggleType(_vm.params, _vm.options.length !== 0); }).apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.title)+" "),(_vm.options.length !== 0)?_c('svg',{attrs:{"width":"11","height":"8","viewBox":"0 0 11 8","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M1.05273 1.71289L5.05273 5.71289L9.05273 1.71289","stroke":"#0275D8","stroke-width":"2","stroke-linecap":"round"}})]):_vm._e()])]),(_vm.opened && _vm.options.length !== 0)?_c('ul',{staticClass:"catalog-filter__options"},_vm._l((_vm.options),function(item,index){return _c('li',{key:("option" + index),class:[
        'catalog-filter__option',
        { 'catalog-filter__option_selected': _vm.isActive(item.params.section) }
      ]},[_c('a',{staticClass:"sss",attrs:{"href":item.url},on:{"click":function($event){$event.preventDefault();return (function () { return _vm.toggleSection(item.params.section); }).apply(null, arguments)}}},[_vm._v(" "+_vm._s(item.title)+" "),_c('span',{staticClass:"catalog-filter__option-box"},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"12","height":"10","viewBox":"0 0 12 10","fill":"none"}},[_c('path',{attrs:{"d":"M1 5.2441L3.5 7.7441C3.96017 8.20427 4.7065 8.20427 5.16667 7.7441L11 1.91077","stroke":"#00B5ED","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}})])])])])}),0):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }