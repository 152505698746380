<script>
import Vue from "vue";
import { useEncyclopediaStore } from "../stores/encyclopedia";

export default {
  name: 'EncyclopediaArticlesWrap',
  setup() {
    return {
      store: useEncyclopediaStore()
    }
  },
  methods: {
    wrapTemplate(template) {
      return `<encyclopedia-articles-inner>${template}</encyclopedia-articles-inner>`
    }
  },
  render(createElement) {
    return createElement(Vue.compile(
      this.store.template ?
      this.wrapTemplate(this.store.template) :
      this.wrapTemplate('<slot/>')), this.$slots.default)
  }
}
</script>
