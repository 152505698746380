<template>
  <form action="" class="form form_question" @submit.prevent.stop="submit" ref="form">
    <div class="form__row">
      <div class="form__field">
        <span class="form__label">Текст сообщения*</span>
        <textarea name="text" :class="{ '_error': $v.form.text.$error }" v-model="form.text"></textarea>
      </div>
    </div>
    <div class="form__row form__row_flex">
      <div class="form__field">
        <span class="form__label">Имя*</span>
        <input type="text" name="name" placeholder="Введите ваше имя" :class="{ '_error': $v.form.name.$error }"
               v-model="form.name" @keypress="isLetter">
      </div>
      <div class="form__field">
        <span class="form__label">Почта*</span>
        <input type="text" name="email" placeholder="Введите email" :class="{ '_error': $v.form.email.$error }"
               v-model="form.email">
      </div>
    </div>
    <div class="form__row form__row_flex" v-if="captchaCode.length">
      <div class="form__field">
        <div class="form__captcha">
          <img :src="'/bitrix/tools/captcha.php?captcha_sid=' + captchaCode" alt="CAPTCHA"/>
        </div>
      </div>
      <div class="form__field">
        <input type="text" name="captcha" placeholder="Введите символы с картинки"
               :class="{ '_error': $v.form.captcha.$error }" v-model="form.captcha">
      </div>
    </div>
    <div class="form__row form__row_center">
      <custom-checkbox
        label="Я согласен на обработку персональных данных"
        name="agree"
        v-model="form.agree"
        :v="$v.form.agree"
      >
      </custom-checkbox>
    </div>
    <div class="form__row form__row_center">
      <input type="submit" class="button button_small" value="Отправить">
    </div>
    <div class="popup popup_small popup_msg" v-if="showSuccessPopup">
      <div class="popup__box">
        <span class="popup__close" @click="showSuccessPopup = false;">
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 13L13 1.00036" stroke="#0275D8" stroke-width="2"/>
            <path d="M1 1L13 12.9996" stroke="#0275D8" stroke-width="2"/>
          </svg>
        </span>
        <h2 class="popup__title">СПАСИБО</h2>
        <p>Сообщение успешно отправлено!</p>
        <a href="" class="button button_small" @click.prevent="showSuccessPopup = false;">Закрыть</a>
      </div>
    </div>
  </form>
</template>

<script>
import {email, required, sameAs} from "vuelidate/lib/validators";
import axios from 'axios';

export default {
  name: 'QuestionForm',
  data() {
    return {
      form: {
        email: '',
        text: '',
        captcha: '',
        agree: false,
      },
      captchaCode: '',
      showSuccessPopup: false,
    }
  },
  validations: {
    form: {
      email: {required, email},
      name: {required},
      text: {required},
      captcha: {required},
      agree: {sameAs: sameAs(() => true)},
    }
  },
  computed: {},
  mounted() {
    this.getCaptcha();
  },
  methods: {
    async submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      const res = await this.send('feedback', this.getFormData());

      if (res !== false) {
        this.showSuccessPopup = true;
        this.resetForm();
      } else {
        alert('Ошибка!');
      }
    },
    getFormData() {
      const form = new FormData;

      form.append('email', this.form.email);
      form.append('text', this.form.text);
      form.append('name', this.form.name);
      form.append('captchaSid', this.captchaCode);
      form.append('captchaCode', this.form.captcha);

      return form;
    },
    resetForm() {
      this.form = {
        email: '',
        text: '',
        name: '',
      };
      this.$v.$reset();

      this.getCaptcha();
    },
    async getCaptcha() {
      const res = await this.send('getCaptchaCode');

      this.captchaCode = res.captchaCode;
    },
    async send(action, data) {
      try {
        const response = await axios({
          url: '/api/' + action + '/',
          method: 'POST',
          data: data,
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });

        if (response.data.status === 'error') {
          throw new Error(response.data.errors);
        }

        return response.data.data;
      } catch (e) {
        console.log('Error!', e);
        return false;
      }
    },
    isLetter(e) {
      let char = String.fromCharCode(e.keyCode);
      if(/^[A-Za-zА-Яа-я\s]+$/.test(char)) return true;
      else e.preventDefault();
    }
  }
}
</script>
