<template>
  <div v-if="isDisplay" class="popup popup-sale">
    <img src="/frontend/assets/img/sale-animal-end.png" alt="">
    <a
      class="popup-sale_close"
      href="#"
      @click.prevent="closePopup"
    >
      <svg viewBox="0 0 50 50">
        <path
          style="stroke:none;fill-rule:nonzero;fill-opacity:1;"
          d="M 7.71875 6.28125 L 6.28125 7.71875 L 23.5625 25 L 6.28125 42.28125 L 7.71875 43.71875 L 25 26.4375 L 42.28125 43.71875 L 43.71875 42.28125 L 26.4375 25 L 43.71875 7.71875 L 42.28125 6.28125 L 25 23.5625 Z M 7.71875 6.28125 "
        />
      </svg>
    </a>
  </div>
</template>

<script>
export default {
  name: 'PopupSaleEnd',
  data() {
    return {
      isDisplay: true,
    }
  },
  methods: {
    closePopup() {
      this.isDisplay = false;
    },
  }
}
</script>

<style lang="scss">
@import '../scss/base/includes.scss';

$b: '.popup-sale';

#{$b} {
  padding: 50px;

  @include mobile {
    padding: 0;
    align-items: center;
  }

  img {
    max-height: 100%;
  }

  &_close {
    width: 50px;
    height: 50px;
    position: fixed;
    top: 30px;
    right: 30px;

    svg {
      width: 100%;
      height: 100%;

      path {
        fill: #ffffff;
        transition: 0.3s ease;
      }
    }

    &:hover svg path {
      fill: #2E408D;
    }

    @include mobile {
      top: 15px;
      right: 15px;
      width: 30px;
      height: 30px;
    }
  }
}
</style>
