<template>
  <div class="expert-slider">
    <div v-if="!isDesktop" class="grid">
      <slot />
    </div>
    <div v-else class="slider">
      <swiper ref="Swiper" :options="swiperOptions">
        <slot />
      </swiper>
      <div class="slider__bullets expert-slider__bullets"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ExpertSlider",
  props: {
    dataArray: {
      type: Array,
      default: () => {return []}
    },
  },
  data() {
    return {
      swiperOptions: {
        slidesPerView: 1,
        spaceBetween: 20,
        watchOverflow: true,
        loopAdditionalSlides: 1,
        grid: {
          fill: 'row',
          rows: 3,
        },
        pagination: {
          el: '.expert-slider__bullets',
          type: "bullets",
          bulletClass: "slider__bullet",
          bulletActiveClass: "slider__bullet_active",
          clickable: true
        },
        breakpoints: {
          768: {
            slidesPerView: 2,
          },
          1024: {
            slidesPerView: 4,
          },
        }
      }
    };
  },
  computed: {
    swiper() {
      return this.$refs.Swiper.$swiper;
    },
    isDesktop: function () {
      return this.$vssWidth < 1280
    }
  },
};
</script>

