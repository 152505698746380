<template>
  <div>
    <div class="article__side-header">
      <h3 class="g-visible-tablet" @click="toggle">
        {{ title }}
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" :class="{'_flip': opened}">
          <path d="M2 4L6 8L10 4" stroke="#0275D8" stroke-width="2" stroke-linecap="round"/>
        </svg>
      </h3>
      <slot />
    </div>
    <div class="article__content-wrap scroll-container">
      <template v-if="contents.length > 0">
        <transition name="slide">
          <ul class="article__content-list" v-if="opened || !isTablet">
            <li v-for="(item, index) in contents" :key="`content-item${index}`" :class="[{'_current': item.link === currentLink}]">
              <a :href="`#${item.link}`" @click="chooseChapter($event, item.link)">
                {{ item.name }}
              </a>
            </li>
          </ul>
        </transition>
      </template>
      <div class="article__sharing">
        <div class="ya-share2" data-bare data-services="vkontakte,odnoklassniki,telegram"></div>
        <copy-link></copy-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ArticleContents',
  props: {
    title: {
      type: String,
      default: ''
    },
    contents: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      opened: false,
      currentLink: ''
    }
  },
  computed: {
    isTablet: function () {
      return this.$vssWidth < 1024
    },
  },
  watch: {

  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  mounted() {
    let sharingScript = document.createElement('script')
    sharingScript.setAttribute('src', 'https://yastatic.net/share2/share.js')
    document.head.appendChild(sharingScript)
  },
  methods: {
    toggle() {
      const scrollSide = document.querySelector('.article__side').getBoundingClientRect().y;
      if (scrollSide > 0) {
        window.scrollTo(0, scrollSide)
      }
      document.documentElement.classList.toggle('article_state_menu');

      this.opened = !this.opened;
    },
    chooseChapter(e, id) {
      if (this.isTablet) {
        e.preventDefault();
        const el = document.getElementById(id);

        this.opened = false;
        document.documentElement.classList.remove('article_state_menu');

        window.scrollTo(0, el.offsetTop);
      }
    },
    handleScroll() {
      this.contents.map(obj => {
        const el = document.getElementById(obj.link);
        if (el && this.isInViewport(el)) {
          this.currentLink = obj.link
        }
      })
    },
    isInViewport(el) {
      const rect = el.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)

      );
    }
  }
}
</script>
