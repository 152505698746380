<template>
  <div :class="{'products-main_state_load': store.isLoading}" class="products-main">
    <slot/>
  </div>
</template>

<script>
import {useProductsStore} from "../stores/products-main";

export default {
  name: 'ProductsInner',
  setup() {
    return {
      store: useProductsStore()
    }
  },
}
</script>
