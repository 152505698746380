<template>
  <div>
    <swiper ref="Swiper" :options="swiperOptions">
      <slot />
    </swiper>
    <div class="top-slider__controls" :class="{'g-hidden': slidesCount < 2}">
      <div class="top-slider__prev">
        <svg width="23" height="18" viewBox="0 0 23 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M21.9205 8.23102C22.3452 8.65571 22.3452 9.34428 21.9205 9.76898L14.9997 16.6898C14.575 17.1145 13.8865 17.1145 13.4618 16.6898C13.0371 16.2651 13.0371 15.5765 13.4618 15.1518L19.6136 9L13.4618 2.84817C13.0371 2.42347 13.0371 1.73491 13.4618 1.31021C13.8865 0.885518 14.575 0.885518 14.9997 1.31021L21.9205 8.23102ZM0.851562 7.9125L21.1516 7.9125L21.1516 10.0875L0.851563 10.0875L0.851562 7.9125Z" fill="black"/>
        </svg>
      </div>
      <div class="top-slider__next">
        <svg width="23" height="18" viewBox="0 0 23 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M21.9205 8.23102C22.3452 8.65571 22.3452 9.34428 21.9205 9.76898L14.9997 16.6898C14.575 17.1145 13.8865 17.1145 13.4618 16.6898C13.0371 16.2651 13.0371 15.5765 13.4618 15.1518L19.6136 9L13.4618 2.84817C13.0371 2.42347 13.0371 1.73491 13.4618 1.31021C13.8865 0.885518 14.575 0.885518 14.9997 1.31021L21.9205 8.23102ZM0.851562 7.9125L21.1516 7.9125L21.1516 10.0875L0.851563 10.0875L0.851562 7.9125Z" fill="black"/>
        </svg>
      </div>
    </div>
    <div
      :class="['top-slider__bullets', {'top-slider__bullets_hidden': slidesCount < 2}]"
    ></div>
  </div>
</template>

<script>
export default {
  name: "TopSlider",
  props: {
  },
  data() {
    return {
      slidesCount: 0,
      swiperOptions: {
        slidesPerView: 1,
        loop: true,
        watchOverflow: true,
        speed: 500,
        //autoplay: {
        //  delay: 4000,
        //  disableOnInteraction: false,
          //pauseOnMouseEnter: true
        //å},
        navigation: {
          prevEl: ".top-slider__prev",
          nextEl: ".top-slider__next",
          disabledClass: "is-disabled"
        },
        pagination: {
          el: ".top-slider__bullets",
          type: "bullets",
          bulletClass: "top-slider__bullet",
          bulletActiveClass: "top-slider__bullet_active",
          clickable: true
        },
        on: {
          afterInit: function () {
            // function getRandomInt(max) {
            //   return Math.floor(Math.random() * max);
            // }
            // this.slideTo(getRandomInt(this.slides.length), 0);
          }
        }
      }
    };
  },
  computed: {
  },
  mounted() {
    this.slidesCount = this.$el.childElementCount;
  },
  methods: {
  }
};
</script>

