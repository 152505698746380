<template>
  <div
    :class="['filter', 'filter_no-padding', 'filter--newborn', {'filter_closed': !opened}]"
  >
    <!-- <div v-if="isMobile" class="filter__toggler-alt" @click="toggle"></div> -->
    <div class="filter__inner">
      <h3 class="filter__heading">выберите <span>тему</span></h3>
      <transition name="slide">
        <div class="filter__theme" v-if="opened || !isMobile">
          <a v-for="(theme, index) in themes"
             :key="index"
             :class="[
               'filter__theme-item',
               'filter__theme-item_' + theme.code,
               {
                 'filter__theme-item_selected': checkSelected(theme.code)
               }
             ]"
             :href="`${url}?themes=${theme.code}`"
             @click.prevent="selectTheme(theme.code)"
          >
            {{ theme.name }}
          </a>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { useNewsStore } from '../stores/news';

export default {
  name: 'NewsFilter',
  setup() {
    return {
      store: useNewsStore()
    }
  },
  props: {
    themes: {
      type: Array,
      default: () => {return[]}
    },
    currentTheme: {
      type: String,
      default: 'all'
    },
    url: {
      type: String,
      required: true,
    },
    ajax_url: {
      type: String,
      required: true,
    },
    contentContainerId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      opened: true,
    }
  },
  created() {
    this.store.setAjaxLink(this.ajax_url)
  },
  computed: {
    isMobile: function () {
      return this.$vssWidth < 768
    },
    // currentTheme: function () {
    //   return this.newbornStore.currentTheme;
    // }
  },
  methods: {
    async selectTheme(selectedTheme) {
      const url = `${this.url}?themes=${selectedTheme}`;
      const ajaxUrl = `${this.ajax_url}?themes=${selectedTheme}`;

      this.store.update(url, ajaxUrl);
    },
    toggle() {
      if (this.isMobile) {
        this.opened = !this.opened;
      }
    },
    checkSelected(value) {
      return value === this.currentTheme;
    },
  },
}
</script>

<style lang="scss">
  .filter__theme
  {
    .loading &
    {
      pointer-events: none;

      opacity: .4;
    }
  }
</style>
