<script>
import Vue from "vue";
import {useProductsStore} from "../stores/products-main";

export default {
  name: 'ProductsMainWrap',
  setup() {
    return {
      store: useProductsStore()
    }
  },
  methods: {
    wrapTemplate(template) {
      return `<products-main-inner>${template}</products-main-inner>`
    }
  },
  render(createElement) {
    return createElement(Vue.compile(
      this.store.template ?
      this.wrapTemplate(this.store.template) :
      this.wrapTemplate('<slot/>')), this.$slots.default)
  }
}
</script>
