<template>
  <ul :class="['catalog-tags', {'catalog-tags--loading': catalog.isLoading}]">
    <li
      v-for="(item, index) in tags"
      :key="`tags${index}`"
      :class="[
        'catalog-tags__item',
        { 'catalog-tags__item_selected': isActive(item.alias) },
      ]"
    >
      <a @click.prevent="() => toggleTag(item.alias)">{{ item.title }}</a>
    </li>
  </ul>
</template>

<script>
import { useCatalogStore } from "../stores/catalog";

export default {
  name: "CatalogTags",
  setup() {
    return {
      catalog: useCatalogStore(),
    };
  },
  props: {
    tags: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      activeIndexes: [],
    };
  },
  computed: {},
  methods: {
    toggleTag(tag) {
      const tags = this.catalog.url.searchParams.get('tags')?.split(',') || [];
      const index = tags.indexOf(tag);

      if (index === -1) {
        tags.push(tag);
      } else {
        tags.splice(index, 1);
      }

      if(tags.length) {
        this.catalog.setSearchParams('tags', tags.join(','));
      } else {
        this.catalog.deleteSearchParams('tags');
      }

      this.catalog.deleteSearchParams('page');

      this.catalog.setHistory();
    },
    isActive(i) {
      const activeIndexes = this.catalog.url.searchParams.get('tags')?.split(',') || [];

      return activeIndexes.indexOf(i) !== -1;
    },
  },
};
</script>
