<!-- eslint-disable vue/require-v-for-key -->
<template>
  <div class="form__fileinput">
    <label :for="inputName">
      <input
        type="file"
        :name="inputName"
        :id="inputName"
        ref="fileInput"
        @change="getInputValue"
        multiple
      />
      <template v-if="files.length">
        <span v-for="(file, index) in this.files" class="form__fileinput-wrap">
          <span class="form__fileinput-file">
            <span class="form__fileinput-file-holder"
              ><span>{{ file.name }}</span></span
            >
            <a
              href=""
              v-if="value != ''"
              class="form__fileinput-delete"
              @click.prevent="clearInput(index)"
              title="Удалить"
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M18 6L6 18"
                  stroke="#00B5ED"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M6 6L18 18"
                  stroke="#00B5ED"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                /></svg
            ></a>
          </span>
        </span>
      </template>

      <span class="form__fileinput-button">Выбрать</span>
    </label>
  </div>
</template>

<script>
export default {
  name: "FileInput",
  props: {
    inputName: {
      type: String,
      default: "file",
    },
  },
  data() {
    return {
      files: [],
    };
  },
  methods: {
    getInputValue() {
      for (let key in this.$refs.fileInput.files) {
        if (typeof this.$refs.fileInput.files[key] == "object") {
          this.files.push(this.$refs.fileInput.files[key]);
        }
      }
      this.$emit("get-files", this.files);
    },
    clearInput(index) {
      this.files.splice(index, 1);
      this.$emit("get-files", this.files);
    },
  },
};
</script>
