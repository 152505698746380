<template>
  <div :class="{'loading': store.isLoading}">
    <slot/>
  </div>
</template>

<script>
import {useExpertsStore} from "../stores/experts";

export default {
  name: 'ExpertsInner',
  setup() {
    return {
      store: useExpertsStore()
    }
  },
}
</script>
