<template>
  <div :class="{'products-main_state_load': store.isLoading}" class="products-main">
    <slot/>
  </div>
</template>

<script>
import {useArticlesStore} from "../stores/articles-main";

export default {
  name: 'ArticlesInner',
  setup() {
    return {
      store: useArticlesStore()
    }
  },
}
</script>
