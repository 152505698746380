<template>
  <div class="articles-slider">
    <h2 class="content-section__title">Полезные статьи</h2>
    <div class="articles-slider__back">
      <inline-svg src="/frontend/assets/img/articles-back.svg"></inline-svg>
    </div>
    <div class="products-main-filter">
      <div
        v-for="(item, key, index) in filterSegments"
        :key="`option${index}`"
        :class="['products-main-filter__tab', {'products-main-filter__tab_active': item.selected}]"
        @click="setParam('segment', item.code, true, true)"
      >
        <inline-svg
          :src="`/frontend/assets/${item.icon}`"
        ></inline-svg>
        <p>{{ item.name }}</p>
      </div>
    </div>

    <div class="products-main-filter products-main-filter--mobile">
      <div @click="openModal" class="products-main-filter__btn" >
        <div>Возраст: <span>{{ activeFilterName }}</span></div>
        <div class="products-main-filter__close">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="none">
            <path d="M5 13L10 8L5 3" stroke="#005DAA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
      </div>
    </div>
    <div v-if="isOpenModal" class="products-main-filter__modal">
      <div class="products-main-filter__modal-body">
        <div class="products-main-filter__header">
          Выберите возраст
          <button @click="closeModal" class="products-main-filter__modal-close">
            <svg

              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M18 6L6 18"
                stroke="#BDBDBD"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M6 6L18 18"
                stroke="#BDBDBD"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </div>
        <div class="products-main-filter__holder">
          <ul class="products-main-filter__radios">
            <li
              v-for="(item, key, index) in filterSegments"
              :key="`option${index}`"
              @click="setParam('segment', item.code, true, true)"
            >
              <input
                type="radio"
                :id="`products-main-filter-${index + 1}`"
                name="products-main-filter"
                :checked="item.selected"
              />
              <label :for="`products-main-filter-${index + 1}`">{{ item.name }}</label>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <slider-main-article
      id="articles_main"
      class="products-main__slider"
      :items="items"
      :key="`slider-contnent${activeTab}`"
    >
      <slot :name="`tab-content${activeTab + 1}`" />
    </slider-main-article>
    <div class="articles-slider__more">
      <a href="/calendopedia/" class="btn">Смотреть всё</a>
    </div>
    <img
      class="articles-slider__art_1"
      srcset="/frontend/assets/img/articles-slider__art_1.webp, /frontend/assets/img/articles-slider__art_1@2x.webp 2x"
      src="/frontend/assets/img/articles-slider__art_1.webp"
      alt="ягода"
      loading="lazy"
    >
    <img
      class="articles-slider__art_2"
      srcset="/frontend/assets/img/articles-slider__art_2.webp, /frontend/assets/img/articles-slider__art_2@2x.webp 2x"
      src="/frontend/assets/img/articles-slider__art_2.webp"
      alt="ягода"
      loading="lazy"
    >
  </div>
</template>

<script>
// import axios from "axios";
// import Vue from "vue";
import { useArticlesStore } from "../stores/articles-main";
import Slider from "./SliderMainProduct";

export default {
  name: "Slider",
  setup() {
    return {
      articles: useArticlesStore(),
    };
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    Slider,
  },

  props: {
    currentFilters: {
      type: Object,
      default: () => {return{}}
    },
    filterSegments: {
      type: Object,
      default: () => {return{}},
    },
    catalogLink: {
      type: String,
      default: undefined,
    },
    items: {
      type: Number,
      default: 4,
    },
    url: {
      type: String,
      required: true,
    },
    contentContainerId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      isOpenModal: false,
      activeTab: 0,
      params: this.currentFilters
    };
  },

  mounted() {
    this.resizeHandler();
    const observer = new ResizeObserver(() => {
      this.resizeHandler();
    });
    if(this.$refs.mainWrap)
      observer.observe(this.$refs.mainWrap.$el);
  },

  computed: {
    isTablet() {
      return this.$vssWidth < 1024;
    },
    activeFilterName() {
      let name = '';

      for (const key in this.filterSegments) {
        if(this.filterSegments[key].selected) {
          name = this.filterSegments[key].name

          break;
        }
      }

      return name;
    },
  },

  methods: {
    resizeHandler() {
      if(this.$refs.mainWrap) {
        this.isScroll =
          this.$refs.mainWrap.$el.children[0].getBoundingClientRect().width >
          this.$refs.mainWrap.$el.getBoundingClientRect().width;
        this.$refs.mainWrap.update();
      }
    },
    setParam(category, value, isRadio, mobileReload = false) {
      if (isRadio) {
        this.params[category] = value;

        if (value === 'all') {
          delete this.params[category];
        }
      } else {
        if (value === 'all' || this.params[category] === undefined) {
          delete this.params[category];
        } else {
          let categorySet = new Set(this.params[category].split(','));
          categorySet.delete('all');
          if (categorySet.has(value)) {
            categorySet.delete(value);
            if (categorySet.size === 0) {
              categorySet.add('all');
            }
          } else {
            categorySet.add(value);
          }
          this.params[category] = [...categorySet].join(',');
        }
      }

      this.queryString = Object.keys(this.params).map(key => key + '=' + this.params[key]).join('&');
      if (mobileReload) {
        // this.setQueryString()
        this.reloadBlock()
      }

      this.closeModal();
    },
    setQueryString() {
      window.location.assign(`?${this.queryString}`);
    },
    async reloadBlock() {
      const url = `${this.url}?${this.queryString}`;

      this.articles.update(url);
      // this.articles.update(url, ajaxUrl);

      // try {
      //   const response = await axios.get(url);

      //   let AjaxComponent = Vue.extend({
      //     template: response.data
      //   })
      //   let compiled = new AjaxComponent().$mount();

      //   const container = document.getElementById(this.contentContainerId);
      //   container.innerHTML = compiled.$el.outerHTML;
      // }
      // catch (error) {
      //   console.log(error);
      // }
    },
    openModal() {
      this.isOpenModal = true;

      const html = document.querySelector('html');
      const scroll = window.pageYOffset;

      html.classList.add('html_state_age-filter');
      html.style.top = `${ -scroll }px`;
    },
    closeModal() {
      this.isOpenModal = false;

      const html = document.querySelector('html');
      const scroll = Math.abs(parseInt(html.style.top));

      html.classList.remove('html_state_age-filter');
      html.style.top = 0;

      if(scroll) {
        window.scrollTo({
          top: scroll,
          left: 0,
          behavior: 'instant',
        });
      }
    },
  }
};
</script>
