<template>
  <div :class="[{'slider': `slider-${id}`}, {'slider_no-next-visible': !visibleNext}]">
    <swiper ref="Swiper" :options="swiperOptions">
      <slot />
    </swiper>
    <div class="slider__controls" :class="{'': slidesCount <= items}">
      <div :class="['slider__prev', `slider-${id}__prev`]">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path d="M21 12L3 12" stroke="#005DAA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M10 19L3 12L10 5" stroke="#005DAA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
      <div :class="['slider__next', `slider-${id}__next`]">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path d="M3 12L21 12" stroke="#005DAA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M14 19L21 12L14 5" stroke="#005DAA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Slider",
  props: {
    id: {
      type: String,
      default: '0',
    },
    items: {
      type: Number,
      default: 3,
    },
    visibleNext: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      slidesCount: 0,
      swiperOptions: {
        slidesPerView: 1,
        spaceBetween: 8,
        watchOverflow: true,
        loopAdditionalSlides: 1,
        loop: true,
        navigation: {
          prevEl: `.slider-${this.id}__prev`,
          nextEl: `.slider-${this.id}__next`,
          disabledClass: "is-disabled"
        },
        breakpoints: {
          768: {
            spaceBetween: 24,
            slidesPerView: 3,
          },

          1366: {
            spaceBetween: 24,
            slidesPerView: this.items,
          },
        }
      }
    };
  },
  computed: {
    swiper() {
      return this.$refs.Swiper.$swiper;
    },
    isMobile: function () {
      return this.$vssWidth < 768
    }
  },
  mounted() {
    this.slidesCount = this.$refs.Swiper.$children.length;
  }
};
</script>

