<template>
  <Portal>
    <div class="characters-popup">
      <Transition name="fade">
        <div 
          v-if="opened"
          class="characters-popup__backdrop"
        ></div>
      </Transition>

      <div v-if="opened" class="characters-popup__content">
        <div class="characters-popup__panel">
          <CharactersSlider 
            :items="items"
            :loading="loading"
            :character="character"
            @click-outside="close"
            @close="close"
          />
        </div>
      </div>
    </div>
  </Portal>
</template>

<script>
import axios from "axios";
import { Portal } from "@linusborg/vue-simple-portal";
import CharactersSlider from './CharactersSlider.vue';

export default {
  name: "CharactersPopup",

  components: { Portal, CharactersSlider },

  props: {
    opened: { type: Boolean, default: false },
    character: { type: Object, default: null },
  },

  data() {
    return {
      loading: false,
      items: []
    }
  },

  watch: {
    character(value) {
      if (value) {
        this.loadVerses()
      }
    }
  },

  methods: {
    async loadVerses() {
      try {
        this.loading = true;
        const { status, data } = await axios.get(this.character['action-get-verses']);

        if (status === 200) {
          this.items = data.imgs_paths || [];
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    close() {
      this.$emit("close");
    }
  }
}
</script>

<style lang="scss">
@import "../scss/base/includes.scss";

.characters-popup {
  position: fixed;
  z-index: 999;

  &__backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.6);
  }

  &__content {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  &__panel {
    display: flex;
    align-items: center;
    height: 100%;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
}
</style>
