<template>
  <div :class="{'loading': store.isLoading}">
    <slot/>
  </div>
</template>

<script>
import { useNewsStore } from "../stores/news";

export default {
  name: 'NewsInner',
  setup() {
    return {
      store: useNewsStore()
    }
  },
}
</script>
