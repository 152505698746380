<template>
  <div class="generator-catalog">
    <div class="generator-catalog__body">
      <div class="generator-catalog__main">
        <ul class="generator-catalog__buttons">
          <li v-if="isStorePage">
            <a
              :href="`${baseLink}/popular/`"
              class="button button_small button_bordered"
              >Популярные имена</a
            >
          </li>
          <li v-else-if="!isInfoPage">
            <a
              :href="`${baseLink}/store/`"
              class="button button_small button_bordered"
              >Энциклопедия имен</a
            >
          </li>
          <li>
            <a :href="`${baseLink}/options/`" class="button button_small"
              >К выбору имени</a
            >
          </li>
        </ul>
        <ul v-if="filteredNames.length" class="generator-catalog-list">
          <li v-for="(item, i) in filteredNames" :key="i">
            <div v-if="!isTablet" class="generator-catalog-item">
              <div class="generator-catalog-item__head">
                <div class="generator-catalog-item__name">{{ item.name }}</div>
                <div
                  v-if="isUserPage || isInfoPage"
                  class="generator-catalog-item__favorite"
                  title="Нравится"
                  @click.prevent="toggleFavorite(item.id)"
                >
                  <template v-if="isFavorite(item.id)">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xml:space="preserve"
                      viewBox="0 0 31 28"
                    >
                      <path
                        fill="#FC3E48"
                        d="M15.5 28c-.4 0-.8-.1-1.1-.3l-.8-.5s-3.2-2.1-6.5-5.1c-1.9-1.8-3.5-3.6-4.6-5.3-.7-1.1-1.3-2.2-1.7-3.3C.3 12.3 0 11 0 9.8 0 8.5.3 7.2.8 6 1.3 4.8 2 3.7 2.9 2.8c.9-.9 2-1.6 3.1-2.1 2.9-1.2 6.3-.9 9 .7.1.1.3.2.4.3.1-.1.3-.2.4-.3C17.4.4 19.2-.1 21-.1c1.3 0 2.6.3 3.8.8 1.2.5 2.2 1.2 3.1 2.1.9.9 1.6 1.9 2.1 3.1.7 1.3 1 2.5 1 3.9 0 1.2-.3 2.5-.7 3.8-.4 1.1-1 2.1-1.7 3.3-1.1 1.7-2.7 3.5-4.6 5.3-3.2 2.9-6.3 5-6.5 5.1l-.8.5c-.4.1-.8.2-1.2.2z"
                      />
                    </svg>
                  </template>
                  <template v-else>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xml:space="preserve"
                      viewBox="0 0 31 28"
                    >
                      <path
                        fill="#2e408d"
                        d="M15.5 28c-.4 0-.8-.1-1.1-.3l-.8-.5s-3.2-2.1-6.5-5.1c-1.9-1.8-3.5-3.6-4.6-5.3-.7-1.1-1.3-2.2-1.7-3.3C.3 12.3 0 11 0 9.8 0 8.5.3 7.2.8 6 1.3 4.8 2 3.7 2.9 2.8c.9-.9 2-1.6 3.1-2.1 2.9-1.2 6.3-.9 9 .7.1.1.3.2.4.3.1-.1.3-.2.4-.3C17.4.4 19.2-.1 21-.1c1.3 0 2.6.3 3.8.8 1.2.5 2.2 1.2 3.1 2.1.9.9 1.6 1.9 2.1 3.1.7 1.3 1 2.5 1 3.9 0 1.2-.3 2.5-.7 3.8-.4 1.1-1 2.1-1.7 3.3-1.1 1.7-2.7 3.5-4.6 5.3-3.2 2.9-6.3 5-6.5 5.1l-.8.5c-.4.1-.8.2-1.2.2zM9.9 2c-1.1 0-2.1.2-3.1.6-.9.4-1.8 1-2.5 1.7S3 5.8 2.6 6.7c-.4 1-.6 2-.6 3s.2 2 .6 3.1c.4.9.9 1.9 1.5 2.9 1.1 1.6 2.5 3.2 4.3 4.9 3.1 2.9 6.1 4.8 6.2 4.8l.8.5.9-.5c.1-.1 3.1-2 6.2-4.8 1.8-1.7 3.3-3.3 4.3-4.9.7-1 1.2-1.9 1.5-2.9.4-1.1.6-2.1.6-3.1s-.2-2.1-.6-3c-.4-.9-1-1.8-1.7-2.5-.6-.6-1.5-1.2-2.4-1.6-1-.4-2-.6-3-.6-1.5 0-2.9.4-4.2 1.2-.3.2-.6.4-.8.6l-.6.5-.6-.5c-.3-.2-.5-.4-.8-.6C12.8 2.4 11.3 2 9.9 2z"
                      />
                    </svg>
                  </template>
                </div>
              </div>
              <div class="generator-catalog-item__desc">
                <div class="generator-catalog-item__text" v-html="item.desc" />
              </div>
              <div class="generator-catalog-item__bottom">
                <a :href="item.detail_url" class="generator-catalog-item__more">
                  Подробнее
                </a>
              </div>
            </div>
            <a
              v-else
              :href="item.detail_url"
              class="generator-catalog-mobile-item"
            >
              <div class="generator-catalog-mobile-item__head">
                <div class="generator-catalog-mobile-item__icon _favorite">
                  <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" id="Слой_1" x="0" y="0" version="1.1" viewBox="0 0 26 26">
                    <path d="M13 26C5.8 26 0 20.2 0 13S5.8 0 13 0s13 5.8 13 13-5.8 13-13 13zm0-24C6.9 2 2 6.9 2 13s4.9 11 11 11 11-4.9 11-11S19.1 2 13 2z" fill="#27ae60"/>
                    <path d="M13.7 6.8v-2h-2.1v2h2.1zm-.2 2.7h-1.8v11.1h1.8V9.5z" fill="#27ae60"/>
                  </svg>
                </div>
                <div
                  v-if="isUserPage"
                  class="generator-catalog-mobile-item__icon _favorite"
                  @click.prevent="toggleFavorite(item.id)"
                >
                  <template v-if="isFavorite(item.id)">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xml:space="preserve"
                      viewBox="0 0 31 28"
                    >
                      <path
                        fill="#FC3E48"
                        d="M15.5 28c-.4 0-.8-.1-1.1-.3l-.8-.5s-3.2-2.1-6.5-5.1c-1.9-1.8-3.5-3.6-4.6-5.3-.7-1.1-1.3-2.2-1.7-3.3C.3 12.3 0 11 0 9.8 0 8.5.3 7.2.8 6 1.3 4.8 2 3.7 2.9 2.8c.9-.9 2-1.6 3.1-2.1 2.9-1.2 6.3-.9 9 .7.1.1.3.2.4.3.1-.1.3-.2.4-.3C17.4.4 19.2-.1 21-.1c1.3 0 2.6.3 3.8.8 1.2.5 2.2 1.2 3.1 2.1.9.9 1.6 1.9 2.1 3.1.7 1.3 1 2.5 1 3.9 0 1.2-.3 2.5-.7 3.8-.4 1.1-1 2.1-1.7 3.3-1.1 1.7-2.7 3.5-4.6 5.3-3.2 2.9-6.3 5-6.5 5.1l-.8.5c-.4.1-.8.2-1.2.2z"
                      />
                    </svg>
                  </template>
                  <template v-else>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xml:space="preserve"
                      viewBox="0 0 31 28"
                    >
                      <path
                        fill="#2e408d"
                        d="M15.5 28c-.4 0-.8-.1-1.1-.3l-.8-.5s-3.2-2.1-6.5-5.1c-1.9-1.8-3.5-3.6-4.6-5.3-.7-1.1-1.3-2.2-1.7-3.3C.3 12.3 0 11 0 9.8 0 8.5.3 7.2.8 6 1.3 4.8 2 3.7 2.9 2.8c.9-.9 2-1.6 3.1-2.1 2.9-1.2 6.3-.9 9 .7.1.1.3.2.4.3.1-.1.3-.2.4-.3C17.4.4 19.2-.1 21-.1c1.3 0 2.6.3 3.8.8 1.2.5 2.2 1.2 3.1 2.1.9.9 1.6 1.9 2.1 3.1.7 1.3 1 2.5 1 3.9 0 1.2-.3 2.5-.7 3.8-.4 1.1-1 2.1-1.7 3.3-1.1 1.7-2.7 3.5-4.6 5.3-3.2 2.9-6.3 5-6.5 5.1l-.8.5c-.4.1-.8.2-1.2.2zM9.9 2c-1.1 0-2.1.2-3.1.6-.9.4-1.8 1-2.5 1.7S3 5.8 2.6 6.7c-.4 1-.6 2-.6 3s.2 2 .6 3.1c.4.9.9 1.9 1.5 2.9 1.1 1.6 2.5 3.2 4.3 4.9 3.1 2.9 6.1 4.8 6.2 4.8l.8.5.9-.5c.1-.1 3.1-2 6.2-4.8 1.8-1.7 3.3-3.3 4.3-4.9.7-1 1.2-1.9 1.5-2.9.4-1.1.6-2.1.6-3.1s-.2-2.1-.6-3c-.4-.9-1-1.8-1.7-2.5-.6-.6-1.5-1.2-2.4-1.6-1-.4-2-.6-3-.6-1.5 0-2.9.4-4.2 1.2-.3.2-.6.4-.8.6l-.6.5-.6-.5c-.3-.2-.5-.4-.8-.6C12.8 2.4 11.3 2 9.9 2z"
                      />
                    </svg>
                  </template>
                </div>
              </div>
              <div class="generator-catalog-mobile-item__body">
                <div class="generator-catalog-mobile-item__name">
                  {{ item.name }}
                </div>
              </div>
            </a>
          </li>
        </ul>
        <div v-else>
          <div class="generator-catalog__not-found">
            К сожалению ничего не найдено...
          </div>
        </div>
      </div>
      <div class="generator-catalog__sidebar">
        <generator-filter
          :names="names"
          @setFilter="setFilter"
          @updateFilter="updateFilter"
          @resetFilter="resetFilter"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GeneratorCatalog',
  props: {
    baseLink: {
      type: String,
      default: '',
    },
    initNames: {
      type: Array,
      default: () => [],
    },
    isPopularPage: {
      type: Boolean,
      default: false,
    },
    isInfoPage: {
      type: Boolean,
      default: false,
    },
    isStorePage: {
      type: Boolean,
      default: false,
    },
    isUserPage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      names: [],
      filters: null,
      userData: null,
    };
  },
  created() {
    this.userData = this.getUserData();
  },
  computed: {
    isTablet() {
      return this.$vssWidth < 1024;
    },
    filteredNames() {
      return this.filters && this.names
        ? this.names.filter((item) => {
            const firstLetter = item.name.charAt(0).toLowerCase();

            return (
              (!this.filters.gender ||
                this.filters.gender === 'unknown' ||
                item.gender === this.filters.gender) &&
              (!this.filters.letter || firstLetter === this.filters.letter) &&
              (!this.filters.origin ||
                item.origins.some(
                  (origin) => origin.value === this.filters.origin
                )) &&
              (!this.filters.theme ||
                item.themes.some((theme) => theme.value === this.filters.theme))
            );
          })
        : this.names;
    },
  },
  mounted() {
    this.names = this.isUserPage ? this.getUserNames() : this.initNames;
  },
  methods: {
    getUserNames() {
      return this.initNames.filter((item) =>
        this.userData.favorites.includes(item.id)
      );
    },
    getUserData() {
      return JSON.parse(localStorage.getItem('generatorUserData'));
    },
    setFilter(filters) {
      this.filters = { ...filters };
    },
    updateFilter(filters) {
      this.filters = { ...filters };
    },
    resetFilter() {
      this.filters = null;
    },
    setStorage(obj) {
      localStorage.setItem('generatorUserData', JSON.stringify(obj));
    },
    isFavorite(currentId) {
      return this.userData && this.userData.favorites
        ? this.userData.favorites.some((id) => currentId === id)
        : false;
    },
    toggleFavorite(id) {
      this.userData = this.getUserData() || {};
      this.userData.favorites = this.userData.favorites || [];

      if (this.userData.favorites.includes(id)) {
        this.userData.favorites = this.userData.favorites.filter(
          (item) => item !== id
        );
      } else {
        this.userData.favorites.push(id);
      }

      this.setStorage(this.userData);
    },
  },
};
</script>

<style lang="scss">
@import '../../scss/base/includes.scss';

$b: '.generator-catalog';

#{$b} {
  padding-bottom: 100px;

  @include tablet {
    padding-bottom: 50px;
  }

  &__body {
    display: flex;

    @include tablet {
      flex-direction: column;
    }
  }

  &__sidebar {
    width: 100% / 3 * 1;

    @include tablet {
      order: -1;
      width: 100%;
      margin-bottom: 32px;
    }
  }

  &__main {
    width: 100% / 3 * 2;
    padding-right: 44px;

    @include tablet {
      width: 100%;
      padding: 0;
      display: flex;
      flex-direction: column;
    }
  }

  &__buttons {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;

    @include tablet {
      order: 99;
      margin: 32px 0 0;
      flex-direction: column;
    }

    &:not(:last-child) {
      margin-bottom: 24px;

      @include tablet {
        margin-bottom: 0;
      }
    }

    & > li {
      margin: 0 16px 16px 0;

      @include tablet {
        margin: 0 0 16px;
      }

      & > * {
        @include tablet {
          width: 100%;
        }
      }
    }
  }

  &-list {
    list-style: none;
    padding: 0;
    margin: 0;

    & > li {
      &:not(:last-child) {
        margin-bottom: 48px;

        @include tablet {
          margin-bottom: 24px;
        }
      }
    }
  }

  &-item {
    display: block;

    &__head {
      display: flex;
      align-items: center;

      &:not(:last-child) {
        margin-bottom: 24px;
      }

      & > * {
        &:not(:last-child) {
          margin-right: 32px;
        }
      }
    }

    &__name {
      display: block;
      font-family: 'PH', sans-serif;
      font-size: 48px;
      line-height: 1;
      font-weight: 600;
      color: $brand;
      margin: 0;
    }

    &__favorite {
      width: 26px;
      height: 26px;
      display: flex;
      align-items: center;
      justify-content: center;
      @include clickable;

      svg {
        width: 100%;
        height: 100%;
      }
    }

    &__desc {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 4;

      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }

    &__text {
      font-size: 18px;
      line-height: 1.66;
      color: $dark;

      @include tablet {
        font-size: 16px;
      }
    }

    &__bottom {
      display: flex;
    }

    &__more {
      cursor: pointer;
      display: inline-block;
      font-size: 18px;
      line-height: 1.66;
      font-weight: 600;
      color: $accent;
      text-decoration: underline;
      transition: color 0.3s ease;

      &:hover,
      &:focus {
        text-decoration: none;
        color: $dark;
      }
    }
  }

  &-mobile-item {
    display: block;
    padding: 32px;
    text-decoration: none;
    color: inherit;
    border-radius: 8px;
    border: 1px solid #E3F0FC;

    @include mobile {
      padding: 24px 16px;
    }

    &__head {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      &:not(:empty):not(:last-child) {
        margin-bottom: 16px;
      }

      & > * {
        &:not(:last-child) {
          margin-right: 24px;

          @include mobile {
            margin-right: 16px;
          }
        }
      }
    }

    &__icon {
      width: 26px;
      height: 26px;
      display: flex;
      align-items: center;
      justify-content: center;
      @include clickable;

      svg {
        width: 100%;
        height: 100%;
      }
    }

    &__body {
      &:not(:first-child) {
        padding-bottom: 24px;
      }
    }

    &__name {
      display: block;
      font-family: 'PH', sans-serif;
      text-align: center;
      font-size: 48px;
      line-height: 1;
      font-weight: 600;
      color: $brand;
      margin: 0;
    }
  }

  &__not-found {
    display: block;
    font-family: 'PH', sans-serif;
    font-size: 24px;
    line-height: 1;
    font-weight: 600;
    color: $brand;
    margin: 0;
  }
}
</style>
