<template>
  <div class="products-main2">
    <div
      :class="[
        'products-main__holder',
        { 'products-main__holder--not-found': !itemsExists },
      ]"
    >
      <div class="products-main__header">
        <inline-svg src="/frontend/assets/img/product-header.svg"></inline-svg>
      </div>
      <h2 class="content-section__title">Выбери свою Агушу</h2>
      <div class="products-main__back">
        <inline-svg src="/frontend/assets/img/product-back.svg"></inline-svg>
      </div>
      <div class="products-main-filter">
        <div
          v-for="(item, key, index) in filterSegments"
          :key="`option${index}`"
          :class="[
            'products-main-filter__tab',
            { 'products-main-filter__tab_active': item.selected },
          ]"
          @click="setParam('product_segment', item.code, true, true)"
        >
          <inline-svg :src="`/frontend/assets/${item.icon}`"></inline-svg>
          <p>{{ item.name }}</p>
        </div>
      </div>

      <div class="products-main-filter products-main-filter--mobile">
        <div @click="openModal" class="products-main-filter__btn">
          <div>Возраст: <span>{{ activeFilterName }}</span></div>
          <div class="products-main-filter__close">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M5 13L10 8L5 3"
                stroke="#005DAA"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
      </div>
      <div v-if="isOpenModal" class="products-main-filter__modal">
        <div class="products-main-filter__modal-body">
          <div class="products-main-filter__header">
            Выберите возраст
            <button @click="closeModal" class="products-main-filter__modal-close">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M18 6L6 18"
                  stroke="#BDBDBD"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M6 6L18 18"
                  stroke="#BDBDBD"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
          </div>
          <div class="products-main-filter__holder">
            <ul class="products-main-filter__radios">
              <li
                v-for="(item, key, index) in filterSegments"
                :key="`option${index}`"
                @click="setParam('product_segment', item.code, true, true)"
              >
                <input
                  type="radio"
                  :id="`products-main-filter-${index + 1}`"
                  name="products-main-filter"
                  :checked="item.selected"
                />
                <label :for="`products-main-filter-${index + 1}`">{{
                  item.name
                }}</label>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="products-main__top">
        <div class="products-main__tabs-arrows" v-if="!isMobile">
          <div
            v-if="isPrevVisible"
            class="products-main__tabs-arrow products-main__tabs-arrow--prev"
            ref="scrollTabsPrev"
            @click.prevent="slideTo()"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M2 8L14 8"
                stroke="#005DAA"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M9.33333 12.6666L14 7.99998L9.33333 3.33331"
                stroke="#005DAA"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div
            v-if="isNextVisible"
            class="products-main__tabs-arrow products-main__tabs-arrow--next"
            ref="scrollTabsNext"
            @click.prevent="slideTo('left')"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M2 8L14 8"
                stroke="#005DAA"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M9.33333 12.6666L14 7.99998L9.33333 3.33331"
                stroke="#005DAA"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
        <div class="products-main__tabs-wrap" ref="scrollTabsWrap">
          <div class="products-main__tabs" ref="scrollTabs">
            <div
              v-for="(item, index) in filterCategories"
              :key="`option${index}`"
              :class="[
                'products-main__tab',
                { 'products-main__tab_active': item.selected },
              ]"
              @click="
                item.type ? setParam('product_type', item.type, true, true) : setParam('product_section', item.section, true, true)
              "
            >
              {{ item.name }}
            </div>
            <!-- remove -->
            <!-- <div class="products-main__tab">Молочные коктейли</div> -->
          </div>
        </div>
      </div>
      <div class="holder" v-if="!itemsExists">
        <div class="products-main__not-found">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
          >
            <path
              d="M22.5 22L17.7779 17.2695M20.3947 10.9474C20.3947 13.3204 19.452 15.5961 17.7741 17.2741C16.0962 18.952 13.8203 19.8947 11.4474 19.8947C9.07437 19.8947 6.79857 18.952 5.12062 17.2741C3.44267 15.5961 2.5 13.3204 2.5 10.9474C2.5 8.57437 3.44267 6.29858 5.12062 4.62061C6.79857 2.94266 9.07437 2 11.4474 2C13.8203 2 16.0962 2.94266 17.7741 4.62061C19.452 6.29858 20.3947 8.57437 20.3947 10.9474Z"
              stroke="#217BC4"
              stroke-width="1.12885"
              stroke-linecap="round"
            />
            <path
              d="M14.5 8L8.5 14"
              stroke="#00B5ED"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M8.5 8L14.5 14"
              stroke="#00B5ED"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <p>
            Мы не нашли подходящие продукты, измените условия поиска: возраст или категорию товара.
          </p>
        </div>
      </div>
      <slot :name="`content-not_found`" v-if="!itemsExists" />
      <slider-main-product
        id="products_main"
        class="products-main__slider"
        :items="items"
        :key="`slider-contnent${activeTab}`"
      >
        <slot :name="`tab-content${activeTab + 1}`" />
      </slider-main-product>
      <div class="products-main__more">
        <a href="/products/" class="btn">Перейти в каталог</a>
      </div>
    </div>

    <div class="products-main-caption">
      <!-- v-if="isInfoVisible" -->
      <inline-svg
        src="/frontend/assets/img/products-main-caption-back.svg"
        class="products-main-caption__back"
      ></inline-svg>
      <inline-svg
        src="/frontend/assets/img/products-main-caption-back-mobile.svg"
        class="products-main-caption__back products-main-caption__back--mobile"
      ></inline-svg>
      <img
        class="products-main-caption__flower_2"
        srcset="
          /frontend/assets/img/products-main-caption__flower_2.webp,
          /frontend/assets/img/products-main-caption__flower_2@2x.webp 2x
        "
        src="/frontend/assets/img/products-main-caption__flower_2.webp"
        alt="цветок"
      />
      <div class="products-main-caption__img">
        <img
          srcset="
            /frontend/assets/img/agu-boy.webp,
            /frontend/assets/img/agu-boy@2x.webp 2x
          "
          src="/frontend/assets/img/agu-boy.webp"
          alt="малыш"
        />
      </div>
      <div class="products-main-caption__content">
        <div class="products-main-caption__title">
          {{ productInfoTitle }}
        </div>
        <p class="products-main-caption__text">
          {{ productInfoDescription }}
        </p>
      </div>
    </div>
    <div class="products-main__bottom-back"></div>
  </div>
</template>

<script>
import { useProductsStore } from "../stores/products-main";
// import axios from "axios";
// import Vue from "vue";
import Slider from "./SliderMainProduct";

export default {
  name: "Slider",
  setup() {
    return {
      products: useProductsStore(),
    };
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    Slider,
  },

  props: {
    currentFilters: {
      type: Object,
      default: () => {
        return {};
      },
    },
    filterCategories: {
      type: Object,
      default: () => {
        return {};
      },
    },
    filterSegments: {
      type: Object,
      default: () => {
        return {};
      },
    },
    catalogLink: {
      type: String,
      default: undefined,
    },
    productInfoTitle: {
      type: String,
      default: undefined,
    },
    productInfoDescription: {
      type: String,
      default: undefined,
    },
    items: {
      type: Number,
      default: 4,
    },
    itemsExists: {
      type: Boolean,
      default: false,
    },
    url: {
      type: String,
      required: true,
    },
    contentContainerId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      isOpenModal: false,
      activeTab: 0,
      params: this.currentFilters,
      isPrevVisible: false,
      isNextVisible: true,
      tabsWrapper: 0,
      tabsInner: 0,
    };
  },

  created() {
    window.addEventListener("resize", this.handleResize);
  },

  mounted() {
    this.resizeHandler();
    const observer = new ResizeObserver(() => {
      this.resizeHandler();
    });
    if (this.$refs.mainWrap) observer.observe(this.$refs.mainWrap.$el);

    this.handleResize();
  },

  computed: {
    isTablet() {
      return this.$vssWidth < 1024;
    },

    isMobile() {
      return this.$vssWidth < 768;
    },

    isInfoVisible() {
      return (
        this.productInfoTitle.length > 0 &&
        this.productInfoDescription.length > 0
      );
    },

    activeFilterName() {
      let name = '';

      for (const key in this.filterSegments) {
        if(this.filterSegments[key].selected) {
          name = this.filterSegments[key].name

          break;
        }
      }

      return name;
    },
  },

  methods: {
    slideTo(direction) {
      let scrollAmount = 0;

      let slideTimer = setInterval(() => {
        if (direction == "left") {
          this.tabsWrapper.scrollLeft += 10;
        } else {
          this.tabsWrapper.scrollLeft -= 10;
        }
        scrollAmount += 10;

        this.isPrevVisible = this.tabsWrapper.scrollLeft > 0 ? true : false;

        const newScrollLeft = this.tabsWrapper.scrollLeft,
          wrapWidth = this.tabsWrapper.offsetWidth,
          scrollWidth = this.tabsInner.scrollWidth;

        const offset = 10;

        this.isNextVisible =
          scrollWidth - (newScrollLeft - offset) >= wrapWidth &&
          scrollWidth - (newScrollLeft + offset) <= wrapWidth
            ? false
            : true;

        if (scrollAmount >= this.tabsInner.offsetWidth / 2) {
          window.clearInterval(slideTimer);
        }
      }, 5);
    },

    handleResize() {
      this.tabsWrapper = this.$refs["scrollTabsWrap"];
      this.tabsInner = this.$refs["scrollTabs"];

      this.isNextVisible = this.tabsInner.scrollWidth > this.tabsWrapper.offsetWidth;
    },

    resizeHandler() {
      if (this.$refs.mainWrap) {
        this.isScroll =
          this.$refs.mainWrap.$el.children[0].getBoundingClientRect().width >
          this.$refs.mainWrap.$el.getBoundingClientRect().width;
        this.$refs.mainWrap.update();
      }
    },
    setParam(category, value, isRadio, mobileReload = false) {
      console.log(value)
      if (isRadio) {
        if (category === "product_section") {
          delete this.params.product_type;
        } else if (category === "product_type") {
          delete this.params.product_section;
        }

        this.params[category] = value;

        // if (value === "all") {
        //   delete this.params[category];
        // }
      } else {
        if (value === "all" || this.params[category] === undefined) {
          // delete this.params[category];
        } else {
          let categorySet = new Set(this.params[category].split(","));
          categorySet.delete("all");
          if (categorySet.has(value)) {
            categorySet.delete(value);
            if (categorySet.size === 0) {
              categorySet.add("all");
            }
          } else {
            categorySet.add(value);
          }
          this.params[category] = [...categorySet].join(",");
        }
      }

      this.queryString = Object.keys(this.params)
        .map((key) => key + "=" + this.params[key])
        .join("&");
      if (mobileReload) {
        // this.setQueryString();
        this.reloadBlock();
      }

      this.closeModal();
    },
    setQueryString() {
      window.location.assign(`?${this.queryString}`);
    },
    async reloadBlock() {
      const url = `${this.url}?${this.queryString}`;

      this.products.update(url);
      // try {
      //   const response = await axios.get(url);

      //   let AjaxComponent = Vue.extend({
      //     template: response.data
      //   })
      //   let compiled = new AjaxComponent().$mount();

      //   const container = document.getElementById(this.contentContainerId);
      //   container.innerHTML = compiled.$el.outerHTML;
      // }
      // catch (error) {
      //   console.log(error);
      // }
    },
    openModal() {
      this.isOpenModal = true;

      const html = document.querySelector('html');
      const scroll = window.pageYOffset;

      html.classList.add('html_state_age-filter');
      html.style.top = `${ -scroll }px`;
    },
    closeModal() {
      this.isOpenModal = false;

      const html = document.querySelector('html');
      const scroll = Math.abs(parseInt(html.style.top));

      html.classList.remove('html_state_age-filter');
      html.style.top = 0;

      if(scroll) {
        window.scrollTo({
          top: scroll,
          left: 0,
          behavior: 'instant',
        });
      }
    },
  },
};
</script>
