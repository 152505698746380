<template>
  <a @click.prevent="clear">
    <slot/>
  </a>
</template>

<script>
import { useCatalogStore } from "../stores/catalog";

export default {
  name: "CatalogClear",
  setup() {
    return {
      catalog: useCatalogStore(),
    };
  },
  methods: {
    clear() {
      const url = this.catalog.url.href;

      if(url.indexOf('?') != -1) {
        const params = url.split('?')[1].split('&');

        for(let i = 0; i <= params.length - 1; i++) {
          const key = params[i].split('=')[0];

          this.catalog.deleteSearchParams(key);
        }
      }

      this.catalog.setHistory();
    },
  },
};
</script>
