<script>
import {useExpertsStore} from "../stores/experts";

export default {
  name: 'ExpertSettings',
  props: {
    ajaxLink: {
      type: String,
      required: true
    }
  },
  setup() {
    return {
      store: useExpertsStore()
    }
  },
  created() {
    // console.log('experts ajax link:', this.ajaxLink)
    this.store.setAjaxLink(this.ajaxLink)
  },
  render() {
    return null;
  }
}
</script>

