<template>
  <button 
    type="button" 
    class="characters-slider__close" 
    v-bind="$attrs"
    v-on="$listeners"
  >
    <svg fill="#000000" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 30 30" width="30px" height="30px">    <path d="M 7 4 C 6.744125 4 6.4879687 4.0974687 6.2929688 4.2929688 L 4.2929688 6.2929688 C 3.9019687 6.6839688 3.9019687 7.3170313 4.2929688 7.7070312 L 11.585938 15 L 4.2929688 22.292969 C 3.9019687 22.683969 3.9019687 23.317031 4.2929688 23.707031 L 6.2929688 25.707031 C 6.6839688 26.098031 7.3170313 26.098031 7.7070312 25.707031 L 15 18.414062 L 22.292969 25.707031 C 22.682969 26.098031 23.317031 26.098031 23.707031 25.707031 L 25.707031 23.707031 C 26.098031 23.316031 26.098031 22.682969 25.707031 22.292969 L 18.414062 15 L 25.707031 7.7070312 C 26.098031 7.3170312 26.098031 6.6829688 25.707031 6.2929688 L 23.707031 4.2929688 C 23.316031 3.9019687 22.682969 3.9019687 22.292969 4.2929688 L 15 11.585938 L 7.7070312 4.2929688 C 7.5115312 4.0974687 7.255875 4 7 4 z"/></svg>
  </button>
</template>

<script>
export default {
  name: "CharactersSliderButtonClose",
}
</script>

<style lang="scss">
@import "../scss/base/includes.scss";

.characters-slider {
  &__close {
    position: absolute;
    top: -15px;
    right: -15px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: rgb(239, 238, 238);
    border: none;
    cursor: pointer;
    transition: background 0.3s ease;

    &:hover {
      background: rgb(219, 218, 218);
    }

    svg {
      width: 20px;
      height: 20px;
    }
  }
}
</style>
