<template>
  <div class="product-icons">
    <ProductIconsItem
      v-for="(item, index) in data"
      v-bind:key="index"
      :src="item.src"
      :name="item.name"
    />
  </div>
</template>

<script>

export default {
  name: 'ProductIcons',
  props: {
    data: { type: Array, default: () => ([]) },
  },
}
</script>

<style lang="scss">
@import '../scss/base/includes';

.tippy-box
{
  font-size: 15px;

  padding: 8px 16px;

  color: $black-true;
  border-radius: 12px;
  background: $white-true;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .25);
}

.tippy-content
{
  padding: 0;

  white-space: nowrap;
}

.tippy-arrow
{
  color: $white-true;

  &:before
  {
    .tippy-box[data-placement^='top'] &
    {
      filter: drop-shadow(4px 4px 4px rgba(0, 0, 0, .25));
    }
    .tippy-box[data-placement^='bottom'] &
    {
      filter: drop-shadow(-4px -4px 4px rgba(0, 0, 0, .25));
    }
    .tippy-box[data-placement^='left'] &
    {
      filter: drop-shadow(4px -4px 4px rgba(0, 0, 0, .25));
    }
    .tippy-box[data-placement^='right'] &
    {
      filter: drop-shadow(-4px 4px 4px rgba(0, 0, 0, .25));
    }
  }
}

</style>
