<template>
  <CharactersItem class="characters-item_random" :character="character">
    <template #image>
      <picture
        class="characters-item__image characters-item__image_background"
      >
        <source
          :srcset="require('../assets/img/characters/character-random-bg.webp')"
          type="image/webp"
        >
        <img
          :src="require('../assets/img/characters/character-random-bg.png')"
        />
      </picture>
    </template>
  </CharactersItem>
</template>

<script>
import CharactersItem from "./CharactersItem.vue";

export default {
  name: "CharactersItemRandom",

  components: {
    CharactersItem
  },

  data() {
    return {
      character: {
        name: "Случайный герой"
      }
    }
  }
};
</script>

<style lang="scss">
@import "../scss/base/includes.scss";

.characters-item_random {
  .characters-item__apperance-background {
    background: $text-brand;
  }

  .characters-item__name-container {
    height: 61px;
    margin-top: -1px;
  }
}
</style>
