<template>
  <div :class="{'catalog-wrap--loading': store.isLoading}" class="catalog-wrap">
    <slot/>
  </div>
</template>

<script>
import {useCatalogStore} from "../stores/catalog";

export default {
  name: 'CatalogInner',
  setup() {
    return {
      store: useCatalogStore()
    }
  },
}
</script>

