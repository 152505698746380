<template>
  <div :class="['filter', {'filter_closed': !opened}]" id="filter">
    <h2 class="filter__title" @click="opened = !opened">Фильтр</h2>
    <div class="filter__toggler" @click="opened = !opened">
      <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 4L3.84211 1L7 4" stroke="#1D388F"/>
      </svg>
      <template v-if="opened">
        Свернуть
      </template>
      <template v-else>
        Показать
      </template>
    </div>
    <transition name="slide">
      <div class="filter__inner" v-if="opened">
        <h3 v-if="showAge" class="filter__heading">
          Выберите <span>возраст</span>
        </h3>
        <age-filter v-if="showAge" :current-slide="currentSlide">
          <template v-if="filterTrimesters.length > 0" slot="trimester">
            <div class="filter__options">
              <h4 class="filter__options-title">Триместры</h4>
              <div class="filter__options-wrap">
                <a v-for="(item, i) in filterTrimesters"
                   :key="`tr${i}`"
                   :class="['filter__options-item', {'filter__options-item_selected': checkSelected('trimester', item.code, true)}]"
                   href="#"
                   @click.prevent="setParam('trimester', item.code, true)"
                   :title="item.name"
                >
                  <template v-if="item.image">
                    <inline-svg :src="item.image"/>
                  </template>
                  <template v-else>{{ item.text }}</template>
                </a>
              </div>
            </div>
          </template>
          <template v-if="filterMonths.length > 0" slot="month">
            <div class="filter__options">
              <h4 class="filter__options-title">Месяцы</h4>
              <div class="filter__options-wrap">
                <a v-for="(item, i) in filterMonths"
                   :key="`mon${i}`"
                   href="#"
                   :class="['filter__options-item', {'filter__options-item_selected': checkSelected('month', item.code, true)}]"
                   @click.prevent="setParam('month', item.code, true)"
                   :title="item.name"
                >
                  {{ item.code }}
                </a>
              </div>
            </div>
          </template>
          <template v-if="filterYears.length > 0" slot="age">
            <div class="filter__options">
              <h4 class="filter__options-title">Годы</h4>
              <div class="filter__options-wrap">
                <a v-for="(item, i) in filterYears"
                   :key="`years${i}`"
                   href="#"
                   :class="['filter__options-item', {'filter__options-item_selected': checkSelected('years', item.code, true)}]"
                   @click.prevent="setParam('years', item.code, true)"
                   :title="item.name"
                >
                  {{ item.code }}
                </a>
              </div>
            </div>
          </template>
        </age-filter>
        <h3 v-if="filterCategories.length > 0" class="filter__heading">
          Выберите <span>категорию</span>
        </h3>
        <div v-if="filterCategories.length > 0" class="filter__category">
          <a href="#"
             :class="['filter__category-item', {'filter__category-item_selected': checkSelected('categories', 'all', false)}]"
             @click.prevent="setParam('categories', 'all', false)"
          >
            Все категории
          </a>
          <a v-for="(item, i) in filterCategories"
             :key="`cat${i}`"
             href="#"
             :class="['filter__category-item', {'filter__category-item_selected': checkSelected('categories', item.code, false)}]"
             @click.prevent="setParam('categories', item.code, false)"
          >
            {{ item.name }}
          </a>
        </div>
        <h3 v-if="filterThemes.length > 0" class="filter__heading">
          Выберите <span>тему</span>
        </h3>
        <div v-if="filterThemes.length > 0" class="filter__theme">
          <a href="#"
             :class="['filter__theme-item', 'filter__theme-item_all', {'filter__theme-item_selected': checkSelected('themes', 'all', singleChoiceComputed)}]"
             @click.prevent="setParam('themes', 'all', singleChoiceComputed)"
          >
            Все темы
          </a>
          <a v-for="(item, i) in filterThemes"
             :key="`theme${i}`" href="#"
             :class="['filter__theme-item', `filter__theme-item_${item.code}`, getItemClass(item.color), {'filter__theme-item_selected': checkSelected('themes', item.code, singleChoiceComputed)}]"
             @click.prevent="setParam('themes', item.code, singleChoiceComputed)"
          >
            {{ item.name }}
          </a>
        </div>
        <a
          class="filter__clear"
          :href="resetFilterLink"
        >
          Сбросить фильтр
        </a>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'BlockFilter',
  props: {
    filterTrimesters: {
      type: Array,
      default: () => {return[]}
    },
    filterMonths: {
      type: Array,
      default: () => {return[]}
    },
    filterYears: {
      type: Array,
      default: () => {return[]}
    },
    filterCategories: {
      type: Array,
      default: () => {return[]}
    },
    filterThemes: {
      type: Array,
      default: () => {return[]}
    },
    currentFilters: {
      type: Object,
      default: () => {return{}}
    },
    resetFilterLink: {
      type: String,
      default: '/calendopedia/?categories=all&themes=all#filter'
    },
    singleChoice: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      opened: true,
      params: this.currentFilters,
    }
  },
  computed: {
    singleChoiceComputed() {
      return this.singleChoice || this.$attrs.singlechoice !== undefined
    },
    isMobile: function () {
      return this.$vssWidth < 1024
    },
    currentSlide: function () {
      if (this.currentFilters['month'] !== undefined) {
        return 1;
      } else if (this.currentFilters['years'] !== undefined) {
        return 2;
      } else {
        return 0;
      }
    },
    showAge: function () {
      return this.filterTrimesters.length > 0 || this.filterYears.length > 0 || this.filterMonths.length > 0;
    }
  },
  mounted() {
  },
  methods: {
    checkSelected(category, value, isRadio) {
      if (this.currentFilters[category] !== undefined) {
        if (isRadio) {
          if (this.currentFilters[category] === value) {
            return true;
          }
        } else {
          if (this.currentFilters[category].includes(value)) {
            return true;
          }
        }
      } else {
        if (value === 'all') {
          return true;
        }
      }
      return false;
    },
    setParam(category, value, isRadio) {
      if (isRadio) {
        delete this.params.trimester;
        delete this.params.month;
        delete this.params.years;
        this.params[category] = value;
      } else {
        if (value === 'all' || this.params[category] === undefined) {
          this.params[category] = value;
        } else {
          let categorySet = new Set(this.params[category].split(','));
          categorySet.delete('all');
          if (categorySet.has(value)) {
            categorySet.delete(value);
            if (categorySet.size === 0) {
              categorySet.add('all');
            }
          } else {
            categorySet.add(value);
          }
          this.params[category] = [...categorySet].join(',');
        }
      }

      const queryString = Object.keys(this.params).map(key => key + '=' + this.params[key]).join('&');
      window.location.assign(`?${queryString}#filter`);
    },
    getItemClass(name) {
      if (name) {
        return `filter__theme-item_${name}`;
      }
    }
  }
}
</script>

