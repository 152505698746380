<template>
  <span class="like">
    <template v-if="noCount">
      <span :class="['_like', {'_active': isActive}]" @click="submit">
        <icon></icon>
      </span>
    </template>
    <template v-else>
      <img :src="`/frontend/assets/img/icons/like${white ? '-white' : !isActive ? '-empty' : ''}.svg`" alt="like" @click="submit">
      <template v-if="!noCount">{{ localValue }}</template>
    </template>
  </span>
</template>

<script>
import { useLikeStore } from "../stores/like";

export default {
  name: 'Like',
  props: {
    url: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      required: true
    },
    iblockid: {
      type: String,
      default: '',
    },
    mode: {
      type: String,
      default: 'like'
    },
    type: {
      type: String,
      default: 'element'
    },
    value: {
      type: Number,
      default: 0
    },
    white: {
      type: Boolean,
      default: false
    },
    noCount: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    return {
      store: useLikeStore()
    }
  },
  components: {
    'icon': {
      props: {
        white: {
          type: Boolean,
          default: false
        },
        color: {
          type: String,
          default: 'red'
        }
      },
      template: '<svg width="19" height="17" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18.4815 6.54222C18.0237 5.95097 17.3143 5.60517 16.5626 5.60732H13.2313L13.4288 2.65104H13.429C13.4815 1.94546 13.2295 1.25111 12.7358 0.740519C12.2587 0.246609 11.5919 -0.0222864 10.9025 0.00144836C10.5394 0.0118414 10.1874 0.12864 9.89098 0.337164C9.59455 0.545523 9.36698 0.836353 9.2371 1.1729L7.47466 5.60733H6.33439C6.13677 5.60733 5.94713 5.6852 5.80741 5.82377C5.66768 5.96235 5.58916 6.15042 5.58916 6.3464V16.2609C5.58916 16.4569 5.66768 16.645 5.80741 16.7836C5.94713 16.9221 6.13677 17 6.33439 17H14.4982C15.1524 16.9965 15.7867 16.7765 16.3004 16.3747C16.814 15.973 17.1778 15.4128 17.3339 14.7828L18.925 8.6447C19.1108 7.9134 18.9473 7.13801 18.4815 6.54198L18.4815 6.54222ZM17.4791 8.2975L15.8919 14.4318C15.8179 14.7444 15.6404 15.0234 15.3879 15.2243C15.1352 15.4253 14.8221 15.5366 14.4982 15.5404H7.07963V7.08544H7.98139C8.1311 7.08527 8.27715 7.0404 8.40091 6.95676C8.5245 6.87295 8.61982 6.75434 8.67438 6.61609L10.6231 1.71246C10.6747 1.58247 10.7958 1.49239 10.9362 1.47969C11.2083 1.47672 11.4698 1.58511 11.6591 1.77895C11.8596 1.98351 11.9626 2.26314 11.9423 2.54771L11.6926 6.28365C11.6793 6.48788 11.7518 6.68848 11.893 6.83761C12.0342 6.98674 12.2315 7.07121 12.4378 7.07071H16.5626C16.8557 7.07137 17.1315 7.20814 17.3079 7.44025C17.4982 7.68325 17.5617 8.00097 17.4792 8.29762L17.4791 8.2975ZM3.72612 5.60732H0.745224C0.547608 5.60732 0.357971 5.68519 0.218244 5.82376C0.0785168 5.96234 0 6.15041 0 6.34639V16.2609C0 16.4569 0.0785168 16.645 0.218244 16.7836C0.357971 16.9221 0.547608 17 0.745224 17H3.72612C3.92374 17 4.11337 16.9221 4.2531 16.7836C4.39283 16.645 4.47134 16.4569 4.47134 16.2609V6.34639C4.47134 6.15041 4.39283 5.96234 4.2531 5.82376C4.11337 5.68519 3.92374 5.60732 3.72612 5.60732ZM2.9809 15.5219H1.49045V7.08546H2.9809V15.5219Z" :fill="white ? `#FFFFFF` : `#2E408D`"/></svg>'
    }
  },
  computed: {
    localValue() {
      return this.store.likes[this.id]?.quantity ?? this.value;
    },
    isActive() {
      return this.store.likes[this.id]?.isActive;
    }
  },
  methods: {
    submit() {
      this.store.submit({url: this.url, mode: this.mode, type: this.type, iblockid: this.iblockid, id: this.id})
    },
  }
}
</script>
