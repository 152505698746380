<template>
  <div>
    <div
      class="calendar-filter__trigger"
      ref="filterTrigger"
    />
    <div
      :class="[
        'calendar-filter',
        {
          'calendar-filter_closed': closed || isMobile,
          'calendar-filter_on-top': onTop,
        },
      ]"
      ref="filter"
    >
      <div class="calendar-filter__arrows" v-if="!isMobile">
        <div
          class="calendar-filter__arrow calendar-filter__arrow--prev"
          @click.prevent="slideTo()"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M21 12L3 12"
              stroke="#005DAA"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M10 19L3 12L10 5"
              stroke="#005DAA"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div
          class="calendar-filter__arrow calendar-filter__arrow--next"
          @click.prevent="slideTo('left')"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M3 12L21 12"
              stroke="#005DAA"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M14 19L21 12L14 5"
              stroke="#005DAA"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>
      <div class="calendar-filter__trimester-tabs" v-if="isMobile">
        <a
          href=""
          v-for="(item, index) in filterData"
          :key="`trimester-tab${index}`"
          :class="[
            'calendar-filter__trimester-title',
            {
              'calendar-filter__trimester-title_selected':
                item.name === currentTrimester,
            },
          ]"
          @click.prevent="scrollToTrimester(item.name)"
        >
          {{ getLatinNumber(item.name) }} {{ trimesterName }}
          <p class="calendar-filter__trimester-subtitle">
            {{ item.weeks[0].name }} -
            {{ item.weeks[item.weeks.length - 1].name }} недели
          </p>
        </a>
      </div>
      <div
        class="calendar-filter__trimester-wrap-wide"
        ref="filter-wrap"
      >

        <div
          class="holder holder--straight calendar-filter__trimester-wrap"
          ref="filter-wrap-inner"
        >
        <div
            class="calendar-filter__trimester"
            v-for="(item, index) in filterData"
            :key="`trimester${index}`"
            :ref="`trimester${item.name}`"
          >
            <div class="calendar-filter__trimester-holder">
              <div class="calendar-filter__trimester-title" v-if="!isMobile">
                {{ getLatinNumber(item.name) }} {{ trimesterName }}
              </div>
              <p class="calendar-filter__trimester-subtitle">
                {{ item.weeks[0].name }} -
                {{ item.weeks[item.weeks.length - 1].name }} недели
              </p>
            </div>
            <div class="calendar-filter__weeks">
              <a
                v-for="(week, week_index) in item.weeks"
                :key="`week${week_index}`"
                :href="week.link"
                :class="[
                  'calendar-filter__week',
                  { 'calendar-filter__week_selected': week.isActive === '1' },
                ]"
                :ref="week.isActive === '1' ? 'week-selected' : undefined"
              >
                {{ week.name }}
              </a>
            </div>
          </div>
        </div>


      </div>
      <!-- <div class="calendar-filter__inner" v-if="!onTop && !isMobile">
        <a
          href=""
          class="calendar-filter__toggle"
          @click.prevent="closed = !closed"
        >
          {{ closed ? "Развернуть" : "Свернуть" }}
          <svg
            width="12"
            height="13"
            viewBox="0 0 12 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10 8.5L6 4.5L2 8.5"
              stroke="#0275D8"
              stroke-width="2"
              stroke-linecap="round"
            />
          </svg>
        </a>
      </div> -->
    </div>
    <slot />
  </div>
</template>

<script>
export default {
  name: "PregnancyCalendar",
  props: {
    filterData: {
      type: Array,
      default: () => [],
    },
    trimesterName: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      closed: false,
      onTop: false,
      filterHeight: undefined,
      currentTrimester: "1",
    };
  },
  computed: {
    isMobile: function () {
      return this.$vssWidth < 768;
    },
  },
  watch: {
    closed() {
      this.$nextTick(() => {
        this.filterHeight = this.$refs.filter.offsetHeight;
        this.scrollToCurrentWeek();
      });
    },
    onTop() {
      this.$nextTick(() => {
        if (!this.closed && !this.isMobile) {
          this.scrollToCurrentWeek();
        }
      });
    },
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("resize", this.handleResize);
  },
  mounted() {
    this.filterHeight = this.$refs.filter.offsetHeight;
    this.scrollToCurrentWeek();
    this.currentTrimester = this.filterData.find((obj1) =>
      obj1.weeks.find((obj2) => obj2.isActive === "1")
    ).name;
  },
  methods: {
    handleScroll() {
      const toggle = this.$refs.filterTrigger.getBoundingClientRect().top <= 0;

      this.onTop = toggle;

      setTimeout(() => {
        this.$refs.filterTrigger.style.height = toggle ? `${ this.$refs.filter.offsetHeight }px` : ``;
      }, 0);
    },
    handleResize() {
      this.filterHeight = this.$refs.filter.offsetHeight;
    },
    scrollToCurrentWeek() {
      const _el = this.$refs["week-selected"][0];
      this.$refs["filter-wrap"].scrollLeft =
        _el.getBoundingClientRect().left - (this.$vssWidth / 2 - 22);
    },
    scrollToTrimester(val) {
      this.currentTrimester = val;
      const _ref = `trimester${val}`;
      this.$refs["filter-wrap"].scrollLeft =
        this.$refs[_ref][0].offsetLeft - 15;
    },

    slideTo(direction) {
      let scrollAmount = 0;

      var slideTimer = setInterval(() => {
          if(direction == 'left'){
              this.$refs["filter-wrap"].scrollLeft += 10;
          } else {
              this.$refs["filter-wrap"].scrollLeft -= 10;
          }
          scrollAmount += 10;
          if(scrollAmount >= this.$refs["filter-wrap-inner"].offsetWidth / 1.25){
              window.clearInterval(slideTimer);
          }
      }, 5);
      // if (direction == "left") {
      //   this.$refs["filter-wrap"].scrollLeft =
      //     this.$refs["filter-wrap"].scrollLeft +
      //     this.$refs["filter-wrap-inner"].offsetWidth / 1.25;
      // } else {
      //   this.$refs["filter-wrap"].scrollLeft =
      //     this.$refs["filter-wrap"].scrollLeft -
      //     this.$refs["filter-wrap-inner"].offsetWidth / 1.25;
      // }
    },

    getLatinNumber(val) {
      if (val === "1") {
        return "l";
      }
      if (val === "2") {
        return "ll";
      }
      if (val === "3") {
        return "lll";
      }
      return "";
    },
  },
};
</script>
