<template>
    <div class="composition-popup__wrap">
      <span @click.prevent="show = !show" class="composition-popup-trigger"></span>
      <div v-if="show" class="product-card__tooltip">
        <div class="product-card__tooltip-head">
          <a @click.prevent="show = !show" href="#" class="product-card__tooltip-close"></a>
        </div>
        <div class="product-card__tooltip-body">
            {{text}}
        </div>
        <svg preserveAspectRatio="none" viewBox="0 0 444 204" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.5" filter="url(#filter0_f_5281_253)">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M141.323 27.6146C120.503 26.173 100.193 24.7667 80.2363 24.5807C49.3397 24.2927 24 25.7716 24 56.8119C24 64.6765 24.798 71.211 25.5303 77.2073C26.1512 82.2908 26.7248 86.9875 26.7248 91.7798C26.7248 96.1627 26.245 100.146 25.6881 104.769C24.9178 111.164 24 118.784 24 130.381C24 161.716 49.3304 170.119 80.2363 169.883C105.698 169.689 132.827 168.377 159.441 167.09C175.449 166.316 191.27 165.551 206.43 165.043L220.015 178.613C221.866 180.462 224.868 180.462 226.72 178.613L240.921 164.445C264.703 164.538 282.904 165.904 300.793 167.247C317.243 168.482 333.429 169.696 353.448 169.883C393.996 169.883 420 168.692 420 139.009C420 133.656 419.221 129.683 418.343 125.207C417.21 119.431 415.913 112.817 415.913 101.317C415.913 91.7593 416.809 86.0744 417.762 80.0278C418.844 73.1594 420 65.8243 420 51.8165C420 30.4725 394.672 24.3445 363.764 24.5807C338.045 24.7772 312.742 26.335 288.007 27.8577C262.786 29.4103 238.157 30.9266 214.28 30.9266C189.157 30.9266 164.913 29.248 141.323 27.6146Z" fill="#005DAA"/>
            </g>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M141.338 18.5464C120.522 17.1051 100.215 15.6991 80.2628 15.5131C49.3721 15.2251 24.0371 16.7038 24.0371 47.7382C24.0371 55.6013 24.835 62.1346 25.5672 68.1297C26.1879 73.2123 26.7614 77.9081 26.7614 82.6995C26.7614 87.0816 26.2817 91.0639 25.7249 95.6864C24.9548 102.08 24.0371 109.698 24.0371 121.293C24.0371 152.622 49.3628 161.024 80.2628 160.788C105.72 160.594 132.844 159.282 159.453 157.995C177.567 157.12 195.442 156.255 212.391 155.763L221.646 165.243C222.631 166.252 224.228 166.252 225.213 165.243L234.878 155.355C236.041 155.349 237.196 155.346 238.345 155.346C263.393 155.346 282.253 156.762 300.778 158.152C317.225 159.387 333.408 160.602 353.423 160.788C393.964 160.788 419.963 159.597 419.963 129.92C419.963 124.567 419.184 120.595 418.306 116.12C417.173 110.345 415.876 103.733 415.876 92.2344C415.876 82.679 416.772 76.9952 417.725 70.9498C418.807 64.0826 419.963 56.7489 419.963 42.7438C419.963 21.4037 394.639 15.2769 363.737 15.5131C338.023 15.7096 312.725 17.267 287.994 18.7895C262.778 20.3418 238.153 21.8578 214.281 21.8578C189.163 21.8578 164.924 20.1794 141.338 18.5464Z" fill="url(#paint0_radial_5281_253)"/>
            <defs>
            <filter id="filter0_f_5281_253" x="0" y="0.553833" width="444" height="203.446" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                <feGaussianBlur stdDeviation="12" result="effect1_foregroundBlur_5281_253"/>
            </filter>
            <radialGradient id="paint0_radial_5281_253" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(222 90.7431) rotate(90) scale(101.898 341.17)">
                <stop stop-color="white"/>
                <stop offset="0.23" stop-color="white"/>
                <stop offset="0.48" stop-color="white"/>
                <stop offset="0.727768" stop-color="#E6F7FF"/>
                <stop offset="0.9999" stop-color="#BCEAFF"/>
                <stop offset="1" stop-color="#BCEAFF"/>
            </radialGradient>
            </defs>
        </svg>
        <div class="product-card__tooltip-overlay"></div>
      </div>
    </div>
</template>

<script>
export default {
    props: ['text'],
    name: 'ProductCompositionTooltip',
    data() {
      return {
        show: false
      }
    },
    watch: {
      show() {
        const productCardWrap = document.getElementsByClassName('product-card__wrapper')
        if (this.show) {
          productCardWrap[0].classList.add('z-index-9')
        } else {
          productCardWrap[0].classList.remove('z-index-9')
        }
      }
    }
}
</script>