<template>
  <div class="item-card__labels">
    <div
      v-for="item in tags"
      :key="item.CODE"
      @click.prevent="() => selectTag(item.CODE)"
      class="item-card__label"
    >
      {{ item.NAME }}
    </div>
  </div>
</template>

<script>
import { useRecipesStore } from "../stores/recipes";

export default {
  name: "RecipesTags",
  setup() {
    return {
      recipes: useRecipesStore(),
    };
  },
  props: {
    tags: {
      type: Array,
      default: () => []
    },
  },
  methods: {
    selectTag(tag) {
      if(tag === this.recipes.section) {
        return false;
      }

      this.recipes.setSection(tag);
      this.recipes.setSearchParams('section', tag);
      this.recipes.update();
    },
  }
};
</script>
