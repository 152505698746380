<template>
  <div :class="['text-description', {'text-description_opened': opened}]">
    <h3 class="text-description__title">{{ title }}</h3>
    <div class="text-description__text" ref="text">
        <slot />
    </div>
    <a v-if="textOverflow" href="" @click.prevent="opened = !opened" class="text-description__show">
      {{ !opened ? 'Показать полностью' : 'Свернуть'}}
    </a>
  </div>
</template>

<script>
export default {
  name: "SideMenu",
  props: {
    title: {
      type: String,
      value: '',
    }
  },
  data() {
    return {
      opened: false,
      textOverflow: false,
    };
  },
  computed: {
    isMobile: function () {
      return this.$vssWidth < 768
    },
  },
  mounted() {
    if (this.$refs.text.offsetHeight > 99) {
      this.textOverflow = true;
    }
  },
  methods: {
  }
};
</script>
