import Vue from 'vue';

export default function registerVueDirectives() {
  Vue.directive('readmore', {
    inserted(el, binding, vnode) {
      const windowWidth = vnode.context.$vssWidth;
      const isDesktop = windowWidth > 1280;
      const isLowDesktop = windowWidth > 1024 && windowWidth < 1280;
      const isTablet = windowWidth > 768 && windowWidth < 1024;
      const isMobile = windowWidth < 768;
      const height = el.offsetHeight;
      let maxHeight;

      if (typeof binding.value === 'object') {
        if (isDesktop && binding.value.desktop) {
          maxHeight = binding.value.desktop;
        }

        if (isLowDesktop && binding.value.lowDesktop) {
          maxHeight = binding.value.lowDesktop;
        }

        if (isTablet && binding.value.tablet) {
          maxHeight = binding.value.tablet;
        }

        if (isMobile && binding.value.mobile) {
          maxHeight = binding.value.mobile;
        }
      } else {
        maxHeight = binding.value;
      }

      if (!maxHeight || maxHeight === 'auto' || maxHeight >= height) return;

      const actions = document.createElement('div');
      const button = document.createElement('span');
      const classes = {
        el: 'readmore',
        actions: 'readmore-actions',
        button: 'readmore-button',
        collapsed: 'readmore_collapsed',
      };
      const text = {
        expand: 'Развернуть',
        collapse: 'Свернуть',
      };

      el.setCollapse = () => {
        el.classList.add(classes.el);
        el.classList.add(classes.collapsed);
        el.style.height = `${maxHeight}px`;
        actions.classList.add(classes.actions);
        el.parentNode.insertBefore(actions, el.nextSibling);
        button.classList.add(classes.button);
        button.innerHTML = text.expand;
        actions.appendChild(button);
      };

      el.clickCallback = (e) => {
        e.preventDefault();

        if (el.classList.contains(classes.collapsed)) {
          el.classList.remove(classes.collapsed);
          el.style.height = '';
          button.innerHTML = text.collapse;
        } else {
          el.classList.add(classes.collapsed);
          el.style.height = `${maxHeight}px`;
          button.innerHTML = text.expand;
        }
      };

      el.setCollapse();
      button.addEventListener('click', el.clickCallback);
    },
    unbind(el) {
      const button = el.querySelector('readmore-button');
      button.removeEventListener('click', el.clickCallback);
    },
  });

  Vue.directive('click-outside', {
    bind(el, binding, vnode) {
      el.clickOutsideEvent = function (event) {
        if (!(el == event.target || el.contains(event.target))) {
          vnode.context[binding.expression](event);
        }
      };
      document.body.addEventListener('click', el.clickOutsideEvent);
    },
    unbind(el) {
      document.body.removeEventListener('click', el.clickOutsideEvent);
    },
  });
}
