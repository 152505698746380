<template>
  <div class="double-scroll">
    <vue-custom-scrollbar class="double-scroll__item ps--top" ref="dummyWrap" @ps-scroll-x="scrollHandlerDummy">
      <div class="double-scroll__dummy" ref="dummy"></div>
    </vue-custom-scrollbar>
    <vue-custom-scrollbar class="double-scroll__item ps--bottom" @ps-scroll-x="scrollHandlerMain" ref="mainWrap">
      <slot/>
    </vue-custom-scrollbar>
  </div>
</template>

<script>
import vueCustomScrollbar from 'vue-custom-scrollbar'

export default {
  name: "DoubleScroll",
  components: {
    vueCustomScrollbar
  },
  mounted() {
    this.resizeHandler();
    const observer = new ResizeObserver(() => {
      this.resizeHandler();

    });
    observer.observe(this.$refs.mainWrap.$el);
  },
  methods: {
    scrollHandlerMain() {
      this.$refs.dummyWrap.$el.scrollLeft = this.$refs.mainWrap.$el.scrollLeft;
    },
    scrollHandlerDummy() {
      this.$refs.mainWrap.$el.scrollLeft = this.$refs.dummyWrap.$el.scrollLeft;
    },
    resizeHandler() {
      this.isScroll = this.$refs.mainWrap.$el.children[0].getBoundingClientRect().width > this.$refs.mainWrap.$el.getBoundingClientRect().width;
      this.$refs.dummy.style.width = this.$refs.mainWrap.$el.children[0].getBoundingClientRect().width + 'px';
      this.$refs.dummyWrap.update();
      this.$refs.mainWrap.update();
    }
  }
};
</script>
