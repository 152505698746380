<template>
  <div :class="['catalog-filter__chips', {'catalog-filter__chips--loading': store.isLoading}]">
    <div
      v-for="(item, index) in getItems()"
      :key="`wrap${index}`"
    >
      <span class="catalog-filter__chip">
        {{ chips.find(i => i.alias === item.value).title }}
        <a @click.prevent="() => removeChip(item)" class="catalog-filter__chip-remove" title="сбросить">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M12 4L4 12" stroke="#00B5ED" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M4 4L12 12" stroke="#00B5ED" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </a>
      </span>
    </div>
</div>
</template>

<script>
import { useCatalogStore } from "../stores/catalog";

export default {
  name: "FilterChips",
  setup() {
    return {
      store: useCatalogStore()
    }
  },
  props: {
    chips: {
      type: Array,
      default: () => [],
    },
  },
  created() {},
  methods: {
    getItems() {
      const url = this.store.url.href;
      const res = [];

      if(url.indexOf('?') != -1) {
        const params = url.split('?')[1].split('&');

        for(let i = 0; i <= params.length - 1; i++) {
          const [label, value] = params[i].split('=');

          if(label !== 'page') {
            res.push(
              decodeURIComponent(value).split(',').map(i => ({
                label,
                value: i,
              }))
            );
          }
        }
      }

      return res.flat();
    },
    removeChip(chip) {
      const tags = this.store.url.searchParams.get(chip.label)?.split(',') || [];
      const sections = this.store.url.searchParams.get('section')?.split(',') || [];
      const index = tags.indexOf(chip.value);
      const current = this.chips.find(i => i.alias === chip.value);
      const subitems = current.subitems;
      const siblings = current.siblings;
      const parents = current.parents;
      const sectionsIndexes = sections.filter(i => !subitems.includes(i));

      tags.splice(index, 1);

      if(sectionsIndexes.length) {
        this.store.setSearchParams('section', sectionsIndexes.join(','));
      } else {
        this.store.deleteSearchParams('section');
      }

      if(tags.length) {
        this.store.setSearchParams(chip.label, tags.join(','));
      } else {
        this.store.deleteSearchParams(chip.label);
      }

      if(parents) {
        const params = this.store.url.searchParams.get('type')?.split(',') || [];
        const paramsIndex = params.indexOf(parents);
        const paramsIncludes = siblings.filter(i => sections.indexOf(i.code) !== -1);
        const indexSection = paramsIncludes.indexOf(chip.value);

        paramsIncludes.splice(indexSection, 1);

        if(!paramsIncludes.length) {
          params.splice(paramsIndex, 1);

          if(params.length) {
            this.store.setSearchParams('type', params.join(','));
          } else {
            this.store.deleteSearchParams('type');
          }
        }
      }

      this.store.deleteSearchParams('page');

      this.store.setHistory();
    },
  }
};
</script>
