import { defineStore } from 'pinia'
import axios from "axios";

export const useExpertsStore = defineStore('experts-template', {
  state: () => ({
    template: '',
    isLoading: false,
    ajaxLink: '',
  }),
  getters: {
  },
  actions: {
    setAjaxLink(link) {
      this.ajaxLink = link;
    },
    setTemplate(newTemplate) {
      this.template = newTemplate;
    },
    async update(url, ajaxUrl) {
      history.replaceState(null, "", url);
      return this.loadTemplate(ajaxUrl);
    },
    async loadTemplate(url) {
      try {
        this.isLoading = true;
        const res = await axios.get(url);
        this.setTemplate(res.data);
      }
      finally {
        this.isLoading = false;
      }
    }
  },
})
