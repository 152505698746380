<template>
  <div class="poll-main background">
    <picture class="background__header">
      <source
        srcset="
          /frontend/assets/img/main/header1920-2.webp,
          /frontend/assets/img/main/header1920-2@2x.webp 2x
        "
        media="(min-width: 1366px)"
      />
      <source
        srcset="
          /frontend/assets/img/main/header1024-4.webp,
          /frontend/assets/img/main/header1024-4@2x.webp 2x
        "
        media="(min-width: 768px)"
      />
      <img
        srcset="
          /frontend/assets/img/main/header375-5.webp,
          /frontend/assets/img/main/header375-5@2x.webp 2x
        "
        src="/frontend/assets/img/main/header375-5.webp"
        alt="фон страницы"
      />
    </picture>
    <picture class="poll-main__flower">
      <source
        srcset="
          /frontend/assets/img/product-flower2.webp,
          /frontend/assets/img/product-flower2@2x.webp 2x
        "
        media="(min-width: 768px)"
      />
      <img
        srcset="
          /frontend/assets/img/flower14.webp,
          /frontend/assets/img/flower14@2x.webp 2x
        "
        src="/frontend/assets/img/flower14.png"
        alt="цветок"
      />
    </picture>
    <img
      srcset="
        /frontend/assets/img/agu-boy7.webp,
        /frontend/assets/img/agu-boy7@2x.webp 2x
      "
      src="/frontend/assets/img/agu-boy7.webp"
      alt="цветок"
      class="poll-main__agu"
    />
    <div class="holder">
      <div class="poll-header" ref="poll-header">
        <h2 class="poll-main__title">{{ title }}</h2>
        <div class="poll-main__description">
          С ребёнком, друзьями или в одиночку? На море или в горах?
          <div>Пройди короткий тест и узнай!</div>
        </div>
      </div>
    </div>
    <div class="holder holder--full">
      <div class="poll-main__wrapper">
        <svg
          preserveAspectRatio="none"
          viewBox="0 0 1222 1000"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="poll-main__back"
        >
          <g clip-path="url(#clip0_2853_20804)">
            <path
              d="M28.6178 792.607C28.6178 856.335 0.00501789 904.989 0.00501373 1000L60 1000L60 2.62268e-06L0.00505745 2.19188e-10C0.00505562 158.521 28.6184 190.62 28.6184 263.158C28.6184 335.695 -0.411848 388.432 0.00445594 524.436C0.389509 650.231 28.6178 716.165 28.6178 792.607Z"
              fill="white"
            />
          </g>
          <rect
            width="1104"
            height="1000"
            transform="translate(59)"
            fill="white"
          />
          <g clip-path="url(#clip1_2853_20804)">
            <path
              d="M1193.38 207.393C1193.38 143.665 1221.99 95.0107 1221.99 -2.19157e-10L1162 -2.62268e-06L1162 1000L1221.99 1000C1221.99 841.479 1193.38 809.38 1193.38 736.842C1193.38 664.305 1222.41 611.568 1222 475.564C1221.61 349.769 1193.38 283.835 1193.38 207.393Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_2853_20804">
              <rect width="60" height="1000" fill="white" />
            </clipPath>
            <clipPath id="clip1_2853_20804">
              <rect
                width="60"
                height="1000"
                fill="white"
                transform="translate(1162)"
              />
            </clipPath>
          </defs>
        </svg>

        <div class="poll" v-if="showResult">
          <div class="poll__result-label" v-if="isMobile">Результат теста</div>
          <div class="poll__content">
            <div class="poll__result-label" v-if="!isMobile">
              Результат теста
            </div>
            <h3 class="poll__result-title">{{ resultTitle }}</h3>
            <p class="poll__result-comment">{{ resultDesc }}</p>
            <ul class="poll__result-options">
              <li v-for="(item, index) in resultAdvice" :key="`advice${index}`">
                <h4 v-if="item.title != ' '" v-html="item.title"></h4>
                <p v-if="item.desc != ' '" v-html="item.desc"></p>
              </li>
            </ul>

            <div class="poll__restore">
              <a href="" @click.prevent="clear">
                Пройти тест заново
                <img src="/frontend/assets/img/icons/refresh.svg" alt="" />
              </a>
            </div>
            <span class="poll__views" v-if="viewsCount > 0">
              Количество просмотров теста: {{ viewsCount }}
            </span>
            <span class="poll__views" v-if="testCount > 0">
              Количество прошедших тест: {{ testCount }}
            </span>
          </div>
          <div class="poll__img-col">
            <div class="poll__img">
              <svg
                preserveAspectRatio="none"
                viewBox="0 0 367 218"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="poll__img-cover"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M367 0H0V218H367V0ZM316.184 213H316.023C285.318 212.996 251.251 210.786 219.396 208.719L219.39 208.719C191.461 206.907 165.234 205.206 144.463 205.198C120.156 205.19 103.796 207.421 88.6772 209.484C75.7119 211.252 63.6587 212.896 48.2891 212.896C24.4805 212.896 5 193.855 5 170.797C5 160.337 6.47559 149.899 7.94873 139.483L7.9502 139.47C9.41602 129.102 10.8789 118.754 10.8789 108.428C10.8789 98.0434 9.3999 87.7898 7.92529 77.5688V77.5359C6.46045 67.3906 5 57.2771 5 47.099C5 23.9446 24.4805 5 48.2891 5C75.751 5 104.628 6.82489 132.367 8.57782C158.003 10.1979 182.667 11.7565 204.343 11.7565C227.681 11.7565 246.94 9.9498 265.54 8.20496C282.91 6.57553 299.706 5 318.711 5C342.52 5 362 24.1091 362 47.099C362 54.6146 360.711 61.0349 359.341 67.8633C357.784 75.6194 356.122 83.9021 356.122 94.915C356.122 104.752 357.448 116.658 358.841 129.16C360.38 142.976 362 157.518 362 170.797C362 193.951 342.47 212.896 318.688 212.995C317.855 212.998 317.021 213 316.184 213Z"
                  fill="#fff"
                ></path>
              </svg>
              <img :src="resultImage" alt="" />
            </div>
          </div>
        </div>
        <div v-else class="poll" :key="`question${currentQuestion}`">
          <div class="poll__counter" v-if="isMobile">
            {{ currentQuestion + 1 }}<span>/{{ items.length }}</span>
          </div>
          <div class="poll__content">
            <a
              v-if="currentQuestion > 0"
              class="poll__backlink"
              href=""
              @click.prevent="previousQuestion"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M14 8L2 8"
                  stroke="#00B5ED"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M6.66667 12.6666L2 7.99998L6.66667 3.33331"
                  stroke="#00B5ED"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              Предыдущий вопрос
            </a>
            <div class="poll__counter" v-if="!isMobile">
              {{ currentQuestion + 1 }}<span>/{{ items.length }}</span>
            </div>
            <h3 class="poll__question">{{ items[currentQuestion].title }}</h3>

            <ul class="poll__answers">
              <li
                v-for="(item, index) in items[currentQuestion].answers"
                :key="`option${index}`"
                @click="selectAnswer(item.id, items[currentQuestion].id)"
                :class="{ selected: allAnswers.includes(item.id) }"
              >
                {{ item.name }}
              </li>
            </ul>
            <a
              href=""
              @click.prevent="
                nextQuestion(items[currentQuestion].id, answersArray)
              "
              :class="['poll__next', { 'poll__next--disabled': !isSelected }]"
              >
              <template v-if="(currentQuestion + 1 < items.length)" >
                Следующий вопрос
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M2 8L14 8"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M9.33333 12.6667L14 7.99999L9.33333 3.33333"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </template>
              <template v-else>
                Узнать результат
              </template>
            </a>
          </div>
          <div class="poll__img-col">
            <div class="poll__img">
              <svg
                preserveAspectRatio="none"
                viewBox="0 0 367 218"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="poll__img-cover"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M367 0H0V218H367V0ZM316.184 213H316.023C285.318 212.996 251.251 210.786 219.396 208.719L219.39 208.719C191.461 206.907 165.234 205.206 144.463 205.198C120.156 205.19 103.796 207.421 88.6772 209.484C75.7119 211.252 63.6587 212.896 48.2891 212.896C24.4805 212.896 5 193.855 5 170.797C5 160.337 6.47559 149.899 7.94873 139.483L7.9502 139.47C9.41602 129.102 10.8789 118.754 10.8789 108.428C10.8789 98.0434 9.3999 87.7898 7.92529 77.5688V77.5359C6.46045 67.3906 5 57.2771 5 47.099C5 23.9446 24.4805 5 48.2891 5C75.751 5 104.628 6.82489 132.367 8.57782C158.003 10.1979 182.667 11.7565 204.343 11.7565C227.681 11.7565 246.94 9.9498 265.54 8.20496C282.91 6.57553 299.706 5 318.711 5C342.52 5 362 24.1091 362 47.099C362 54.6146 360.711 61.0349 359.341 67.8633C357.784 75.6194 356.122 83.9021 356.122 94.915C356.122 104.752 357.448 116.658 358.841 129.16C360.38 142.976 362 157.518 362 170.797C362 193.951 342.47 212.896 318.688 212.995C317.855 212.998 317.021 213 316.184 213Z"
                  fill="#fff"
                ></path>
              </svg>
              <img :src="items[currentQuestion].img" alt="" />
            </div>
          </div>
        </div>
        <div :class="['article article--new', { 'article--disabled': !showResult }]">
          <div class="article__like">
            <h3>Вам понравился тест?</h3>
            <div class="article__bottom">
              <div class="article__bottom-holder">
                <div class="article__reaction">
                  <like
                    url="/bitrix/services/main/ajax.php?action=dalee:project.vote.add"
                    :id="pollId"
                    :i-block-id="iBlockId"
                    mode="like"
                    type="section"
                    :value="likeValue"
                  >
                  </like>
                </div>
                <div class="article__share">
                  Поделиться
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 16 15"
                    fill="none"
                  >
                    <path
                      d="M6.55126 1.3298L6.38698 3.86848L6.37113 4.20479L6.29863 4.21236C2.71524 4.63355 0 7.6888 0 11.4294C0 11.7983 0.0275644 12.1641 0.0820957 12.5246C0.307568 14.0148 0.477072 14.1589 1.51298 12.9268C1.768 12.6235 2.05261 12.3529 2.35248 12.0952C3.43123 11.2052 4.76872 10.6854 6.19142 10.6274L6.37356 10.6236L6.4232 11.6157L6.55724 13.5092C6.62126 14.1584 7.30424 14.6019 7.93287 14.2473C8.21651 14.0872 8.49377 13.9169 8.77159 13.7471C10.2151 12.8438 11.5658 11.8061 12.8822 10.7286C13.6568 10.0831 14.3359 9.47096 14.9137 8.91386C15.1795 8.65243 15.4457 8.39164 15.7035 8.12205C16.0766 7.73151 16.1135 7.08471 15.7229 6.67577C14.824 5.73488 13.5531 4.55645 11.9713 3.3074C10.6894 2.30442 9.36333 1.36743 7.95855 0.544559C7.28292 0.159562 6.61271 0.687814 6.55126 1.3298Z"
                      fill="white"
                    />
                  </svg>
                  <div class="article__share-dropdown">
                    <div class="article-share-dropdown">
                      <div class="article-share-dropdown__back">
                        <svg
                          viewBox="0 0 276 179"
                          preserveAspectRatio="none"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g filter="url(#filter0_d_3257_2360)">
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M39.527 4C78.0107 4 120.311 8.87246 153.18 8.87246C186.048 8.87246 207.809 4 236.473 4C253.813 4 268 17.7806 268 34.3599C268 45.9358 263.719 53.9083 263.719 68.8425C263.719 83.7767 268 105.325 268 123.565C268 140.263 253.777 153.925 236.456 153.996C194.116 154.17 142.453 148.386 109.57 148.374C76.6865 148.362 63.7739 153.925 39.527 153.925C22.1876 153.925 8 140.194 8 123.565C8 108.51 12.2815 93.5185 12.2815 78.5877C12.2815 63.6569 8 49.1024 8 34.3599C8 17.662 22.1874 4 39.527 4Z"
                              fill="url(#paint0_radial_3257_2360)"
                            />
                            <path
                              d="M118.5 148.508C118.5 148.508 119.382 149.593 119.828 150.039L135.385 165.596C136.947 167.158 139.48 167.158 141.042 165.596L156.23 150.407C142.774 149.554 129.67 148.804 118.5 148.508Z"
                              fill="url(#paint1_radial_3257_2360)"
                            />
                          </g>
                          <defs>
                            <filter
                              id="filter0_d_3257_2360"
                              x="0"
                              y="0"
                              width="276"
                              height="178.766"
                              filterUnits="userSpaceOnUse"
                              color-interpolation-filters="sRGB"
                            >
                              <feFlood
                                flood-opacity="0"
                                result="BackgroundImageFix"
                              />
                              <feColorMatrix
                                in="SourceAlpha"
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                result="hardAlpha"
                              />
                              <feOffset dy="4" />
                              <feGaussianBlur stdDeviation="4" />
                              <feComposite in2="hardAlpha" operator="out" />
                              <feColorMatrix
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0.364706 0 0 0 0 0.666667 0 0 0 0.25 0"
                              />
                              <feBlend
                                mode="normal"
                                in2="BackgroundImageFix"
                                result="effect1_dropShadow_3257_2360"
                              />
                              <feBlend
                                mode="normal"
                                in="SourceGraphic"
                                in2="effect1_dropShadow_3257_2360"
                                result="shape"
                              />
                            </filter>
                            <radialGradient
                              id="paint0_radial_3257_2360"
                              cx="0"
                              cy="0"
                              r="1"
                              gradientUnits="userSpaceOnUse"
                              gradientTransform="translate(138 85.3837) rotate(90) scale(110.193 224.043)"
                            >
                              <stop stop-color="white" />
                              <stop offset="0.23" stop-color="white" />
                              <stop offset="0.48" stop-color="white" />
                              <stop offset="0.727768" stop-color="#E6F7FF" />
                              <stop offset="0.9999" stop-color="#BCEAFF" />
                              <stop offset="1" stop-color="#BCEAFF" />
                            </radialGradient>
                            <radialGradient
                              id="paint1_radial_3257_2360"
                              cx="0"
                              cy="0"
                              r="1"
                              gradientUnits="userSpaceOnUse"
                              gradientTransform="translate(138 85.3837) rotate(90) scale(110.193 224.043)"
                            >
                              <stop stop-color="white" />
                              <stop offset="0.23" stop-color="white" />
                              <stop offset="0.48" stop-color="white" />
                              <stop offset="0.727768" stop-color="#E6F7FF" />
                              <stop offset="0.9999" stop-color="#BCEAFF" />
                              <stop offset="1" stop-color="#BCEAFF" />
                            </radialGradient>
                          </defs>
                        </svg>
                      </div>
                      <div class="article-share-dropdown__title">
                        Поделиться
                      </div>
                      <div class="article__sharing">
                        <div
                          class="ya-share2"
                          data-bare
                          data-services="vkontakte,telegram,odnoklassniki"
                        ></div>
                        <copy-link></copy-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <a href="/tests/" class="article__other">Другие тесты</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <img
      srcset="
        /frontend/assets/img/polls/agu-footer.webp,
        /frontend/assets/img/polls/agu-footer@2x.webp 2x
      "
      src="/frontend/assets/img/polls/agu-footer.webp"
      alt="цветок"
      class="poll-main__agu2"
    />
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Poll",
  props: {
    pollId: {
      type: String,
      required: true,
    },
    iBlockId: {
      type: String,
      required: true,
    },
    likeValue: {
      type: Number,
      default: 0,
    },
    viewsCount: {
      type: Number,
      default: 0,
    },
    testCount: {
      type: Number,
      default: 0,
    },
    title: {
      type: String,
      default: "",
    },
    items: {
      type: Array,
      default: () => [],
    },
    requestUrl: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      currentQuestion: 0,
      loading: false,
      isSelected: false,
      answersArray: {},
      currentAnswers: [],
      allAnswers: [],
      showResult: false,
      resultTitle: "",
      resultImage: "",
      resultDesc: "",
      resultAdvice: [],
    };
  },
  mounted() {
    let sharingScript = document.createElement("script");
    sharingScript.setAttribute("src", "https://yastatic.net/share2/share.js");
    document.head.appendChild(sharingScript);
  },
  computed: {
    isMobile: function () {
      return this.$vssWidth < 768;
    },
  },
  methods: {
    nextQuestion() {
      // this.answersArray[questionId] = answerId;
      if (this.currentQuestion + 1 < this.items.length) {
        this.currentQuestion++;
        this.currentAnswers = [];
      } else {
        this.getResults();
      }

      for(const item of this.items[this.currentQuestion].answers ) {
        if (this.allAnswers.includes(item.id)) {
          this.isSelected = true;
          return;
        } else {
          this.isSelected = false;
        }
      }

      window.scrollTo(0, this.$refs["poll-header"].offsetTop);


    },
    previousQuestion() {
      this.currentQuestion--;
      this.currentAnswers = [];
      window.scrollTo(0, this.$refs["poll-header"].offsetTop);

      this.isSelected = true;

      // if (this.currentAnswers.length > 0) {
      //   this.isSelected = true;
      // } else {
      //   this.isSelected = false;
      // }
    },
    selectAnswer(itemId, questionId) {
      let currentAnswersIds = this.items[this.currentQuestion].answers.map(
        (answer) => {
          return answer.id;
        }
      );
      switch (this.items[this.currentQuestion].type) {
        case "single":
          this.allAnswers = this.allAnswers.filter(
            (answer) => !~currentAnswersIds.indexOf(answer)
          );
          this.currentAnswers = this.currentAnswers.filter(
            (answer) => !~currentAnswersIds.indexOf(answer)
          );
          this.allAnswers.push(itemId);
          this.currentAnswers.push(itemId);
          break;
        case "multiple": {
          if (this.currentAnswers.includes(itemId)) {
            this.allAnswers = this.allAnswers.filter(
              (answer) => answer != itemId
            );
            this.currentAnswers = this.currentAnswers.filter(
              (answer) => answer != itemId
            );
          } else {
            this.allAnswers.push(itemId);
            this.currentAnswers.push(itemId);
          }
          break;
        }
      }
      this.answersArray[questionId] = this.currentAnswers;

      if (this.currentAnswers.length > 0) {
        this.isSelected = true;
      } else {
        this.isSelected = false;
      }
    },
    async getResults() {
      this.loading = true;
      let query = `&pollId=${this.pollId}`;
      for (const [key, value] of Object.entries(this.answersArray)) {
        query = `${query}&testResult[${value}]=${key}`;
      }
      const url = `${this.requestUrl}${query}`;
      try {
        const response = await axios.get(url);
        if (response.data.status === "success") {
          this.resultTitle = response.data.data.title;
          this.resultImage = response.data.data.img;
          this.resultDesc = response.data.data.desc;
          this.resultAdvice = [...response.data.data.advice];
          this.showResult = true;
          this.loading = false;
          console.log(response.data.data);
        } else {
          this.loading = false;
          throw new Error(response.data.errors);
        }
      } catch (error) {
        alert(error);
      }
    },
    clear() {
      this.showResult = false;
      this.currentQuestion = 0;
      this.answersArray = {};
      this.currentAnswers = [];
      this.allAnswers = [];
      window.scrollTo(0, this.$refs["poll-header"].offsetTop);
    },
  },
};
</script>
