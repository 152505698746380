<template>
  <div class="product-icons__item">
    <span ref="tooltip">
      <img :src="src" :alt="name">
    </span>
  </div>
</template>

<script>
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

export default {
  name: 'ProductIconsItem',
  props: {
    src: { type: String, default: '' },
    name: { type: String, default: '' },
  },
  mounted() {
    tippy(this.$refs.tooltip, {
      content: this.name,
      appendTo: 'parent',
    });
  }
}
</script>
