<template>
  <div>
    <div v-if="!isTablet" class="grid">
      <div v-for="(item, index) in dataArray" :key="index" class="grid__item">
        <div class="skazki-item">
          <div class="skazki-item__image"><img :src="item.image" alt=""></div>
          <div class="skazki-item__title">{{ item.title }}</div>
        </div>
      </div>
    </div>
    <div v-else class="slider">
      <swiper ref="Swiper" :options="swiperOptions">
        <swiper-slide v-for="(item, index) in dataArray" :key="index" class="slider__item slider__item_no-bg">
          <div class="skazki-item">
            <div class="skazki-item__image"><img :src="item.image" alt=""></div>
            <div class="skazki-item__title">{{ item.title }}</div>
          </div>
        </swiper-slide>
      </swiper>
      <div v-if="dataArray.length > 1" class="slider__bullets"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SliderSkazki",
  props: {
    dataArray: {
      type: Array,
      default: () => {return []}
    },
  },
  data() {
    return {
      swiperOptions: {
        slidesPerView: 1.25,
        spaceBetween: 20,
        watchOverflow: true,
        loopAdditionalSlides: 1,
        pagination: {
          el: '.slider__bullets',
          type: "bullets",
          bulletClass: "slider__bullet",
          bulletActiveClass: "slider__bullet_active",
          clickable: true
        },
        breakpoints: {
          768: {
            slidesPerView: 2,
          },
        }
      }
    };
  },
  computed: {
    swiper() {
      return this.$refs.Swiper.$swiper;
    },
    isTablet: function () {
      return this.$vssWidth < 1024
    }
  },
  mounted() {
  }
};
</script>

