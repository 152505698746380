<template>
  <div ref="wrap">
    <slot />
  </div>
</template>

<script>
import { useCatalogStore } from "../stores/catalog";

export default {
  name: "CatalogPagination",
  setup() {
    return {
      catalog: useCatalogStore(),
    };
  },
  mounted() {
    this.$refs.wrap.querySelectorAll("a").forEach((a) => {
      const pageUrl = new URL(a.href);
      const page = pageUrl.searchParams.get("page");

      a.href = `/products/${pageUrl.search}`;

      a.addEventListener("click", (e) => {
        e.preventDefault();

        if (page) {
          this.catalog.setSearchParams("page", page);

          let newTitle = document.title.replace(/[0-9]+/g, page);

          document.title = newTitle;
          // document.querySelector('meta[name="description"]').setAttribute("content", newTitle);
          // document.querySelector('meta[property="og:description"]').setAttribute("content", newTitle);

        } else {
          this.catalog.deleteSearchParams("page");

          let newTitle = document.title.replace(/[0-9]+/g, 1);
          document.title = newTitle;
        }

        this.catalog.setHistory();

        window.scrollTo(0, 0);
      });
    });
  },
};
</script>
