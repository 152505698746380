<template>
  <div class="product-card__images">
    <svg
      class="product-card__images-clip"
      preserveAspectRatio="none"
      viewBox="0 0 105 105"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <clipPath id="svgPath" clipPathUnits="objectBoundingBox">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0.201889 0.000237061C0.299638 0.0052343 0.395599 0.0152326 0.49514 0.0152326C0.594682 0.0152326 0.697306 0.0052343 0.798389 0.000237061C0.90914 -0.00523912 1 0.0909658 1 0.201856C1 0.30985 0.988337 0.417118 0.988337 0.525112C0.988337 0.61596 1 0.707533 1 0.798381C1 0.909272 0.909276 1 0.798389 1C0.696377 1 0.59382 0.985004 0.491809 0.985004C0.394987 0.985004 0.29871 1 0.201889 1C0.0910016 1 0.000277405 0.909272 0.000277405 0.798381C0.000277405 0.70947 0.0235368 0.620551 0.0186059 0.531777L0.000277405 0.201856C-0.00587302 0.0911359 0.091147 -0.00542428 0.201889 0.000237061Z"
          fill="none"
        />
      </clipPath>
    </svg>
    <div class="product-card__images-preview">
      <div
        class="product-card__images-preview-item"
        v-for="(item, index) in dataArray"
        :key="`preview${index}`"
        @click="setCurrentImage(index)"
      >
        <picture>
          <source :srcset="item.webp" type="image/webp" />
          <img :src="item.img" :alt="item.alt" :title="item.title" />
        </picture>
      </div>
    </div>
    <div class="product-card__images-main">
      <picture>
        <source :srcset="dataArray[currentImage].webp" type="image/webp" />
        <img
          :src="dataArray[currentImage].img"
          :alt="dataArray[currentImage].alt"
          :title="dataArray[currentImage].title"
        />
      </picture>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductImages",
  props: {
    dataArray: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      currentImage: 0,
    };
  },
  mounted() {},
  methods: {
    setCurrentImage(index) {
      this.currentImage = index;
    },
  },
};
</script>
