<template>
  <div ref="wrap">
    <slot/>
  </div>
</template>

<script>
import { useEncyclopediaStore } from "../stores/encyclopedia";

export default {
  name: 'EncyclopediaPagination',
  setup() {
    return {
      encyclopedia: useEncyclopediaStore(),
    }
  },
  mounted() {
    this.$refs.wrap.querySelectorAll('a').forEach(a => {
      const url = new URL(a.href);
      const page = url.searchParams.get("page");

      a.href = `/calendopedia/${ url.search }`;

      a.addEventListener('click', e => {
        e.preventDefault();

        this.encyclopedia.update(url.search.slice(1));

        let newTitle = document.title.replace(/[0-9]+/g, page);

        document.title = newTitle;

      })
    })
  },
}
</script>
