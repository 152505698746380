<template>
  <div class="hello">
    <div>{{ msg }}</div>
    <div class="mt-4" v-if="!isEditMode">
      <button @click="mode = 'edit'; form.fio = fio">Исправить...</button>
    </div>
    <div class="mt-4" v-if="isEditMode">
      <form @submit.prevent="submit">
        <input placeholder="FIO" v-model="form.fio">
        <button type="submit">Сохранить</button>
        <button v-if="isEditMode" @click="mode = 'view'">Отменить</button>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  data() {
    return {
      mode: 'view',
      fio: '',
      form: {
        fio: '',
      }
    }
  },
  computed: {
    isEditMode() {
      return this.mode === 'edit'
    },
    msg() {
      return `Hello, ${this.fio}!`
    }
  },
  methods: {
    submit() {
      this.mode = 'view'
      this.fio = this.form.fio
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.hello {
  background: #f5f2df;
  border: dashed 1px #ccc;
  padding: 8px;
}
.mt-4 {
  margin-top: 4px;
}
</style>
