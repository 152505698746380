import { render, staticRenderFns } from "./SliderMainArticle.vue?vue&type=template&id=7099e73c&"
import script from "./SliderMainArticle.vue?vue&type=script&lang=js&"
export * from "./SliderMainArticle.vue?vue&type=script&lang=js&"
import style0 from "./SliderMainArticle.vue?vue&type=style&index=0&id=7099e73c&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports