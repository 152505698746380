<template comments>
    <div :class="['header2', {'header_menu-opened': menuOpened, 'header_search-opened': searchOpened}]">
      <div class="">
        <div class="header2__holder">
          <div class="header__menu-button" @click="toggleMenu">
            <svg v-if="menuOpened" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15 1L1 15" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M1 1L15 15" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <svg v-else xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="none">
            <path d="M2 8H14" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M2 3H14" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M2 13H14" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
          <div class="header2__logo">
            <a href="/" rel="nofollow">
              <img src="/frontend/assets/img/logo2.svg" alt="">
            </a>
          </div>
          <slot name="menu-catalog" v-if="!isTablet"/>
          <slot name="search" v-if="!isTablet"/>
          <slot name="menu2" v-if="!isTablet"/>
          <!--noindex-->
          <div :class="['header__search-button', {'header__search-button--hide': menuOpened}]" @click="toggleSearch">
            <svg  viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M18.2507 18.2497L14.1385 14.1302M16.4173 8.62468C16.4173 10.6912 15.5964 12.673 14.1352 14.1342C12.674 15.5954 10.6921 16.4163 8.62565 16.4163C6.55917 16.4163 4.57733 15.5954 3.11611 14.1342C1.65489 12.673 0.833984 10.6912 0.833984 8.62468C0.833984 6.5582 1.65489 4.57636 3.11611 3.11513C4.57733 1.65391 6.55917 0.833008 8.62565 0.833008C10.6921 0.833008 12.674 1.65391 14.1352 3.11513C15.5964 4.57636 16.4173 6.5582 16.4173 8.62468V8.62468Z" stroke="#1D388F" stroke-width="1.5" stroke-linecap="round"/>
            </svg>
          </div>
          <!--/noindex-->

          <transition name="slide">
            <div class="header__drop-menu" v-if="searchOpened && isTablet">
              <slot name="search"/>
            </div>
          </transition>
          <transition name="slide">
            <div class="header__drop-menu header__drop-menu_full-height" v-if="menuOpened && isTablet" id="drop_menu">
              <slot
                  name="actions"
                  :login="signInUws"
                  :register="signUpUws"
                  :logout="logoutUws"
                  :is-authorized="uwsUser.isAuthorized"
                />
                <slot name="search"/>
                <slot name="menu"/>
            </div>
          </transition>
        </div>
      </div>
    </div>
</template>
<script>
import { eventBus } from "../main";

export default {
  name: 'SiteHeader',
  inject: ['uwsUser'],
  props: {
    new:  {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      menuOpened: false,
      searchOpened: false,
    }
  },
  computed: {
    isTablet: function () {
      return this.$vssWidth < 1024
    }
  },
  methods: {
    toggleMenu() {
      this.menuOpened = !this.menuOpened;
      this.searchOpened = false;
      this.$nextTick(() => {
        if (this.menuOpened) {
          document.documentElement.classList.toggle('html_state_menu');
          window.scrollTo(0, 0);
        } else {
          document.documentElement.classList.toggle('html_state_menu');
        }
      })
    },
    toggleSearch() {
      this.searchOpened = !this.searchOpened;
      this.menuOpened = false;
      document.body.style.overflow = null;
    },
    signInUws() {
      eventBus.$emit("signInUws");
      this.menuOpened = false;
    },
    signUpUws() {
      eventBus.$emit("signUpUws");
      this.menuOpened = false;
    },
    logoutUws() {
      eventBus.$emit("logoutUws");
      this.menuOpened = false;
    }
  }
}
</script>

