<template>
  <a ref="a" @click.prevent="onClickUrl">
    <slot/>
  </a>
</template>

<script>
import {useCatalogStore} from "../stores/catalog";

export default {
  name: 'CatalogLink',
  setup() {
    return {
      catalog: useCatalogStore()
    }
  },
  methods: {
    onClickUrl() {
      this.catalog.update(this.$refs.a.href, this.$refs.a.getAttribute('data-ajax-url'));
    }
  },
};
</script>

