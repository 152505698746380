<template>
  <div
    :class="[
      { slider: `slider-${id}` },
      { 'slider_no-next-visible': !visibleNext },
    ]"
  >
    <swiper ref="Swiper" :options="swiperOptions">
      <slot />
    </swiper>
    <div class="slider__controls" :class="{ 'g-hidden': slidesCount <= items }">
      <div :class="['slider__prev', `slider-${id}__prev`]">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M21 12L3 12"
            stroke="#005DAA"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M10 19L3 12L10 5"
            stroke="#005DAA"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <div :class="['slider__next', `slider-${id}__next`]">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M3 12L21 12"
            stroke="#005DAA"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M14 19L21 12L14 5"
            stroke="#005DAA"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Slider",
  props: {
    id: {
      type: String,
      default: "0",
    },
    items: {
      type: Number,
      default: 3,
    },
    visibleNext: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      slidesCount: 0,
      swiperOptions: {
        slidesPerView: 1,
        spaceBetween: 0,
        watchOverflow: true,
        loop: false,
        loopAdditionalSlides: 1,
        navigation: {
          prevEl: `.slider-${this.id}__prev`,
          nextEl: `.slider-${this.id}__next`,
          disabledClass: "is-disabled",
        },
        pagination: {
          el: `.slider-${this.id}__bullets`,
          type: "bullets",
          bulletClass: "slider__bullet",
          bulletActiveClass: "slider__bullet_active",
          clickable: true,
        },
        on: {
          // slideChangeTransitionStart: function (swiper) {
          //   setTimeout(() => {
          //     let $wrapperEl = swiper.$wrapperEl;
          //     let params = swiper.params;
          //     $wrapperEl
          //       .children(
          //         "." + params.slideClass + "." + params.slideDuplicateClass
          //       )
          //       .each(function () {
          //         console.log("mounted()");
          //         this.remove();
          //       });
          //   }, 1);
          // },
          // slideChangeTransitionStart: function (swiper) {
          //   console.log('slideChangeTransitionStart');
          //   let $wrapperEl = swiper.$wrapperEl;
          //   let params = swiper.params;
          //   $wrapperEl
          //     .children(
          //       "." + params.slideClass + "." + params.slideDuplicateClass
          //     )
          //     .each(function () {
          //       let idx = this.getAttribute("data-swiper-slide-index");
          //       this.innerHTML = $wrapperEl
          //         .children(
          //           "." +
          //             params.slideClass +
          //             '[data-swiper-slide-index="' +
          //             idx +
          //             '"]:not(.' +
          //             params.slideDuplicateClass +
          //             ")"
          //         )
          //         .html();
          //     });
          // },
          // slideChangeTransitionEnd: function (swiper) {
          //   swiper.slideToLoop(swiper.realIndex, 0, false);
          // },
        },
        breakpoints: {
          768: {
            spaceBetween: "2,06186%",
            slidesPerView: 3,
          },
          1366: {
            // spaceBetween: '1.5625%',
            spaceBetween: "1.764705%",
            slidesPerView: 4,
          },
          // 1920: {
          //   spaceBetween: '1.7647%',
          //   slidesPerView: 4,
          // },
        },
      },
    };
  },
  computed: {
    swiper() {
      return this.$refs.Swiper.$swiper;
    },
    isMobile: function () {
      return this.$vssWidth < 768;
    },
  },
  mounted() {
    this.slidesCount = this.$refs.Swiper.$children.length;

    // let $wrapperEl = this.swiper.$wrapperEl;

    // $wrapperEl.children(".swiper-slide-duplicate").forEach((duplicateSlide) => {
    //   duplicateSlide.target.remove();
    //   this.swiper.update();
    //   console.log(duplicateSlide);
    // });

    // setTimeout(() => {
    //   let params = this.swiper.params;
    //   $wrapperEl
    //     .children("." + params.slideClass + "." + params.slideDuplicateClass)
    //     .each(function () {
    //       console.log("mounted()");
    //       this.remove();
    //     });
    // }, 1);
  },
};
</script>
