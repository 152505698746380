<template>
  <div
    ref="select"
    class="custom-select"
    :class="[
      { 'custom-select_opened': isOpen },
      { 'custom-select_disabled': !dataArray.length },
      { _error: v && v.$error },
      { 'custom-select_gray': gray },
    ]"
    @keydown.esc="close"
  >
    <input type="hidden" :name="name" v-model="inputValue" />
    <div
      :class="[
        'custom-select__value',
        { 'custom-select__value_empty': isEmpty },
      ]"
      @click="isOpen = !isOpen"
    >
      <template v-if="isEmpty">
        <span v-if="!alwaysShowPlaceholder" class="g-hidden-tablet">
          Выберите...
        </span>
        <span :class="{ 'g-visible-tablet': !alwaysShowPlaceholder }">
          {{ placeholder }}
        </span>
      </template>
      <template v-else
        ><span>{{ text }}</span></template
      >
    </div>
    <svg
      class="custom-select__arrow"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z"
      />
    </svg>
    <div
      v-if="!isEmpty"
      class="custom-select__reset"
      title="Сбросить"
      @click="reset"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xml:space="preserve"
        viewBox="0 0 384.1 384.2"
      >
        <path
          d="M373.6 322.7 243 192.1 373.6 61.5c14.1-14.1 14.1-36.8 0-50.9s-36.8-14.1-50.9 0L192.1 141.2 61.5 10.6c-14.1-14.1-36.8-14.1-50.9 0s-14.1 36.8 0 50.9l130.6 130.6L10.6 322.7c-14.1 14.1-14.1 36.8 0 50.9s36.8 14.1 50.9 0L192.1 243l130.6 130.6c14.1 14.1 36.8 14.1 50.9 0 14-14.1 14-36.9 0-50.9z"
        />
      </svg>
    </div>
    <transition name="slide-fade">
      <vue-custom-scrollbar
        class="custom-select__list"
        ref="dummyWrap"
        v-if="isOpen"
      >
        <ul class="">
          <li
            v-for="(item, i) in dataArray"
            :key="i"
            @click="selectOption(item)"
          >
            {{ item.text }}
          </li>
        </ul>
      </vue-custom-scrollbar>
    </transition>
  </div>
</template>

<script>
import vueCustomScrollbar from "vue-custom-scrollbar";

export default {
  name: "CustomSelect",
  components: {
    vueCustomScrollbar,
  },
  props: {
    dataArray: {
      type: Array,
      default: () => {
        return [];
      },
    },
    v: {
      type: Object,
    },
    placeholder: {
      type: String,
      default: "",
    },
    alwaysShowPlaceholder: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: "",
    },
    gray: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: "type",
    },
  },
  data() {
    return {
      text: "",
      inputValue: this.value,
      isOpen: false,
    };
  },
  computed: {
    isEmpty() {
      return !this.inputValue;
    },
  },
  watch: {
    value(newValue, oldValue) {
      const foundOption = this.dataArray.find(
        (option) => option.value === newValue
      );

      if (newValue && newValue !== oldValue && foundOption) {
        this.inputValue = foundOption.value;
        this.text = foundOption.text;
      } else {
        this.inputValue = "";
        this.text = "";
      }
    },
  },
  created() {
    window.addEventListener("click", (e) => {
      if (this.$refs.select && !this.$refs.select.contains(e.target)) {
        this.close();
      }
    });
  },
  beforeDestroy() {
    window.removeEventListener("click", this.close);
  },
  methods: {
    selectOption(item) {
      this.text = item.text;
      this.inputValue = item.value;
      if (this.v) {
        this.v.$touch();
      }
      this.close();
      this.$emit("input", this.inputValue);
    },
    open() {
      this.isOpen = !this.isOpen;
    },
    close() {
      this.isOpen = false;
    },
    reset() {
      this.close();
      this.text = "";
      this.inputValue = "";
      this.$emit("reset");
    },
  },
};
</script>
