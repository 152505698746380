<template>
  <div class="generator-detail">
    <ul class="generator-detail__buttons">
      <li v-if="hasFavorites">
        <a
          :href="`${baseLink}/user/`"
          class="button button_small button_bordered"
          >Понравившиеся имена</a
        >
      </li>
      <li>
        <a :href="`${baseLink}/options/`" class="button button_small"
          >К выбору имени</a
        >
      </li>
    </ul>
    <div class="generator-detail__head">
      <div class="generator-detail__name">
        <span> {{ info.name }} </span>
        <span v-if="userData">
          {{ userData.surname }} {{ userData.patronymic }}
        </span>
      </div>
      <div class="generator-detail__actions">
        <div
          class="generator-detail__favorite"
          @click.prevent="toggleFavorite(info.id)"
        >
          <template v-if="isFavorite">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xml:space="preserve"
              viewBox="0 0 31 28"
            >
              <path
                fill="#FC3E48"
                d="M15.5 28c-.4 0-.8-.1-1.1-.3l-.8-.5s-3.2-2.1-6.5-5.1c-1.9-1.8-3.5-3.6-4.6-5.3-.7-1.1-1.3-2.2-1.7-3.3C.3 12.3 0 11 0 9.8 0 8.5.3 7.2.8 6 1.3 4.8 2 3.7 2.9 2.8c.9-.9 2-1.6 3.1-2.1 2.9-1.2 6.3-.9 9 .7.1.1.3.2.4.3.1-.1.3-.2.4-.3C17.4.4 19.2-.1 21-.1c1.3 0 2.6.3 3.8.8 1.2.5 2.2 1.2 3.1 2.1.9.9 1.6 1.9 2.1 3.1.7 1.3 1 2.5 1 3.9 0 1.2-.3 2.5-.7 3.8-.4 1.1-1 2.1-1.7 3.3-1.1 1.7-2.7 3.5-4.6 5.3-3.2 2.9-6.3 5-6.5 5.1l-.8.5c-.4.1-.8.2-1.2.2z"
              />
            </svg>
          </template>
          <template v-else>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xml:space="preserve"
              viewBox="0 0 31 28"
            >
              <path
                fill="#2e408d"
                d="M15.5 28c-.4 0-.8-.1-1.1-.3l-.8-.5s-3.2-2.1-6.5-5.1c-1.9-1.8-3.5-3.6-4.6-5.3-.7-1.1-1.3-2.2-1.7-3.3C.3 12.3 0 11 0 9.8 0 8.5.3 7.2.8 6 1.3 4.8 2 3.7 2.9 2.8c.9-.9 2-1.6 3.1-2.1 2.9-1.2 6.3-.9 9 .7.1.1.3.2.4.3.1-.1.3-.2.4-.3C17.4.4 19.2-.1 21-.1c1.3 0 2.6.3 3.8.8 1.2.5 2.2 1.2 3.1 2.1.9.9 1.6 1.9 2.1 3.1.7 1.3 1 2.5 1 3.9 0 1.2-.3 2.5-.7 3.8-.4 1.1-1 2.1-1.7 3.3-1.1 1.7-2.7 3.5-4.6 5.3-3.2 2.9-6.3 5-6.5 5.1l-.8.5c-.4.1-.8.2-1.2.2zM9.9 2c-1.1 0-2.1.2-3.1.6-.9.4-1.8 1-2.5 1.7S3 5.8 2.6 6.7c-.4 1-.6 2-.6 3s.2 2 .6 3.1c.4.9.9 1.9 1.5 2.9 1.1 1.6 2.5 3.2 4.3 4.9 3.1 2.9 6.1 4.8 6.2 4.8l.8.5.9-.5c.1-.1 3.1-2 6.2-4.8 1.8-1.7 3.3-3.3 4.3-4.9.7-1 1.2-1.9 1.5-2.9.4-1.1.6-2.1.6-3.1s-.2-2.1-.6-3c-.4-.9-1-1.8-1.7-2.5-.6-.6-1.5-1.2-2.4-1.6-1-.4-2-.6-3-.6-1.5 0-2.9.4-4.2 1.2-.3.2-.6.4-.8.6l-.6.5-.6-.5c-.3-.2-.5-.4-.8-.6C12.8 2.4 11.3 2 9.9 2z"
              />
            </svg>
          </template>
        </div>
        <div class="generator-detail-share" v-click-outside="closeShare">
          <div
            class="generator-detail-share__button"
            @click.prevent="toggleShare"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xml:space="preserve"
              viewBox="0 0 24 26"
            >
              <path
                d="M5 18c-2.8 0-5-2.2-5-5s2.2-5 5-5 5 2.2 5 5-2.2 5-5 5zm0-8c-1.7 0-3 1.3-3 3s1.3 3 3 3 3-1.3 3-3-1.3-3-3-3zM19 26c-2.8 0-5-2.2-5-5s2.2-5 5-5 5 2.2 5 5-2.2 5-5 5zm0-8c-1.7 0-3 1.3-3 3s1.3 3 3 3 3-1.3 3-3-1.3-3-3-3zM19 10c-2.8 0-5-2.2-5-5s2.2-5 5-5 5 2.2 5 5-2.2 5-5 5zm0-8c-1.7 0-3 1.3-3 3s1.3 3 3 3 3-1.3 3-3-1.3-3-3-3z"
                fill="#2e408d"
              />
              <path
                d="M8.1 7.8h7.4v2H8.1z"
                fill="#2e408d"
                transform="rotate(-28.309 11.748 8.75)"
              />
              <path
                d="M11 13.8h2v6.9h-2z"
                fill="#2e408d"
                transform="rotate(-59.743 12 17.25)"
              />
            </svg>
          </div>
          <div v-show="isShareOpen" class="generator-detail-share__popup">
            <div class="ya-share ya-share_center">
              <div
                class="ya-share2"
                data-bare
                data-services="vkontakte,facebook,odnoklassniki"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="generator-detail__body">
      <div class="generator-detail__desc">
        <div class="generator-detail__text typography" v-html="info.desc" />
      </div>
      <div class="generator-detail__related">
        <ul class="generator-detail__related-list">
          <li>
            <a
              :href="`${baseLink}/info/?letter=${getFirstLetter(
                info.name,
                true
              )}`"
            >
              Другие имена на букву {{ getFirstLetter(info.name) }}
            </a>
          </li>
          <li v-if="info.origins.length">
            <a :href="`${baseLink}/info/?origin=${info.origins[0].value}`">
              Другие {{ info.origins[0].text.toLowerCase() }} имена
            </a>
          </li>
          <li v-if="info.themes.length">
            <a :href="`${baseLink}/info/?theme=${info.themes[0].value}`">
              Другие {{ info.themes[0].text.toLowerCase() }} имена
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GeneratorDetail',
  props: {
    baseLink: {
      type: String,
      default: '',
    },
    info: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      userData: null,
      isShareOpen: false,
    };
  },
  computed: {
    isFavorite() {
      return this.userData && this.userData.favorites
        ? this.userData.favorites.some((id) => this.info.id === id)
        : false;
    },
    hasFavorites() {
      return (
        this.userData &&
        this.userData.favorites &&
        this.userData.favorites.length
      );
    },
  },
  created() {
    this.userData = this.getUserData();
  },
  methods: {
    getFirstLetter(string, isLowerCase) {
      const firstLetter = string.charAt(0);
      return isLowerCase
        ? firstLetter.toLowerCase()
        : firstLetter.toUpperCase();
    },
    getUserData() {
      return JSON.parse(localStorage.getItem('generatorUserData'));
    },
    setStorage(obj) {
      localStorage.setItem('generatorUserData', JSON.stringify(obj));
    },
    toggleShare() {
      this.isShareOpen = !this.isShareOpen;
    },
    closeShare() {
      this.isShareOpen = false;
    },
    toggleFavorite(id) {
      this.userData = this.getUserData() || {};
      this.userData.favorites = this.userData.favorites || [];

      if (this.userData.favorites.includes(id)) {
        this.userData.favorites = this.userData.favorites.filter(
          (item) => item !== id
        );
      } else {
        this.userData.favorites.push(id);
      }

      this.setStorage(this.userData);
    },
  },
};
</script>

<style lang="scss">
@import '../../scss/base/includes.scss';

$b: '.generator-detail';

#{$b} {
  display: flex;
  flex-direction: column;
  padding-bottom: 100px;

  &__buttons {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;

    @include tablet {
      order: 99;
      margin: 32px 0 0;
    }

    @include mobile {
      flex-direction: column;
    }

    &:not(:last-child) {
      margin-bottom: 24px;

      @include tablet {
        margin-bottom: 0;
      }
    }

    & > li {
      margin: 0 16px 16px 0;

      @include mobile {
        margin: 0 0 16px;
      }

      & > * {
        @include mobile {
          width: 100%;
        }
      }
    }
  }

  &__head {
    display: flex;
    align-items: center;

    @include mobile {
      flex-direction: column;
      align-items: stretch;
    }

    &:not(:last-child) {
      margin-bottom: 24px;
    }

    & > * {
      &:not(:last-child) {
        margin-right: 32px;

        @include mobile {
          margin-right: 0;
          margin-bottom: 24px;
        }
      }
    }
  }

  &__name {
    display: block;
    font-family: 'PH', sans-serif;
    line-height: 1;
    font-weight: 600;
    margin: 0;

    & > span {
      &:first-child {
        font-size: 48px;
        color: $brand;

        @include mobile {
          display: block;
          font-size: 36px;
        }
      }
      &:nth-child(2) {
        font-size: 30px;
        color: $accent;

        @include mobile {
          font-size: 24px;
        }
      }
    }
  }

  &__actions {
    display: flex;
    align-items: center;

    & > * {
      &:not(:last-child) {
        margin-right: 24px;
      }
    }
  }

  &__favorite {
    width: 26px;
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    @include clickable;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &-share {
    position: relative;

    &__button {
      width: 26px;
      height: 26px;
      display: flex;
      align-items: center;
      justify-content: center;
      @include clickable;

      svg {
        width: 100%;
        height: 100%;
      }
    }

    &__popup {
      position: absolute;
      top: 100%;
      left: -20px;
      background: $white-true;
      padding: 12px;
      border-radius: 4px;
      box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.15);
      margin: 16px 0 0;

      &::before {
        content: '';
        position: absolute;
        top: -8px;
        left: 24px;
        width: 0;
        height: 0;
        border-bottom: 8px solid $white-true;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
      }

      .ya-share2 {
        &__icon {
          width: 32px;
          height: 32px;
        }
      }
    }
  }

  &__desc {
    &:not(:last-child) {
      margin-bottom: 32px;
    }
  }

  &__text {
    font-size: 18px;
    line-height: 1.66;
    color: $dark;

    @include tablet {
      font-size: 16px;
    }
  }

  &__related {
    &:not(:last-child) {
      margin-bottom: 32px;
    }
  }

  &__related-list {
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 18px;
    line-height: 1.33;

    & > li {
      &:not(:last-child) {
        margin-bottom: 12px;
      }

      & > a {
        display: inline-block;
        text-decoration: none;
        color: $link-color;
      }
    }
  }
}
</style>
