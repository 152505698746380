<template>
  <div class="header-search">
    <form
      ref="form"
      action="/search/"
      class="header-search__form"
      @submit.prevent="submit"
    >
      <div class="header-search__field">
        <input
          class="header-search__input"
          :class="{ 'is-error': errorLength }"
          type="text"
          name="query"
          placeholder="Поиск на сайте"
          maxlength="50"
          v-model="inputValue"
          @input="input"
          @focus="focus"
          @blur="blur"
          autocomplete="off"
        />
        <button
          class="header-search__button"
          type="submit"
          @click.prevent="submit"
        >
          <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.2507 18.2497L14.1385 14.1302M16.4173 8.62468C16.4173 10.6912 15.5964 12.673 14.1352 14.1342C12.674 15.5954 10.6921 16.4163 8.62565 16.4163C6.55917 16.4163 4.57733 15.5954 3.11611 14.1342C1.65489 12.673 0.833984 10.6912 0.833984 8.62468C0.833984 6.5582 1.65489 4.57636 3.11611 3.11513C4.57733 1.65391 6.55917 0.833008 8.62565 0.833008C10.6921 0.833008 12.674 1.65391 14.1352 3.11513C15.5964 4.57636 16.4173 6.5582 16.4173 8.62468V8.62468Z" stroke="#1D388F" stroke-width="1.5" stroke-linecap="round"/>
          </svg>
        </button>
      </div>
      <div
        v-show="inputFocus && suggestions.length"
        class="header-search__suggestions"
      >
        <ul class="header-search__suggestions-list">
          <li v-for="(suggestion, i) in suggestions" :key="i">
            <div
              class="header-search__suggestions-item"
              v-html="suggestion"
              @click.prevent="setQueryValue(suggestion)"
            />
          </li>
        </ul>
      </div>
    </form>
  </div>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';

export default {
  props: {
    searchTipsUrl: {
      type: String,
      default: '',
    },
    searchQuery: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      inputValue: this.searchQuery,
      inputFocus: false,
      errorLength: false,
      suggestions: [],
    };
  },
  methods: {
    isValidLength(isSubmit) {
      const trimValue = this.inputValue.toString().trim();

      return isSubmit ? trimValue.length >= 1 : trimValue.length >= 3;
    },
    setQueryValue(value) {
      this.inputValue = value.replace(/(<([^>]+)>)/gi, '');
      this.submit();
    },
    input: _.debounce(function () {
      if (this.isValidLength(true)) {
        this.getSuggestions(this.inputValue);
        this.errorLength = false;
      } else {
        this.errorLength = true;
      }
    }, 500),
    focus() {
      this.inputFocus = true;
    },
    blur: _.debounce(function () {
      this.inputFocus = false;
    }, 100),
    async getSuggestions(query) {
      try {
        const response = await axios({
          url: this.searchTipsUrl,
          method: 'POST',
          params: {
            query: query,
          },
        });

        if (response.data.status === 'success') {
          this.suggestions = response.data.data;
        }
      } catch (e) {
        console.log('Ошибка!', e);
      }
    },
    submit: _.debounce(function () {
      if (this.isValidLength(true)) {
        this.$refs.form.submit();
      } else {
        this.errorLength = true;
      }
    }, 100),
  },
};
</script>
