<template>
  <div>
    <div class="article__side-header">
      <h3 class="g-visible-tablet" @click="toggle">
        {{ title }}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          :class="{ _flip: opened }"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M3 6L8 11L13 6"
            stroke="#005DAA"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </h3>
    </div>
    <div class="article__content-wrap">
      <div
        class="scroll-area scroll-container"
        ref="dummyWrap"
      >
        <div ref="dummy"></div>
        <template v-if="contents.length > 0">
          <transition name="slide">
            <ul class="article__content-list" v-if="opened || !isTablet">
              <li
                v-for="(item, index) in contents"
                :key="`content-item${index}`"
                :class="[{ _current: item.link === currentLink }]"
              >
                <a
                  :href="`#${item.link}`"
                  @click="toggle"
                >
                  <!-- @click="chooseChapter($event, item.link)" -->
                  {{ item.name }}
                </a>
              </li>
            </ul>
          </transition>
        </template>
      </div>
      <slot />
      <div class="article__sharing-wrap">
        <div class="article__sharing-title">Поделиться с друзьями</div>
        <div class="article__sharing">
          <div
            class="ya-share2"
            data-bare
            data-services="vkontakte,telegram,odnoklassniki"
          ></div>
          <copy-link></copy-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ArticleContents",
  components: {
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    contents: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      opened: false,
      currentLink: "",
    };
  },
  computed: {
    isTablet: function () {
      return this.$vssWidth < 1024;
    },
  },
  watch: {},
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  mounted() {
    let sharingScript = document.createElement("script");
    sharingScript.setAttribute("src", "https://yastatic.net/share2/share.js");
    document.head.appendChild(sharingScript);

    this.resizeHandler();
    const observer = new ResizeObserver(() => {
      this.resizeHandler();

    });
    observer.observe(this.$refs.mainWrap.$el);
  },
  methods: {
    resizeHandler() {
      this.$refs.dummyWrap.update();
      this.$refs.mainWrap.update();
    },

    toggle() {
      const scrollSide = document
        .querySelector(".article__side")
        .getBoundingClientRect().y;
      if (scrollSide > 0) {
        window.scrollTo(0, scrollSide);
      }
      document.documentElement.classList.toggle("article_state_menu");

      this.opened = !this.opened;
    },
    chooseChapter(e, id) {
      if (this.isTablet) {
        e.preventDefault();
        const el = document.getElementById(id);

        this.opened = false;
        document.documentElement.classList.remove("article_state_menu");

        window.scrollTo(0, el.offsetTop);
        //this.opened = false;
      }
    },
    handleScroll() {
      this.contents.map((obj) => {
        const el = document.getElementById(obj.link);
        if (el && this.isInViewport(el)) {
          this.currentLink = obj.link;

          var myElement = document.getElementsByClassName('_current')[0];
          var topPos = myElement.offsetTop;

          document.getElementsByClassName('scroll-area')[0].scrollTop = topPos;
        }
      });
    },
    isInViewport(el) {
      const rect = el.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
          200 &&
        rect.right <=
          (window.innerWidth || document.documentElement.clientWidth)
      );
    },
  },
};
</script>
<style>
.scroll-area {
  position: relative;
  margin: auto;
}
</style>
