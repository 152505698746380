class UwsPassport {
  constructor(baseUrl, version, projectId, settings = {}) {
    this.baseUrl = baseUrl;
    this.version = version;
    this.projectId = projectId;
    this.settings = settings;
    this.widget = null;
  }

  getWidget() {
    if (!this.widget) {
      this.widget = new Promise((resolve) => {
        const scriptTag = document.createElement('script');
        scriptTag.src = `${this.baseUrl}/widget-v${this.version}.js`;
        scriptTag.onload = () => {
          if (this.settings.inlineTarget && !document.querySelector(this.settings.inlineTarget)) {
            delete this.settings.inlineTarget;
          }

          this.widget = new window.UWSPassportWidget({baseUrl:  this.baseUrl, projectId: this.projectId, ...this.settings});
          resolve(this.widget);
        };
        document.body.appendChild(scriptTag);
      });
    }

    return this.widget;
  }
}

export default UwsPassport;
