<template>
  <SwiperSlide class="characters-slider__slide">
    <div
      v-click-outside="handleClickOutside"
      class="characters-slider__slide-content"
    >
      <CharactersSliderButtonClose
        @click="$emit('close')"
      />

      <vue-custom-scrollbar class="characters-slider__slide-image-container">
        <Viewer :options="viewerOptions">
          <picture>
            <source
              :srcset="item.image_webp"
              type="image/webp"
            >
            <img
              class="characters-slider__slide-image"
              :src="item.image"
              loading="lazy"
            />
          </picture>
        </Viewer>
      </vue-custom-scrollbar>

      <div class="characters-slider__slide-button-wrapper">
        <a
          :href="item.image_original"
          :download="`${character.name}_стих_${index + 1}`"
          target="_blank"
          class="characters-slider__slide-button button"
        >
          Скачать
        </a>
      </div>
    </div>
  </SwiperSlide>
</template>

<script>
import 'viewerjs/dist/viewer.css'
import { component as Viewer } from "v-viewer"
import CharactersSliderButtonClose from './CharactersSliderButtonClose.vue';
import vueCustomScrollbar from 'vue-custom-scrollbar';

export default {
  name: "CharactersSlider",

  components: {
    Viewer,
    CharactersSliderButtonClose,
    vueCustomScrollbar,
},

  props: {
    item: {
      type: String,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    character: { type: Object, default: null }
  },

  data() {
    return {
      viewerOptions: {
        toolbar: false,
        navbar: false,
        title: false
      }
    }
  },

  methods: {
    handleClickOutside(e) {
      this.$emit('click-outside', e);
    }
  }
}
</script>
