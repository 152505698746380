<script>
import Vue from "vue";
import { useNewsStore } from "../stores/news";

export default {
  name: 'NewsWrap',
  setup() {
    return {
      store: useNewsStore()
    }
  },
  methods: {
    wrapTemplate(template) {
      return `<news-inner>${template}</news-inner>`
    }
  },
  render(createElement) {
    return createElement(Vue.compile(
      this.store.template ?
      this.wrapTemplate(this.store.template) :
      this.wrapTemplate('<slot/>')), this.$slots.default)
  }
}
</script>
