<template>
  <div :class="['catalog-filter__box', {'catalog-filter__box_opened': opened}]">
    <h2 @click="toggle">
      {{ title }}
      <svg v-if="!noToggle" width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.05273 1.71289L5.05273 5.71289L9.05273 1.71289" stroke="#0275D8" stroke-width="2" stroke-linecap="round"/>
      </svg>
    </h2>
    <template v-if="opened">
      <ul class="catalog-filter__options">
        <li
          :class="['catalog-filter__category', {'g-hidden': index > 2 && !showAll}]"
          v-for="(item, index) in options"
          :key="`option${index}`"
        >
          <filter-suboptions
            :title="item.title"
            :link="item.url"
            :ajax_url="item.ajax_url"
            :options="item.subitems || []"
            :params="item.params"
            :selected="item.selected"
            :isOpened="item.selected"
          ></filter-suboptions>
        </li>
      </ul>
      <span
        class="catalog-filter__toggle"
        v-if="!showAll"
        @click="showAll = true"
      >
        Показать все {{ options.length }}
      </span>
      <span
        class="catalog-filter__toggle"
        v-else
        @click="showAll = false"
      >
      Скрыть
      </span>
    </template>
  </div>
</template>

<script>
import { useCatalogStore } from "../stores/catalog";
import FilterSuboptions from "./FilterSuboptions.vue";

export default {
  name: "FilterOptions",
  components: { FilterSuboptions },
  setup() {
    return {
      catalog: useCatalogStore()
    }
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    alias: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default: () => []
    },
    isOpened: {
      type: Boolean,
      default: true
    },
    isShowAll: {
      type: Boolean,
      default: false
    },
    noToggle: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showAll: this.alias === 'ages' ? true : this.isShowAll,
      opened: this.isOpened
    };
  },
  computed: {
  },
  mounted() {
    if (this.options.find(obj => obj.selected)) {
      this.opened = true;
      this.showAll = true;
    }
  },
  methods: {
    toggle() {
      if (!this.noToggle) {
        this.opened = !this.opened;
      }
    },
    onClickUrl(url, ajaxUrl) {
      this.catalog.update(url, ajaxUrl);
    }
  }
};
</script>

