<template>
  <div class="characters-slider__message">
    <div class="characters-slider__slide">
      <div 
        v-click-outside="handleClickOutside"
        class="characters-slider__slide-content"
      >
        <CharactersSliderButtonClose
          @click="$emit('close')"
        />
        <div class="characters-slider__slide-image-container">
          <Spinner />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CharactersSliderButtonClose from './CharactersSliderButtonClose.vue';
import Spinner from './Spinner.vue';

export default {
  name: "CharactersSliderLoading",

  components: {
    Spinner,
    CharactersSliderButtonClose
  },

  methods: {
    handleClickOutside(e) {
      this.$emit('click-outside', e);
    }
  }
}
</script>

