<template>
  <div class="article-filter__sort">
    <h3>Сортировать:</h3>
    <div class="article-filter__sort-select">
      <div class="article-filter__sort-select-value" @click.stop="isOpen = !isOpen">
        <svg v-if="isMobile" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path d="M17 19V4M17 4L22 9.15625M17 4L12 9.15625" stroke="#00B5ED" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M7 4V19M7 19L12 13.8438M7 19L2 13.8438" stroke="#217BC4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        {{ current.value }}
        <svg v-if="!isMobile" width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 1.5L5 5.5L9 1.5" stroke="#0275D8" stroke-width="2" stroke-linecap="round"/>
        </svg>
      </div>
      <ul :class="['article-filter__sort-select-options', {'article-filter__sort-select-options_state_open': isOpen }]" v-click-outside="close">
        <li v-for="item in sortList"
          :key="item.code"
          :class="{_selected: current.code === item.code}"
          @click.prevent="sort(item)"
        >{{ item.value }}</li>
      </ul>
    </div>
  </div>
</template>

<script>
import { useRecipesStore } from "../stores/recipes";

export default {
  name: 'RecipesSort',
  setup() {
    return {
      recipes: useRecipesStore(),
    };
  },
  data() {
    return {
      sortList: [
        {
          code: 'view',
          value: 'По количеству просмотров',
        },
        {
          code: 'rating',
          value: 'По рейтингу',
        },
        {
          code: 'complexity',
          value: 'По уровню сложности',
        },
      ],
      current: null,
      isOpen: false,
    }
  },
  computed: {
    isMobile: function () {
      return this.$vssWidth < 768
    },
  },
  created() {
    const sortValue = new URL(window.location).searchParams.get('sort');

    if(sortValue) {
      this.current = this.sortList.find(i => i.code === sortValue);
    } else {
      this.current = this.sortList[0];
    }

    this.recipes.setSearchParams('sort', this.current.code);
  },
  methods: {
    sort(item) {
      this.current = item;
      this.recipes.setSearchParams('sort', item.code);
      this.recipes.update();

      this.close();
    },
    close() {
      if (this.isOpen && this.isMobile) {
        this.isOpen = false;
      }
    },
  }
}

</script>

<style lang="scss">
@import '../scss/base/includes.scss';

.article-filter
{
  @include fluid('padding', 0);
}

.article-filter__sort-select-options
{
  @include bp-till($sm)
  {
    @include fluid('min-width', 0);
  }
  & li._selected
  {
    pointer-events: none;
  }
}

</style>
