<template>
  <div class="characters-slider__message">
    <div class="characters-slider__slide">
      <div 
        v-click-outside="handleClickOutside"
        class="characters-slider__slide-content"
      >
        <CharactersSliderButtonClose
          @click="$emit('close')"
        />
        <div class="characters-slider__slide-image-container">
          <div class="characters-slider__empty-message">
            Список стихов персонажа пуст
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CharactersSliderButtonClose from './CharactersSliderButtonClose.vue';

export default {
  name: "CharactersSliderEmpty",

  components: {
    CharactersSliderButtonClose
  },

  methods: {
    handleClickOutside(e) {
      this.$emit('click-outside', e);
    }
  }
}
</script>

<style lang="scss">
@import "../scss/base/includes.scss";

.characters-slider {
  &__empty-message {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-weight: 600;
    font-size: 18px;
    text-align: center;
  }
}
</style>
