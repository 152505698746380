<template>
  <div
    :class="['filter', 'filter_no-padding', 'filter--newborn', {'filter_closed': !opened}]"
    v-show="isVisible"
  >
    <div v-if="isMobile" class="filter__toggler-alt" @click="toggle"></div>
    <div class="filter__inner">
      <h3 class="filter__heading" @click="toggle">выберите <span>тему</span></h3>
      <transition name="slide">
        <div class="filter__theme" v-if="opened || !isMobile">
          <a v-for="(section, index) in sections"
             :key="index"
             :class="[
               'filter__theme-item',
               'filter__theme-item_' + section.code,
               {
                 'filter__theme-item_selected': checkSelected(section.code)
               }
             ]"
             :href="`#`"
             @click.prevent="selectSection(section.code)"
          >
            {{ section.name }}
          </a>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Vue from "vue";

import { useNewbornStore } from '../stores/newborn';

export default {
  name: 'NewbornFilter',
  setup() {
    const newbornStore = useNewbornStore();

    return {
      newbornStore
    }
  },
  props: {
    sections: {
      type: Array,
      default: () => {return[]}
    },
    currentSection: {
      type: String,
      default: 'all'
    },
    url: {
      type: String,
      required: true,
    },
    contentContainerId: {
      type: String,
      required: true,
    },
    visibleOnTypes: {
      type: Array,
      default: () => {return[]}
    },
  },
  data() {
    return {
      opened: true,
    }
  },
  computed: {
    isMobile: function () {
      return this.$vssWidth < 768
    },
    isVisible: function () {
      return this.visibleOnTypes.indexOf(this.currentType) !== -1
    },
    currentType: function () {
      return this.newbornStore.currentType;
    }
  },
  methods: {
    async selectSection(selectedSection) {
      let sectionParam = selectedSection !== 'all' ? selectedSection : '';
      const url = `${this.url}?type=${this.currentType}&section=${sectionParam}`;
      try {
        const response = await axios.get(url);

        let AjaxComponent = Vue.extend({
          template: response.data
        })
        let compiled = new AjaxComponent().$mount();

        const container = document.getElementById(this.contentContainerId);
        container.innerHTML = compiled.$el.outerHTML;
        this.currentSection = selectedSection;
      }
      catch (error) {
        console.log(error);
      }
    },
    toggle() {
      if (this.isMobile) {
        this.opened = !this.opened;
      }
    },
    checkSelected(value) {
      return value === this.currentSection;
    },
  },
}
</script>
