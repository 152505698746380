<template>
  <div
    :class="['catalog-tags']"
  >
    <!-- <div v-if="isMobile" class="filter__toggler-alt" @click="toggle"></div> -->
    <div v-for="(section, index) in sections"
      :key="index"
      :class="[
        'catalog-tags__item',
        {
          'catalog-tags__item_selected': checkSelected(section.code)
        }
      ]"
      @click.prevent="selectSection(section.code)"
    >
      <a>{{ section.name }}</a>
    </div>
  </div>
</template>

<script>
import { useRecipesStore } from "../stores/recipes";

export default {
  name: 'RecipesFilter',
  setup() {
    return {
      recipes: useRecipesStore(),
    };
  },
  props: {
    sections: {
      type: Array,
      default: () => {return[]}
    },
    currentSection: {
      type: String,
      default: 'all'
    },
    url: {
      type: String,
      required: true,
    },
    contentContainerId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      activeTag: this.currentSection,
      opened: true,
    }
  },
  computed: {
    isMobile: function () {
      return this.$vssWidth < 768
    },
  },
  watch: {
    'recipes.section'(section) {
      this.activeTag = section;
    },
  },
  created() {
    this.recipes.setSection(this.currentSection);
    this.recipes.setAjaxLink(this.url);
  },
  methods: {
    selectSection(selectedSection) {
      this.activeTag = selectedSection;
      this.recipes.setSection(selectedSection);
      this.recipes.setSearchParams('section', selectedSection !== 'all' ? selectedSection : '');
      this.recipes.update();
    },
    toggle() {
      if (this.isMobile) {
        this.opened = !this.opened;
      }
    },
    checkSelected(value) {
      return value === this.activeTag;
    },
  },
}
</script>

<style lang="scss">
.catalog-tags__item_selected
{
  pointer-events: none;
}

</style>
