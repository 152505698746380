<template>
  <div class="generator-options">
    <div class="generator-options__head g-hidden-mobile">
      <h2 class="generator-options-title">Кому выбираем имя?</h2>
    </div>
    <div class="generator-options__body">
      <form class="form">
        <template v-if="!isMobile">
          <ul class="generator-options-gender">
            <li v-for="(item, i) in genders" :key="i">
              <label
                :for="item.gender"
                class="generator-options-gender-item"
                :class="{
                  'generator-options-gender-item_disabled': !isAvailableGender(
                    item.gender
                  ),
                }"
              >
                <div class="generator-options-gender-item__img">
                  <img
                    :src="
                      isAvailableGender(item.gender)
                        ? item.images[0].src
                        : item.images[1].src
                    "
                    :alt="item.name"
                  />
                </div>
                <div class="custom-radio">
                  <input
                    :id="item.gender"
                    class="custom-radio__input"
                    name="gender"
                    type="radio"
                    :value="item.gender"
                    :checked="item.gender === form.gender"
                    @change="addCurrentParam('gender', $event.target.value)"
                  />
                  <div class="custom-radio__label">
                    <span>
                      {{ item.name }}
                    </span>
                  </div>
                </div>
              </label>
            </li>
          </ul>
        </template>
        <template v-else>
          <div class="generator-options-step">Шаг {{ currentStep }} из 3</div>
        </template>
        <template v-if="isMobile && currentStep === 1">
          <div class="generator-options-step-title">Выберите пол ребенка</div>
          <ul class="generator-options-buttons">
            <li v-for="(item, i) in availableGenders" :key="i">
              <div
                :class="
                  item.gender === 'unknown'
                    ? 'generator-options-button-link'
                    : 'button button_small'
                "
                @click.prevent="addCurrentParam('gender', item.gender)"
              >
                {{ item.name }}
              </div>
            </li>
          </ul>
        </template>
        <div class="generator-options-group">
          <div class="generator-options-group__list">
            <template v-if="!isMobile || currentStep === 2">
              <div class="generator-options-group__item">
                <div class="form__field">
                  <span class="form__label">Фамилия малыша</span>
                  <input
                    type="text"
                    name="surname"
                    v-model="form.surname"
                    :class="{ _error: $v.form.surname.$error }"
                    placeholder="Введите фамилию"
                    @change="$v.form.surname.$touch()"
                  />
                  <div
                    v-if="$v.form.surname.$error && !$v.form.surname.fio"
                    class="form__error"
                  >
                    Допускаются только буквы кирилицы и латиницы
                  </div>
                  <div
                    v-if="$v.form.surname.$error && !$v.form.surname.minLength"
                    class="form__error"
                  >
                    Введите не менее
                    {{ $v.form.surname.$params.minLength.min }} символов
                  </div>
                  <div
                    v-if="$v.form.surname.$error && !$v.form.surname.maxLength"
                    class="form__error"
                  >
                    Введите не более
                    {{ $v.form.surname.$params.maxLength.max }} символов
                  </div>
                </div>
              </div>
              <div class="generator-options-group__item">
                <div class="form__field">
                  <span class="form__label">Отчество малыша</span>
                  <input
                    type="text"
                    name="patronymic"
                    v-model="form.patronymic"
                    :class="{ _error: $v.form.patronymic.$error }"
                    placeholder="Введите отчество"
                    @change="$v.form.patronymic.$touch()"
                  />
                  <div
                    v-if="$v.form.patronymic.$error && !$v.form.patronymic.fio"
                    class="form__error"
                  >
                    Допускаются только буквы кирилицы и латиницы
                  </div>
                  <div
                    v-if="
                      $v.form.patronymic.$error && !$v.form.patronymic.minLength
                    "
                    class="form__error"
                  >
                    Введите не менее
                    {{ $v.form.patronymic.$params.minLength.min }} символов
                  </div>
                  <div
                    v-if="
                      $v.form.patronymic.$error && !$v.form.patronymic.maxLength
                    "
                    class="form__error"
                  >
                    Введите не более
                    {{ $v.form.patronymic.$params.maxLength.max }} символов
                  </div>
                </div>
              </div>
            </template>
            <template v-if="!isMobile || currentStep === 3">
              <div class="generator-options-group__item">
                <div class="form__field">
                  <span class="form__label">Происхождение имени</span>
                  <custom-select
                    v-model="form.originName"
                    :data-array="originsOptions"
                    placeholder="Выберите происхождение имени"
                    always-show-placeholder
                    @input="addCurrentParam('origin', $event)"
                    @reset="removeCurrentParam('origin', 'originName')"
                  >
                  </custom-select>
                </div>
              </div>
              <div class="generator-options-group__item">
                <div class="form__field">
                  <span class="form__label">Первая буква имени</span>
                  <custom-select
                    v-model="form.firstLetter"
                    :data-array="firstLetterOptions"
                    placeholder="Выберите первую букву имени"
                    always-show-placeholder
                    @input="addCurrentParam('letter', $event)"
                    @reset="removeCurrentParam('letter', 'firstLetter')"
                  >
                  </custom-select>
                </div>
              </div>
              <div class="generator-options-group__item">
                <div class="form__field">
                  <span class="form__label">Тема</span>
                  <custom-select
                    v-model="form.themeName"
                    :data-array="themesOptions"
                    placeholder="Выберите тематику имени"
                    always-show-placeholder
                    @input="addCurrentParam('theme', $event)"
                    @reset="removeCurrentParam('theme', 'themeName')"
                  >
                  </custom-select>
                </div>
              </div>
            </template>
          </div>
        </div>
        <ul class="generator-options-buttons">
          <li v-if="!isMobile || currentStep === 3">
            <a
              :href="`${baseLink}/popular/`"
              class="button button_small button_bordered"
              >Пропустить</a
            >
          </li>
          <li v-if="!isMobile || currentStep === 3" class="_submit">
            <a
              :href="`${baseLink}/info/?${urlParams}`"
              class="button button_small"
              @click="submit"
              >Подобрать имя!</a
            >
          </li>
          <li v-if="isMobile && currentStep === 2">
            <a
              href="#"
              class="button button_small"
              @click.prevent="changeStep(3)"
              >Далее</a
            >
          </li>
        </ul>
      </form>
    </div>
  </div>
</template>

<script>
import { helpers, minLength, maxLength } from 'vuelidate/lib/validators';

const fio = helpers.regex('fio', /^[A-za-zА-яа-я ,.'-]*$/);

export default {
  name: 'GeneratorOptions',
  props: {
    baseLink: {
      type: String,
      default: '',
    },
    initNames: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      form: {
        gender: '',
        surname: '',
        patronymic: '',
        originName: '',
        firstLetter: '',
        themeName: '',
      },
      userData: null,
      currentParams: {},
      urlParams: '',
      names: this.initNames,
      genders: [
        {
          gender: 'male',
          name: 'Мальчик',
          images: [
            {
              src: '/frontend/assets/img/name-generator/male-color.svg',
            },
            {
              src: '/frontend/assets/img/name-generator/male-unpainted.svg',
            },
          ],
        },
        {
          gender: 'female',
          name: 'Девочка',
          images: [
            {
              src: '/frontend/assets/img/name-generator/female-color.svg',
            },
            {
              src: '/frontend/assets/img/name-generator/female-unpainted.svg',
            },
          ],
        },
        {
          gender: 'unknown',
          name: 'Еще не знаю',
          images: [
            {
              src: '/frontend/assets/img/name-generator/unknown-color.svg',
            },
            {
              src: '/frontend/assets/img/name-generator/unknown-unpainted.svg',
            },
          ],
        },
      ],
      currentStep: 1,
    };
  },
  created() {
    this.userData = this.getUserData();
    this.form.surname = (this.userData && this.userData.surname) || '';
    this.form.patronymic = (this.userData && this.userData.patronymic) || '';
  },
  computed: {
    isMobile() {
      return this.$vssWidth < 768;
    },
    availableGenders() {
      const available = this.getUniq(this.names.map((item) => item.gender));
      return this.genders.filter(
        (item) => available.includes(item.gender) || item.gender === 'unknown'
      );
    },
    originsOptions() {
      const origins = this.names.flatMap((name) => name.origins);
      return this.getUniqByProp('value', origins);
    },
    firstLetterOptions() {
      const letters = this.names.map(({ name }) => {
        const firstLetter = name.charAt(0);
        return {
          text: firstLetter.toUpperCase(),
          value: firstLetter.toLowerCase(),
        };
      });

      return this.getUniqByProp('value', letters);
    },
    themesOptions() {
      const themes = this.names.flatMap((name) => name.themes);
      return this.getUniqByProp('value', themes);
    },
  },
  mounted() {
    if (!this.isMobile) {
      this.addCurrentParam('gender', this.availableGenders[0].gender);
    }
  },
  validations: {
    form: {
      surname: { minLength: minLength(2), maxLength: maxLength(30), fio },
      patronymic: { minLength: minLength(2), maxLength: maxLength(30), fio },
    },
  },
  methods: {
    getUniq(array) {
      return [...new Set(array)];
    },
    getUniqByProp(prop, array) {
      return Array.from(
        array
          .reduce(
            (acc, item) => (
              item && item[prop] && acc.set(item[prop], item), acc
            ),
            new Map()
          )
          .values()
      );
    },
    isAvailableGender(gender) {
      return this.availableGenders.some((item) => item.gender === gender);
    },
    changeStep(step) {
      this.currentStep = step;
    },
    addCurrentParam(category, value) {
      switch (category) {
        case 'gender':
          this.form.gender = value;
          if (this.isMobile) {
            this.changeStep(2);
          }
          break;
        case 'origin':
          this.form.originName = value;
          break;
        case 'theme':
          this.form.themeName = value;
          break;
        case 'letter':
          this.form.firstLetter = value;
          break;
      }
      this.currentParams[category] = value;
      this.updateUrlParams(this.currentParams);
    },
    removeCurrentParam(category, formEl) {
      this.form[formEl] = '';
      delete this.currentParams[category];
      this.updateUrlParams(this.currentParams);
    },
    updateUrlParams(params) {
      const newParams = Object.keys(params)
        .reduce(function (a, k) {
          a.push(k + '=' + encodeURIComponent(params[k]));
          return a;
        }, [])
        .join('&');

      this.urlParams = `${newParams}`;
    },
    isValid() {
      this.$v.$touch();
      return !this.$v.$invalid;
    },
    setStorage(obj) {
      localStorage.setItem('generatorUserData', JSON.stringify(obj));
    },
    getUserData() {
      return JSON.parse(localStorage.getItem('generatorUserData'));
    },
    submit(e) {
      if (this.isValid()) {
        this.userData = this.getUserData() || {};
        this.userData.surname = this.form.surname;
        this.userData.patronymic = this.form.patronymic;
        this.setStorage(this.userData);
      } else {
        e.preventDefault();
      }
    },
  },
};
</script>

<style lang="scss">
@import '../../scss/base/includes.scss';

$b: '.generator-options';

#{$b} {
  padding-bottom: 50px;
  margin-bottom: 50px;
  background-image: url('../../assets/img/name-generator/bg.svg');
  background-repeat: no-repeat;
  background-position: center;

  @include mobile {
    padding-bottom: 30px;
    margin-bottom: 30px;
  }

  &__head {
    &:not(:last-child) {
      margin-bottom: 60px;

      @include tablet {
        margin-bottom: 30px;
      }
    }
  }

  &-title {
    display: block;
    font-family: 'PH', sans-serif;
    font-size: 40px;
    line-height: 1;
    font-weight: 600;
    text-align: center;
    color: $accent;
    margin: 0;

    @include tablet {
      font-size: 28px;
    }

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  &-gender {
    display: flex;
    justify-content: space-between;
    list-style: none;
    padding: 0;
    max-width: 500px;
    margin: 0 auto;

    &:not(:last-child) {
      margin-bottom: 66px;
    }
  }

  &-gender-item {
    display: block;
    cursor: pointer;

    &_disabled {
      cursor: default;
      pointer-events: none;
    }

    &__img {
      height: 70px;
      display: flex;
      align-items: center;
      justify-content: center;

      &:not(:last-child) {
        margin-bottom: 16px;
      }

      & > img {
        flex-shrink: 0;
        max-width: none;
        max-height: 100%;
      }
    }
  }

  &-group {
    max-width: 732px;
    margin: 0 auto;

    &:not(:last-child) {
      margin-bottom: 32px;
    }

    &__list {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin: 0 0 -32px -24px;

      @include mobile {
        margin-bottom: -24px;
      }
    }

    &__item {
      width: 50%;
      padding-left: 24px;
      margin-bottom: 32px;

      @include mobile {
        width: 100%;
        margin-bottom: 24px;
      }
    }
  }

  &-buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @include mobile {
      flex-direction: column;
    }

    & > * {
      &:not(:last-child) {
        margin-right: 24px;

        @include mobile {
          margin-right: 0;
          margin-bottom: 16px;
        }
      }

      &._submit {
        @include mobile {
          order: -1;

          &:not(:first-child) {
            margin-bottom: 16px;
          }
        }
      }
    }

    .button {
      @include mobile {
        display: block;
        width: 100%;
      }
    }
  }

  &-step {
    display: block;
    text-align: center;
    font-size: 16px;
    line-height: 1.06;
    font-weight: 600;
    color: $dark;

    &:not(:last-child) {
      margin-bottom: 32px;
    }
  }

  &-step-title {
    display: block;
    font-family: 'PH', sans-serif;
    font-size: 22px;
    line-height: 1;
    font-weight: 600;
    text-align: center;
    color: $brand;
    margin: 0;

    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }

  &-button-link {
    display: block;
    font-size: 13px;
    line-height: 24px;
    font-weight: 600;
    color: $accent;
    text-align: center;
    @include clickable;
  }
}
</style>
