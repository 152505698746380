import { defineStore } from 'pinia'
import axios from "axios";

export const useEncyclopediaStore = defineStore('encyclopedia-main', {
  state: () => ({
    template: '',
    isLoading: false,
    tag: null,
  }),
  actions: {
    setTags(tag) {
      this.tag = tag;
    },
    async update(query) {
      history.replaceState(null, "", `/calendopedia/?${ query }#articleFilter`);

      document.getElementById('articleFilter').scrollIntoView();

      return this.loadTemplate(query);
    },
    async loadTemplate(query) {
      try {
        this.isLoading = true;

        const url = `/calendopedia/_ajax/?${ query }`;
        const res = await axios.get(url);

        this.template = res.data;
      }
      finally {
        this.isLoading = false;
      }
    }
  },
})
