<script>
import Vue from "vue";
import {useCatalogStore} from "../stores/catalog";

export default {
  name: 'CatalogMain',
  props: {

  },
  setup() {
    return {
      store: useCatalogStore()
    }
  },
  computed: {

  },
  created() {

  },
  methods: {
    wrapTemplate(template) {
      return `<catalog-inner>${template}</catalog-inner>`
    }
  },
  render(createElement) {
    return createElement(Vue.compile(
      this.store.template ?
      this.wrapTemplate(this.store.template) :
      this.wrapTemplate('<slot/>')), this.$slots.default)
  }
}
</script>

