<template>
  <div class="characters">
    <div class="characters__list">
      <div v-if="(items.length > 0)" class="characters__row">
        <div class="characters__col">
          <CharactersItemRandom
            tabindex="0"
            class="characters__item"
            @click.native="openRandomCharacterVersePopup"
            @keydown.native.enter="openRandomCharacterVersePopup"
          />
        </div>

        <div
          v-for="item in items"
          :key="item.id"
          class="characters__col"
        >
          <CharactersItem
            tabindex="0"
            class="characters__item"
            :character="item"
            @click.native="openCharacterVersePopup(item)"
            @keydown.native.enter="openCharacterVersePopup(item)"
          />
        </div>
      </div>

      <div v-else class="characters__empty">
        Список персонажей пуст
      </div>
    </div>

    <CharactersPopup
      :opened="popupOpened"
      :character="selectedCharacter"
      @close="closePopup"
    />
  </div>
</template>

<script>
import { getRandomInt } from "../utils/index";
import CharactersItem from "./CharactersItem.vue";
import CharactersItemRandom from "./CharactersItemRandom.vue";
import CharactersPopup from "./CharactersPopup.vue";

export default {
  name: "Characters",

  components: {
    CharactersItem,
    CharactersPopup,
    CharactersItemRandom
},

  props: {
    items: { type: Array, default: () => [] },
  },

  data() {
    return {
      popupOpened: false,
      selectedCharacter: null
    }
  },

  methods: {
    openCharacterVersePopup(character) {
      this.selectedCharacter = character
      this.popupOpened = true;

      const html = document.querySelector('html');
      const scroll = window.pageYOffset;

      html.classList.add('html_state_fixed');
      html.style.top = `${ -scroll }px`;
    },
    openRandomCharacterVersePopup() {
      const maxItemIndex = this.items.length - 1
      const getRandomItemIndex = getRandomInt(0, maxItemIndex)
      const randomCharacter = this.items[getRandomItemIndex]
      this.openCharacterVersePopup(randomCharacter)
    },
    closePopup() {
      this.popupOpened = false;

      const html = document.querySelector('html');
      const scroll = Math.abs(parseInt(html.style.top));

      html.classList.remove('html_state_fixed');
      html.style.top = 0;
      html.classList.add('html_default_scroll');
      window.scrollTo(0, scroll);
      html.classList.remove('html_default_scroll');
    }
  }
}
</script>

<style lang="scss">
@import "../scss/base/includes.scss";

.characters {
  margin-bottom: 60px;

  &__list {
    margin-top: 40px;
  }

  &__item {
    cursor: pointer;
    transition: transform 0.3s ease-in-out;

    &:hover {
      transform: translateY(-7px);
    }
  }

  &__row {
    display: flex;
    flex-wrap: wrap;
    margin: -18px -15px;
  }

  &__col {
    flex: 0 0 25%;
    max-width: 25%;
  }

  &__empty {
    width: 100%;
    font-weight: 600;
    font-size: 18px;
    text-align: center;
  }

  @include low-desktop {
    &__col {
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
    }
  }

  @include tablet {
    &__row {
      margin: -10px;
    }

    &__col {
      padding: 10px;
      flex: 0 0 25%;
      max-width: 25%;
    }
  }

  @include mobile {
    &__list {
      margin-top: 32px;
    }

    &__row {
      margin: -7px -10px;
    }

    &__col {
      flex: 0 0 50%;
      max-width: 50%;
      padding: 7px 10px;
    }
  }
}
</style>
