<template>
  <component
    :is="!isDesktop ? 'div' : 'swiper-slide'"
    :class="!isDesktop ? 'grid__item' : 'expert-slider-item'"
  >
    <slot />
  </component>
</template>

<script>
export default {
  name: "ExpertSliderItem",
  computed: {
    isDesktop: function () {
      return this.$vssWidth < 1280
    }
  },
};
</script>
