<template>
  <label class="copy-link" @click="copyURL">
    <svg preserveAspectRatio="none" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="6" y="1" width="12" height="12" rx="1" fill="#F2F7FA" stroke="#1D388F" stroke-width="2"/>
      <rect x="1" y="6" width="12" height="12" rx="1" fill="#F2F7FA" stroke="#1D388F" stroke-width="2"/>
    </svg>
    <input
      type="text"
      :value="linkUrl"
      @click="copyURL"
      ref="input"
    >
    <span class="copy-link__hint"  v-if="showNote">Ссылка скопирована</span>
  </label>
</template>

<script>
export default {
  data() {
    return {
      linkUrl: window.location.href,
      showNote: false
    };
  },
  mounted(){
  },
  methods: {
    copyURL() {
      const Url = this.$refs.input;
      Url.select();
      document.execCommand("copy");
      this.showNote = true;
      setTimeout(() => {this.showNote = false}, 1000);
    },
  },
};
</script>

