<template>
  <div class="encyclopedia-list-tags">
    <a
      v-for="(item, index) in tags"
      :key="`tags${ index }`"
      :href="`/calendopedia?themes=${ item.CODE }`"
      @click.prevent="() => toggleTag(item.CODE)"
      class="encyclopedia-list-tags__item"
    >
      {{ item.NAME }}
    </a>
  </div>
</template>

<script>

import { useEncyclopediaStore } from "../stores/encyclopedia";

export default {
  name: "EncyclopediaTags",
  setup() {
    return {
      encyclopedia: useEncyclopediaStore(),
    };
  },
  props: {
    tags: {
      type: Array,
      default: () => []
    },
  },
  methods: {
    toggleTag(tag) {
      const url = new URL(location);

      url.searchParams.delete('page');
      url.searchParams.set('themes', tag);

      this.encyclopedia.setTags(tag);
      this.encyclopedia.update(url.search.slice(1));
    },
  }
};
</script>
