<template>
  <div class="item-card__label">
    <a
      :href="`/agusha/news/?themes=${ tag }`"
      @click.prevent="() => toggleTag(tag)"
    >
      {{ name }}
    </a>
  </div>
</template>

<script>

import { useNewsStore } from '../stores/news';

export default {
  name: "NewsTags",
  setup() {
    return {
      store: useNewsStore(),
    };
  },
  props: {
    tag: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
  },
  methods: {
    toggleTag(tag) {
      window.scrollTo(0,0);

      const url = `/agusha/news/?themes=${tag}`;
      const ajaxUrl = `/agusha/news/_ajax/?themes=${tag}`;

      this.store.update(url, ajaxUrl);
    },
  }
};
</script>
