<template>
  <div class="benefits" v-if="dataArray.length > 0">
    <div class="benefits__holder">
      <template v-for="(item, index) in dataArray">
        <span v-html="item.value" :key="`row${index}`"></span>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "Benefits",
  props: {
    dataArray: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {
    resizeHandler() {},
  },
};
</script>
