<template>
  <div
    class="generator-filter"
    :class="{ 'generator-filter_is-closed': isTablet && !isOpen }"
  >
    <div class="generator-filter__head" @click.prevent="toggleFilter">
      <div class="generator-filter__title">Фильтр</div>
      <div v-if="isTablet" class="generator-filter__toggler">
        <template v-if="isOpen">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xml:space="preserve"
            viewBox="0 0 6.7 4.1"
          >
            <path d="M6 4.1 3.2 1.4.7 4.1 0 3.4 3.2 0l3.5 3.3z" />
          </svg>
          <span>Свернуть</span>
        </template>
        <template v-else>
          <span>Развернуть</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xml:space="preserve"
            viewBox="0 0 6.7 4.1"
          >
            <path d="M6 0 3.2 2.7.7 0 0 .7l3.2 3.4L6.7.7z" />
          </svg>
        </template>
      </div>
    </div>
    <div v-show="!isTablet || isOpen" class="generator-filter__body">
      <div class="form">
        <div class="form__row">
          <div class="form__field">
            <span class="form__label">Происхождение имени</span>
            <custom-select
              v-model="form.originName"
              :data-array="originsOptions"
              placeholder="Выберите происхождение имени"
              always-show-placeholder
              @input="addCurrentParam('origin', $event)"
              @reset="removeCurrentParam('origin', 'originName')"
            >
            </custom-select>
          </div>
        </div>
        <div class="form__row">
          <div class="form__field">
            <span class="form__label">Первая буква имени</span>
            <custom-select
              v-model="form.firstLetter"
              :data-array="firstLetterOptions"
              placeholder="Выберите первую букву имени"
              always-show-placeholder
              @input="addCurrentParam('letter', $event)"
              @reset="removeCurrentParam('letter', 'firstLetter')"
            >
            </custom-select>
          </div>
        </div>
        <div class="form__row">
          <div class="form__field">
            <span class="form__label">Тема</span>
            <custom-select
              v-model="form.themeName"
              :data-array="themesOptions"
              placeholder="Выберите тематику имени"
              always-show-placeholder
              @input="addCurrentParam('theme', $event)"
              @reset="removeCurrentParam('theme', 'themeName')"
            >
            </custom-select>
          </div>
        </div>
        <div class="form__row">
          <div class="form__field">
            <span class="form__label">Пол</span>
            <custom-select
              v-model="form.gender"
              :data-array="genderOptions"
              placeholder="Выберите тематику имени"
              always-show-placeholder
              @input="addCurrentParam('gender', $event)"
              @reset="removeCurrentParam('gender', 'gender')"
            >
            </custom-select>
          </div>
        </div>
      </div>
      <div v-if="!isEmpty(currentParams)" class="generator-filter__buttons">
        <span class="generator-filter__reset" @click.prevent="resetFilter">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xml:space="preserve"
            viewBox="0 0 12 12"
          >
            <path
              d="M6 12c-3.3 0-6-2.7-6-6s2.7-6 6-6 6 2.7 6 6-2.7 6-6 6zM6 2C3.8 2 2 3.8 2 6s1.8 4 4 4 4-1.8 4-4-1.8-4-4-4z"
            />
            <path d="m1.828 3.242 1.414-1.414 7 7-1.413 1.415z" />
          </svg>
          <span> Сбросить фильтр </span>
        </span>
        <span class="button button_small" @click.prevent="setFilter">
          Применить
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GeneratorFilter',
  props: {
    names: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isOpen: false,
      form: {
        originName: '',
        firstLetter: '',
        themeName: '',
        gender: '',
      },
      currentParams: {},
      genders: [
        {
          gender: 'male',
          name: 'Мальчик',
          images: [
            {
              src: '/frontend/assets/img/name-generator/male-color.svg',
            },
            {
              src: '/frontend/assets/img/name-generator/male-unpainted.svg',
            },
          ],
        },
        {
          gender: 'female',
          name: 'Девочка',
          images: [
            {
              src: '/frontend/assets/img/name-generator/female-color.svg',
            },
            {
              src: '/frontend/assets/img/name-generator/female-unpainted.svg',
            },
          ],
        },
        {
          gender: 'unknown',
          name: 'Еще не знаю',
          images: [
            {
              src: '/frontend/assets/img/name-generator/unknown-color.svg',
            },
            {
              src: '/frontend/assets/img/name-generator/unknown-unpainted.svg',
            },
          ],
        },
      ],
    };
  },
  computed: {
    isTablet() {
      return this.$vssWidth < 1024;
    },
    availableGenders() {
      const genders = this.names.map((item) => item.gender);
      return this.getUniq(genders);
    },
    genderOptions() {
      return this.genders
        .filter((item) => this.availableGenders.includes(item.gender))
        .map(({ name, gender }) => ({
          text: name,
          value: gender,
        }));
    },
    originsOptions() {
      const origins = this.names.flatMap((name) => name.origins);
      return this.getUniqByProp('value', origins);
    },
    firstLetterOptions() {
      const letters = this.names.map(({ name }) => {
        const firstLetter = name.charAt(0);
        return {
          text: firstLetter.toUpperCase(),
          value: firstLetter.toLowerCase(),
        };
      });

      return this.getUniqByProp('value', letters);
    },
    themesOptions() {
      const themes = this.names.flatMap((name) => name.themes);
      return this.getUniqByProp('value', themes);
    },
  },
  mounted() {
    this.getCurrentParamsUrl();
    this.updateFilter();
  },
  methods: {
    getUniq(array) {
      return [...new Set(array)];
    },
    getUniqByProp(prop, array) {
      return Array.from(
        array
          .reduce(
            (acc, item) => (
              item && item[prop] && acc.set(item[prop], item), acc
            ),
            new Map()
          )
          .values()
      );
    },
    getCurrentParamsUrl() {
      const allUrlParams = this.paramsToObject(this.getAllUrlParams());

      if (!allUrlParams) return;

      for (const param in allUrlParams) {
        switch (param) {
          case 'origin':
            this.currentParams[param] = allUrlParams[param];
            this.form.originName = allUrlParams[param];
            break;

          case 'letter':
            this.currentParams[param] = allUrlParams[param];
            this.form.firstLetter = allUrlParams[param];
            break;

          case 'theme':
            this.currentParams[param] = allUrlParams[param];
            this.form.themeName = allUrlParams[param];
            break;

          case 'gender':
            this.currentParams[param] = allUrlParams[param];
            this.form.gender = allUrlParams[param];
            break;
        }
      }
    },
    getAllUrlParams() {
      const url = location.search.substring(1);
      return new URLSearchParams(url);
    },
    addCurrentParam(category, value) {
      this.currentParams[category] = value;
    },
    removeCurrentParam(category, formEl) {
      this.form[formEl] = '';
      delete this.currentParams[category];
      this.removeUrlParam(category);
      this.updateFilter();
    },
    setUrlParams(currentParams) {
      if (this.isEmpty(currentParams)) return;

      const params = this.getAllUrlParams();
      const newParams = this.paramsToString(currentParams);

      const existParams = decodeURIComponent(params.toString());
      const newUrl = `${location.origin}${location.pathname}${location.hash}${
        existParams ? `?${existParams}&${newParams}` : `?${newParams}`
      }`;

      if (history.replaceState) {
        history.replaceState(null, null, newUrl);
      } else {
        // eslint-disable-next-line no-console
        console.warn('History API не поддерживается');
      }
    },
    removeUrlParams(deleteParams) {
      if (this.isEmpty(deleteParams)) return;

      for (const param in deleteParams) {
        this.removeUrlParam(param);
      }
    },
    removeUrlParam(param) {
      if (this.isEmpty(param)) return;

      const params = this.getAllUrlParams();

      params.delete(param);

      const existParams = decodeURIComponent(params.toString());
      const newUrl = `${location.origin}${location.pathname}${location.hash}${
        existParams ? `?${existParams}` : ''
      }`;

      if (history.replaceState) {
        history.replaceState(null, null, newUrl);
      } else {
        // eslint-disable-next-line no-console
        console.warn('History API не поддерживается');
      }
    },
    isEmpty(obj) {
      for (const key in obj) {
        if (obj.hasOwnProperty.call(obj, key)) return false;
      }

      return true;
    },
    paramsToObject(params) {
      const entries = params.entries();
      const result = {};
      for (const [key, value] of entries) {
        result[key] = value;
      }
      return result;
    },
    paramsToString(params) {
      const newParams = Object.keys(params)
        .reduce(function (a, k) {
          a.push(k + '=' + encodeURIComponent(params[k]));
          return a;
        }, [])
        .join('&');

      return `${newParams}`;
    },
    toggleFilter() {
      this.isOpen = !this.isOpen;
    },
    setFilter() {
      this.removeUrlParams(this.currentParams);
      this.setUrlParams(this.currentParams);
      this.$emit('setFilter', this.currentParams);
    },
    updateFilter() {
      this.$emit('updateFilter', this.currentParams);
    },
    resetFilter() {
      this.removeUrlParams(this.currentParams);
      this.currentParams = {};
      for (const prop in this.form) {
        this.form[prop] = '';
      }
      this.$emit('resetFilter');
    },
  },
};
</script>

<style lang="scss">
@import '../../scss/base/includes.scss';

$b: '.generator-filter';

#{$b} {
  display: block;
  background-color: $bg-brand;

  &__head {
    min-height: 56px;
    padding: 8px 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #e1eef7;
    border-radius: 8px 8px 0 0;

    @include tablet {
      justify-content: space-between;
    }

    @include mobile {
      padding: 8px 16px;
    }

    #{$b}_is-closed & {
      border-radius: 8px;
    }
  }

  &__title {
    display: block;
    font-family: 'PH', sans-serif;
    font-size: 32px;
    line-height: 1;
    font-weight: 600;
    color: $brand;
    margin: 0;

    @include mobile {
      font-size: 24px;
    }
  }

  &__toggler {
    @include clickable;
    display: flex;
    flex-direction: column;
    align-items: center;

    & > svg {
      width: 8px;
      height: 5px;

      path {
        fill: currentColor;
      }
    }

    & > span {
      font-size: 12px;
      line-height: 18px;
      font-weight: 600;
      color: $brand;
    }
  }

  &__body {
    padding: 32px 16px;
    border-radius: 0 0 8px 8px;

    @include tablet {
      padding: 32px;
    }

    @include mobile {
      padding: 24px 16px;
    }
  }

  .form {
    &:not(:last-child) {
      margin-bottom: 32px;

      @include mobile {
        margin-bottom: 24px;
      }
    }

    &__row {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__reset {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    color: $brand;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    transition: color 0.3s ease;

    &:hover,
    &:focus {
      color: $dark;
    }

    &:not(:last-child) {
      margin-right: 8px;
    }

    svg {
      width: 12px;
      height: 12px;

      &:not(:last-child) {
        margin-right: 8px;
      }

      path {
        fill: currentColor;
      }
    }
  }
}
</style>
