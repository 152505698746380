<template>
  <label :class="['custom-checkbox', { _error: v.$error, alternate }]">
    <input type="checkbox" :name="name" :checked="checked" @change="change" />
    <span class="custom-checkbox__box">
      <svg
        width="14"
        height="10"
        viewBox="0 0 14 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2 5.33333L4.5 7.83333C4.96017 8.2935 5.7065 8.2935 6.16667 7.83333L12 2"
          stroke="white"
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </span>
    <span class="custom-checkbox__label">{{ label }}</span>
  </label>
</template>

<script>
export default {
  name: "CustomCheckbox",
  props: {
    label: {
      type: String,
      default: "",
    },
    checked: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      value: "",
    },
    v: {
      type: Object,
      required: true,
    },
    alternate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    change(e) {
      this.v.$touch();
      this.$emit("input", e.target.checked);
    },
  },
};
</script>
