<template>
  <div class="shop-slider">
      <div class="shops-slider__bullets"></div>
      <swiper ref="Swiper" :options="swiperOptions">
        <swiper-slide v-for="(item, i) in dataArray" :key="i" class="shops-slider__item">
          <img :src="`/frontend/assets/img/shops/${item.image}`" :alt="item.name">
          <a :href="item.link" class="button button_small" target="_blank">Купить</a>
        </swiper-slide>
      </swiper>
  </div>
</template>

<script>
export default {
  name: "ShopsSlider",
  props: {
    dataArray: {
      type: Array,
      'default': []
    },
  },
  data() {
    return {
      swiperOptions: {
        slidesPerView: 2,
        watchOverflow: true,
        spaceBetween: 40,
        grid: {
          fill: 'row',
          rows: 2,
        },
        pagination: {
          el: '.shops-slider__bullets',
          type: "bullets",
          bulletClass: "shops-slider__bullet",
          bulletActiveClass: "shops-slider__bullet_active",
          clickable: true
        },
        breakpoints: {
          768: {
            slidesPerView: 4,
            spaceBetween: 50,
            grid: {
              rows: 1,
            },
          },
        }
      }
    };
  },
  computed: {
    isMobile: function () {
      return this.$vssWidth < 768
    }
  },
  mounted() {
  }
};
</script>

