<template>
  <div>
    <filter-chips
      :chips="chips"
    />
    <filter-options
      v-for="(item, index) in filters"
      :key="`wrap${index}`"
      :alias="item.alias"
      :title="item.title"
      :options="item.items"
      :is-opened="item.expanded"
    />
  </div>
</template>

<script>
import FilterOptions from "./FilterOptions.vue";
import FilterChips from "./FilterChips.vue";

export default {
  name: "FilterOptionsWrap",
  components: { FilterOptions, FilterChips },
  props: {
    tags: {
      type: Array,
      default: () => []
    },
    filters: {
      type: Array,
      default: () => []
    },
  },
  computed: {
    chips: function () {
      const chips = [];

      this.filters.forEach(i => {
        i.items.forEach(j => {
          chips.push({
            title: j.full_title || j.title,
            alias: Object.values(j.params)[0],
            subitems: j.subitems?.map(f => Object.values(f.params)).flat() || [],
            parents: null,
            siblings: [],
          });

          j.subitems?.forEach(i => {
            chips.push({
              title: i.title,
              alias: Object.values(i.params)[0],
              subitems: [],
              parents: j.code,
              siblings: j.subitems,
            });
          });
        });
      });

      this.tags.forEach(i => {
        chips.push({
          title: i.title,
          alias: i.alias,
          subitems: [],
        });
      });

      return chips;
    },
  },
};
</script>
