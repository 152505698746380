import { defineStore } from 'pinia';
import axios from "axios";

export const useRecipesStore = defineStore('recipes-template', {
  state: () => ({
    template: '',
    isLoading: false,
    section: null,
    ajaxLink: '',
    url: new URL(window.location),
  }),
  getters: {
  },
  actions: {
    setSearchParams(key, value) {
      this.url.searchParams.set(key, value);
    },
    deleteSearchParams(key) {
      this.url.searchParams.delete(key);
    },
    setAjaxLink(link) {
      this.ajaxLink = link;
    },
    setSection(section) {
      this.section = section;
    },
    async update() {
      const ajaxUrl = `${this.ajaxLink}${this.url.search}`;

      history.replaceState(null, "", '/recipes/' + this.url.search);

      return this.loadTemplate(ajaxUrl);
    },
    async loadTemplate(url) {
      try {
        this.isLoading = true;

        const res = await axios.get(url);

        this.template = res.data;
      }
      finally {
        this.isLoading = false;
      }
    },
  },
})
