import { defineStore } from 'pinia'

export const useNewbornStore = defineStore('newborn', {
  state: () => ({
    currentType: 'tutorial',
  }),
  getters: {
  },
  actions: {
  },
})
