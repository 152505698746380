<template>
  <div class="faq-item">
    <h3 v-if="item.subitems" :id="item.id" class="faq__subtitle">
      {{ item.title }}
    </h3>
    <div
      v-if="!item.subitems"
      class="faq-item__title-container"
      :class="{
        'faq-item__title-container_active': selected,
      }"
      role="button"
      tabindex="0"
      @click="emitToggle"
      @keydown.enter="emitToggle"
    >
      <p class="faq-item__title">
        {{ item.title }}
      </p>

      <div class="faq-item__expand-indicator-wrapper">
        <div class="faq-item__expand-indicator">
          <svg
            width="100%"
            height="100%"
            viewBox="0 0 26 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              v-if="!selected"
              d="M13 0V26"
              stroke="currentColor"
              stroke-width="2"
            />
            <path
              d="M26 13L-9.29227e-07 13"
              stroke="currentColor"
              stroke-width="2"
            />
          </svg>
        </div>
      </div>
    </div>

    <template v-if="selected || item.subitems">
      <div
        v-if="!item.subitems"
        v-html="item.content"
        class="faq-item__content typography"
      ></div>

      <ul v-else class="faq-item__subitems">
        <li v-for="subitem of item.subitems" :key="subitem.id">
          <FaqItem
            class="faq-item_subitem"
            :item="subitem"
            :selected="isActive(subitem)"
            @toggle="toggleQuestion(subitem)"
          />
        </li>
      </ul>
    </template>
  </div>
</template>

<script>
export default {
  name: "FaqItem",

  props: {
    item: { type: Object, required: true },
    selected: { type: Boolean, default: false },
  },

  data() {
    return {
      selectedSubItem: null,
    };
  },

  computed: {
    isMobile() {
      return this.$vssWidth < 768;
    },
  },

  methods: {
    emitToggle(e) {
      const item = e.target.closest(".faq-item");

      this.$emit("toggle");
      this.selectedSubItem = null;

      setTimeout(() => {
          item.scrollIntoView(top)
        }, 0);
    },

    toggleQuestion(question) {
      const sameClicked =
        this.selectedSubItem && this.selectedSubItem.id === question.id;
      this.selectedSubItem = sameClicked ? null : question;
    },

    isActive(item) {
      return !!this.selectedSubItem && this.selectedSubItem.id === item.id;
    },
  },
};
</script>
