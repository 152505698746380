<script>
import Vue from "vue";
import {useArticlesStore} from "../stores/articles-main";

export default {
  name: 'ArticlesMain',
  setup() {
    return {
      store: useArticlesStore()
    }
  },
  methods: {
    wrapTemplate(template) {
      return `<articles-inner>${template}</articles-inner>`
    }
  },
  render(createElement) {
    return createElement(Vue.compile(
      this.store.template ?
      this.wrapTemplate(this.store.template) :
      this.wrapTemplate('<slot/>')), this.$slots.default)
  }
}
</script>
