<template>
  <div class="subscription">
    <p class="subscription__note">Мы подберем материалы, которые больше всего подходят вам и вашему малышу. А также будем присылать вам новости об акциях и скидках на продукты «Агуша»</p>
    <form class="subscription__form form" action="" @submit.prevent="submit" ref="form">
      <div class="form__field subscription__form-field">
        <span class="form__label g-hidden-tablet">Почта*</span>
        <input type="text" name="email" :class="{ '_error': $v.form.email.$error }" v-model="form.email" placeholder="E-mail">
        <span class="form__field-error" v-if="$v.form.email.$error">Введите корректный email</span>
      </div>
      <div class="form__field subscription__form-field">
        <span class="form__label g-hidden-tablet">Дети*</span>
        <custom-select
          :v="$v.form.type"
          v-model="form.type"
          :data-array="dataArray"
          placeholder="Дети"
          @reset="resetSelect"
        >
        </custom-select>
        <span class="form__field-error" v-if="$v.form.type.$error">Поле не должно быть пустым</span>
      </div>
      <div class="form__field subscription__form-field">
        <p>Нажимая на кнопку, вы принимаете<br/>
          <a href="/Agulife_Пользовательское_соглашение.pdf" target="_blank">Пользовательское соглашение</a>
        </p>
        <input class="button button_small button_light" type="submit" value="Подписаться">
      </div>
    </form>
  </div>
</template>

<script>
//import axios from "axios";
import {email, required} from "vuelidate/lib/validators";

export default {
  name: 'Subscription',
  props: {

  },
  data() {
    return {
      dataArray: [
        {text: 'Беременна', value: 'pregnant'},
        {text: 'Новорожденный', value: 'newborn'},
        {text: 'Малыш до 6 мес.', value: '6months'},
        {text: 'Малыш от 6 до 12 мес.', value: '6-12months'},
        {text: 'Малыш старше 1 года', value: 'year'},
        {text: 'Ничего из перечисленного', value: 'other'}
      ],
      form: {
        email: '',
        type: '',
      }
    }
  },
  validations: {
    form: {
      email: {required, email},
      type: {required},
    }
  },
  computed: {

  },
  methods: {
    resetSelect() {
      this.form.type = '';
    },
    async submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        //alert('Validation error!');
        console.log(this.$v.form);
      } else {
        alert('success!');
        this.$refs.form.submit();
        /*try {
          const response = await axios({
            url: '/local/ajax/form.php',
            method: 'POST',
            data: this.getFormData(),
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });
          if (response.data.status === 'error') {
            throw new Error(response.data.errors);
          }
          this.resetForm();
        } catch (e) {
          console.log('Error!', e);
          alert('Ошибка!');
        }*/
      }
    },

    getFormData() {
      const form = new FormData();

      form.append('type', this.form.type);
      form.append('email', this.form.email);

      return form;
    },
  }
}
</script>
