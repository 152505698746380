import { defineStore } from 'pinia'
import axios from "axios";

export const useNewsStore = defineStore('news', {
  state: () => ({
    template: '',
    isLoading: false,
    ajaxLink: '',
  }),
  actions: {
    async update(url, ajaxUrl) {
      history.replaceState(null, "", url);

      return this.loadTemplate(ajaxUrl);
    },
    setAjaxLink(link) {
      this.ajaxLink = link;
    },
    async loadTemplate(url) {
      try {
        this.isLoading = true;

        const res = await axios.get(url);

        this.template = res.data;
      }
      finally {
        this.isLoading = false;
      }
    }
  },
})
