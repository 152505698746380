<template>
  <div class="products-main">
    <div class="products-main__top">
      <div class="products-main__tabs">
        <div
          v-for="(item, index) in tabs"
          :key="`tab${index}`"
          :class="['products-main__tab', {'products-main__tab_active': activeTab === index}]"
          @click="activeTab = index"
        >
          {{ item }}
        </div>
      </div>
<!--      <a v-if="catalogLink" :href="catalogLink" class="products-main__catalog-link g-hidden-tablet">-->
<!--        Вся продукция-->
<!--        <svg width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--          <path d="M1 9L5 5L1 1" stroke="#0275D8" stroke-width="2" stroke-linecap="round"/>-->
<!--        </svg>-->
<!--      </a>-->
    </div>
    <slider id="products_main" class="products-main__slider" :items="items" :key="`slider-contnent${activeTab}`">
      <slot :name="`tab-content${activeTab+1}`"/>
    </slider>
  </div>
</template>

<script>
import Slider from "./Slider";

export default {
  name: "ProductsMain",

  components: {
    Slider,
  },

  props: {
    tabs: {
      type: Array,
      default: () => [],
    },
    catalogLink: {
      type: String,
      default: undefined
    },
    items: {
      type: Number,
      default: 4
    }
  },

  data() {
    return {
      activeTab: 0
    };
  },

  computed: {
    isTablet() {
      return this.$vssWidth < 1024;
    },
  },

  methods: {

  },
};
</script>

