import { defineStore } from 'pinia'
import axios from "axios";

export const useArticlesStore = defineStore('articles-main', {
  state: () => ({
    template: '',
    isLoading: false,
  }),
  actions: {
    async update(url) {
      return this.loadTemplate(url);
    },
    async loadTemplate(url) {
      try {
        this.isLoading = true;

        const res = await axios.get(url);

        this.template = res.data;
      }
      finally {
        this.isLoading = false;
      }
    }
  },
})
