<script>
import {useCatalogStore} from "../stores/catalog";

export default {
  name: 'CatalogMain',
  props: {
    ajaxLink: {
      type: String,
      required: true
    }
  },
  setup() {
    return {
      store: useCatalogStore()
    }
  },
  created() {
    this.store.setAjaxLink(this.ajaxLink)
  },
  render() {
    return null;
  }
}
</script>

