<template>
  <div :class="['holder', 'retailers-widget', { 'retailers-widget_state_hide': isHide }]">
    <div class="product-card__retailers" id="shops">
      <div class="shops">
        <img srcset="/frontend/assets/img/product/flower.webp, /frontend/assets/img/product/flower@2x.webp 2x"
          src="/frontend/assets/img/product/flower.webp" alt="leaf" class="shops__flower">
        <svg preserveAspectRatio="none" class="shops__back" viewBox="0 0 1472 506" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <g filter="url(#filter0_di_2179_8940)">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M19.0073 258.347C33.2483 421.477 142.376 499.899 319.277 478.557C387.614 470.313 461.282 450.574 535.427 434.254C615.833 416.555 687.389 453.106 762.735 462.456C840.756 472.138 1035.02 432.147 1193.14 472.332C1370.39 517.379 1436.18 397.877 1453.18 260.179C1466.15 155.186 1434.91 41.6428 1347.52 14.9498C1280.09 -5.64404 1134.38 50.0685 981 58.3083C859.087 64.8571 762.749 16.0413 635.763 21.0521C547.477 24.536 444.445 71.6155 361.927 48.5C291.776 28.8494 229.552 8.57126 174.572 8.0145C21.5665 6.46381 7.76399 129.553 19.0073 258.347Z"
              fill="#F9A624" />
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M19.0073 258.347C33.2483 421.477 142.376 499.899 319.277 478.557C387.614 470.313 461.282 450.574 535.427 434.254C615.833 416.555 687.389 453.106 762.735 462.456C840.756 472.138 1035.02 432.147 1193.14 472.332C1370.39 517.379 1436.18 397.877 1453.18 260.179C1466.15 155.186 1434.91 41.6428 1347.52 14.9498C1280.09 -5.64404 1134.38 50.0685 981 58.3083C859.087 64.8571 762.749 16.0413 635.763 21.0521C547.477 24.536 444.445 71.6155 361.927 48.5C291.776 28.8494 229.552 8.57126 174.572 8.0145C21.5665 6.46381 7.76399 129.553 19.0073 258.347Z"
              fill="#217BC4" />
          </g>
          <defs>
            <filter id="filter0_di_2179_8940" x="0" y="0" width="1472" height="506" filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha" />
              <feOffset dy="8" />
              <feGaussianBlur stdDeviation="8" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.364706 0 0 0 0 0.666667 0 0 0 0.16 0" />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2179_8940" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2179_8940" result="shape" />
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha" />
              <feOffset />
              <feGaussianBlur stdDeviation="80" />
              <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
              <feColorMatrix type="matrix" values="0 0 0 0 0.113725 0 0 0 0 0.219608 0 0 0 0 0.560784 0 0 0 1 0" />
              <feBlend mode="normal" in2="shape" result="effect2_innerShadow_2179_8940" />
            </filter>
          </defs>
        </svg>
        <svg preserveAspectRatio="none" class="shops__back shops__back--mobile" viewBox="0 0 375 296" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <g filter="url(#filter0_di_2179_9618)">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M15 239.25C15 194.193 20.9454 188.017 20.9454 151.992C20.9454 115.967 15 95.8262 15 46.7504C15 12.6653 35 7.99994 54 7.99997C89 7.99994 112.32 12.6653 161.5 12.6653C210.68 12.6653 253.26 7.99978 315.5 7.99996C346 7.99992 360 16.2504 360 46.7504C360 75.3848 353.242 75.588 353.242 111.556C353.242 147.524 360 183.345 360 233.75C360 266.463 346.5 271.75 327 271.75C269.477 271.75 263.428 266.463 214.212 266.463C164.996 266.463 121.685 271.75 51.5 271.75C30 271.75 15 261.75 15 239.25Z"
              fill="#217BC4" />
          </g>
          <defs>
            <filter id="filter0_di_2179_9618" x="-1" y="-6.10352e-05" width="377" height="295.75"
              filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha" />
              <feOffset dy="8" />
              <feGaussianBlur stdDeviation="8" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.364706 0 0 0 0 0.666667 0 0 0 0.16 0" />
              <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2179_9618" />
              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2179_9618" result="shape" />
              <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha" />
              <feOffset />
              <feGaussianBlur stdDeviation="80" />
              <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
              <feColorMatrix type="matrix" values="0 0 0 0 0.113725 0 0 0 0 0.219608 0 0 0 0 0.560784 0 0 0 1 0" />
              <feBlend mode="normal" in2="shape" result="effect2_innerShadow_2179_9618" />
            </filter>
          </defs>
        </svg>
        <h2 class="shops__title">
          {{ title }}
        </h2>
        <div class="shops__text">Покупайте продукты «Агуша» в интернет-магазине с доставкой</div>
        <pepsico-retailers
          url="https://marketplace.pepsico.digital"
          :productid="productId"
          sort-retailers="7,5,3,0,12,14,8"
          vce-ready=""
          sliderconfig='{" slidesPerView" : 5, "slidesPerColumn" : 1, "breakpoints" : {"767": {"slidesPerView": 2, "slidesPerColumn" : 1}}}'
        >
        </pepsico-retailers>
      </div>
    </div>
    <div class="product-card__buy-button">
      <a href="#shops" class="btn">
        {{ buttonLabel }}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "Retailers",
  props: {
    title: {
      type: String,
      default: '',
    },
    productId: {
      type: String,
      default: '',
    },
    buttonLabel: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isHide: true,
    };
  },
  created() {
    const _this = this;

    (function loop() {
      if (!document.querySelector('.retailer-slider')) {
        setTimeout(loop, 100);
      } else {
        if(document.querySelector('.retailer-slider__slider-container')) {
          _this.isHide = false;
        }
      }
    }());
  },
};
</script>

<style lang="scss">

.retailers-widget
{
  &_state
  {
    &_hide
    {
      height: 0;

      pointer-events: none;

      opacity: 0;

      & .product-card__buy-button
      {
        display: none;
      }
    }
  }
}

</style>
