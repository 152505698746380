<template>
  <form
    action=""
    class="form feedback__form"
    @submit.prevent="submit"
    ref="form"
  >
    <div class="form__row form__row_flex">
      <div class="form__field">
        <span class="form__label">Email*</span>
        <input
          type="text"
          name="email"
          placeholder="Введите email"
          :class="{ _error: $v.form.email.$error }"
          v-model="form.email"
        />
      </div>
      <div class="form__field">
        <span class="form__label">Тема обращения*</span>
        <custom-select
          :v="$v.form.type"
          v-model="form.type"
          :data-array="themes"
          placeholder="Тема обращения*"
        ></custom-select>
      </div>
    </div>

    <template v-if="+form.type !== THEME_COOP_PROPOSAL">
      <div class="form__row">
        <div class="form__field custom-text-area">
          <span class="form__label">Текст сообщения*</span>
          <textarea
            name="text"
            :class="{ _error: $v.form.text.$error }"
            v-model="form.text"
          ></textarea>
        </div>
      </div>
      <div class="form__row">
        <div class="form__field">
          <span class="form__label">Прикрепить файл</span>
          <p class="form__note">
            Вы можете загрузить изображения в формате png или jpg. Максимально
            допустимый размер файла - 2mb.
          </p>
          <file-input @get-files="getFiles($event)"></file-input>
        </div>
      </div>
      <div class="form__row form__row_flex" v-if="captchaCode.length">
        <div class="form__captcha">
          <img
            :src="'/bitrix/tools/captcha.php?captcha_sid=' + captchaCode"
            alt="CAPTCHA"
          />
          <input
            type="text"
            name="captcha"
            placeholder="Введите символы с картинки"
            :class="{ _error: $v.form.captcha.$error }"
            v-model="form.captcha"
          />
        </div>
      </div>
      <custom-checkbox-personal
        name="agree"
        v-model="form.agree"
        :v="$v.form.agree"
      ></custom-checkbox-personal>
      <div class="form__row">
        <div class="form__submit">
          <input type="submit" class="btn" value="Отправить" />
        </div>
      </div>
      <!-- v-if="showSuccessPopup" -->
      <div class="popup popup_small popup_msg" v-if="showSuccessPopup">
        <div class="popup__box">
          <svg
            class="popup__back"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 501 306"
            fill="none"
            preserveAspectRatio="none"
          >
            <path
              d="M7.11104 269.823C-4.01722 238.181 7.11114 212.141 7.11099 167.421C7.11084 123.332 -16.1912 50.3566 21.5693 19.4008C73.1097 -22.8516 178.033 17.0772 253.72 17.0772C343.923 17.0772 438.479 -27.1516 487.518 28.5634C502.867 46.003 505.631 83.2056 493.114 125.749C478.94 173.925 511.641 235.737 493.115 269.823C458.607 333.313 379.94 289.418 284.602 289.354C187.139 289.289 30.9624 337.644 7.11104 269.823Z"
              fill="white"
            />
            <path
              d="M7.11104 269.823C-4.01722 238.181 7.11114 212.141 7.11099 167.421C7.11084 123.332 -16.1912 50.3566 21.5693 19.4008C73.1097 -22.8516 178.033 17.0772 253.72 17.0772C343.923 17.0772 438.479 -27.1516 487.518 28.5634C502.867 46.003 505.631 83.2056 493.114 125.749C478.94 173.925 511.641 235.737 493.115 269.823C458.607 333.313 379.94 289.418 284.602 289.354C187.139 289.289 30.9624 337.644 7.11104 269.823Z"
              fill="url(#paint0_radial_6334_724)"
            />
            <defs>
              <radialGradient
                id="paint0_radial_6334_724"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(243.871 150.937) scale(376.041 207.27)"
              >
                <stop stop-color="white" />
                <stop offset="0.23" stop-color="white" />
                <stop offset="0.48" stop-color="white" />
                <stop offset="0.727768" stop-color="#E6F7FF" />
                <stop offset="0.9999" stop-color="#BCEAFF" />
                <stop offset="1" stop-color="#BCEAFF" />
              </radialGradient>
            </defs>
          </svg>
          <span class="popup__close" @click="showSuccessPopup = false">
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M1 13L13 1.00036" stroke="#0275D8" stroke-width="2" />
              <path d="M1 1L13 12.9996" stroke="#0275D8" stroke-width="2" />
            </svg>
          </span>
          <h2 class="popup__title">Спасибо!</h2>
          <p>Сообщение успешно отправлено!</p>
          <a
            href=""
            class="btn"
            @click.prevent="showSuccessPopup = false"
            >Закрыть</a
          >
        </div>
      </div>
    </template>

    <template v-else>
      <p>
        Для связи по вопросам сотрудничества:

        <br />

        на территории России – заполните форму по
        <a
          href="https://client.pepsico.ru/servicedesk/customer/user/login?destination=portals"
          target="_blank"
          rel="noopener noreferrer"
          >ссылке</a
        >

        <br />

        на территории других стран – отправьте запрос на почту
        <a href="mailto:ExportPCH@pepsico.com">ExportPCH@pepsico.com</a>
      </p>

      <br />

      <p>
        Если у вас возникли технические сложности с заполнением формы или
        отправкой письма, пожалуйста, свяжитесь с контактным центром по телефону
        <a href="tel:88007001455">8-800-700-14-55</a>
      </p>
    </template>
  </form>
</template>

<script>
import axios from "axios";
import {
  email,
  required,
  sameAs,
} from "vuelidate/lib/validators";

import FileInput from "../../s1/frontend/components/FileInput";

const THEME_COOP_PROPOSAL = 17;

export default {
  name: "FeedbackForm",
  components: { FileInput },
  props: {
    themes: { type: Array, default: () => [] },
  },
  data() {
    return {
      showSuccessPopup: false,
      form: {
        email: "",
        type: "",
        text: "",
        captcha: "",
        files: [],
        agree: false,
      },
      captchaCode: "",
    };
  },
  validations: {
    form: {
      email: { required, email },
      type: { required },
      text: { required },
      captcha: { required },
      agree: { sameAs: sameAs(() => true) },
    },
  },

  created() {
    this.THEME_COOP_PROPOSAL = THEME_COOP_PROPOSAL;
  },

  mounted() {
    this.getCaptcha();
  },
  methods: {
    async submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      const res = await this.send("contact", this.getFormData());

      if (res !== false) {
        this.showSuccessPopup = true;
        this.resetForm();
      } else {
        alert("Ошибка!");
      }
    },

    getFiles(event) {
      this.files = event
    },

    getFormData() {
      const form = new FormData();

      form.append("email", this.form.email);
      form.append("theme", this.form.type);
      form.append("text", this.form.text);
      form.append("captchaSid", this.captchaCode);
      form.append("captchaCode", this.form.captcha);

      for (let key in this.files) {
        form.append("files[]", this.files[key]);
      }

      return form;
    },
    resetForm() {
      this.form = {
        email: "",
        text: "",
        type: "",
      };

      this.getCaptcha();
    },
    async getCaptcha() {
      const res = await this.send("getCaptchaCode");

      this.captchaCode = res.captchaCode;
    },
    async send(action, data) {
      try {
        const response = await axios({
          url: "/api/" + action + "/",
          method: "POST",
          data: data,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        if (response.data.status === "error") {
          throw new Error(response.data.errors);
        }

        return response.data.data;
      } catch (e) {
        console.log("Error!", e);
        return false;
      }
    },
  },
};
</script>
