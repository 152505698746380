import { defineStore } from 'pinia';
import axios from "axios";

export const useCatalogStore = defineStore('catalog-template', {
  state: () => ({
    template: '',
    isLoading: false,
    ajaxLink: '',
    url: new URL(window.location),
  }),
  getters: {
  },
  actions: {
    setSearchParams(key, value) {
      this.url.searchParams.set(key, value);
    },
    deleteSearchParams(key) {
      this.url.searchParams.delete(key);
    },
    setHistory() {
      history.replaceState(null, "", '/products/' + this.url.search);

      this.update();
    },
    setAjaxLink(link) {
      this.ajaxLink = link;
    },
    setTemplate(newTemplate) {
      this.template = newTemplate;
      setTimeout(() => {
        const breadcrumbsElem = document.querySelector('.breadcrumbs');
        breadcrumbsElem.innerHTML = window.breadcrumbs.map((item, i) => {
          let content = item.title;
          if (i !== window.breadcrumbs.length - 1) {
            content = `<a href="${item.url}">${item.title}</a>`
          }
          return `<div class="breadcrumbs__item">${content}</div>`
        }).join('')
      })
    },
    async update() {
      const ajaxUrl = '/products/_ajax/' + this.url.search;

      return this.loadTemplate(ajaxUrl);
    },
    async loadTemplate(url) {
      try {
        this.isLoading = true;
        const res = await axios.get(url);

        this.setTemplate(res.data);
      }
      finally {
        this.isLoading = false;
      }
    },
  },
})
