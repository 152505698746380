<template>
  <div class="side-menu">
    <div v-for="(item, i) in dataArray" :key="`item${i}`" class="side-menu__item">
      <div class="side-menu__item-wrap">
        <template v-if="item.link !== undefined">
          <a :href="item.link">{{ item.title }}</a>
        </template>
        <template v-else>
          <a href="" @click.prevent="toggleSubitems(i)">{{ item.title }}</a>
        </template>
        <span>{{ item.counter }}</span>
      </div>
      <template v-if="item.subitems">
        <transition name="slide">
          <div v-if="isVisible(i)" class="side-menu__subitems">
            <div v-for="(subitem, k) in item.subitems" :key="`subitem${k}`" :class="['side-menu__subitems-item', {'side-menu__subitems-item_active': subitem.active}]">
              <a :href="subitem.link">{{ subitem.title }}</a>
            </div>
          </div>
        </transition>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "SideMenu",
  props: {
    dataArray: {
      type: Array,
      'default': []
    },
  },
  data() {
    return {
      openedSubitems: [],
    };
  },
  computed: {
    isMobile: function () {
      return this.$vssWidth < 768
    },
  },
  watch: {
  },
  mounted() {
    this.dataArray.map((obj, i) => {
      let _index = i;
      obj.subitems.map(subobj => {
        if (subobj.active) {
          this.openedSubitems.push(_index)
          return;
        }
      })
    })
  },
  methods: {
    toggleSubitems(index) {
      if (this.openedSubitems.includes(index)) {
        this.removeItem(this.openedSubitems, index)
      } else {
        this.openedSubitems.push(index);
      }
    },
    removeItem(array, item) {
      const index = array.indexOf(item);
      if (index > -1) {
        array.splice(index, 1);
      }
    },
    isVisible(i) {
      return this.openedSubitems.includes(i);
    }
  }
};
</script>

