<template>
  <div class="characters-slider__controls" :class="{'g-hidden': slidesCount < 2}">
    <div class="characters-slider__prev">
      <svg width="21" height="34" viewBox="0 0 21 34" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19.0813 32L4.00701 19.1857C3.6914 18.9175 3.43788 18.5839 3.26401 18.2079C3.09014 17.832 3.00009 17.4228 3.00009 17.0086C3.00009 16.5944 3.09014 16.1851 3.26401 15.8092C3.43788 15.4333 3.6914 15.0996 4.00701 14.8314L19.0813 2" stroke="#2BB8ED" stroke-width="5" stroke-linejoin="round"/>
      </svg>
    </div>
    <div class="characters-slider__next">
      <svg width="21" height="34" viewBox="0 0 21 34" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2 32L17.0743 19.1857C17.3899 18.9175 17.6434 18.5839 17.8173 18.2079C17.9912 17.832 18.0812 17.4228 18.0812 17.0086C18.0812 16.5944 17.9912 16.1851 17.8173 15.8092C17.6434 15.4333 17.3899 15.0996 17.0743 14.8314L2 2" stroke="#2BB8ED" stroke-width="5" stroke-linejoin="round"/>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: "CharactersSliderControls",

  props: {
    slidesCount: {
      type: Number,
      required: true
    }
  }
}
</script>

<style lang="scss">
@import "../scss/base/includes.scss";

.characters-slider {
  &__controls {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    width: 100%;
    max-width: 1360px;
    z-index: 10;
  }

  &__prev, 
  &__next {
    position: absolute;
    top: 0;
    z-index: 1;
    margin: 0 -25px;
    width: 80px;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    svg {
      transition: transform 0.3s ease;
    }
  }

  &__prev {
    left: 85px;

    &:hover {
      svg {
        transform: translateX(-5px);
      }
    }
  }

  &__next {
    right: 85px;

    &:hover {
      svg {
        transform: translateX(5px);
      }
    }
  }

  @include low-desktop {
    &__prev {
      left: 35px;
    }

    &__next {
      right: 35px;
    }
  }

  @include tablet {
    &__prev {
      left: 25px;
    }

    &__next {
      right: 25px;
    }
  }

  @include mobile {
    &__controls {
      display: none;
    }
  }
}
</style>
