<template>
  <div class="filter__row" v-if="!isMobile">
    <slot name="trimester"/>
    <slot name="month"/>
    <slot name="age"/>
  </div>
  <div class="filter__slider" v-else>
    <swiper :options="swiperOptions">
      <swiper-slide>
        <slot name="trimester"/>
      </swiper-slide>
      <swiper-slide>
        <slot name="month"/>
      </swiper-slide>
      <swiper-slide>
        <slot name="age"/>
      </swiper-slide>
    </swiper>
    <div class="filter__arrows">
      <div class="filter__arrow-prev">
        <svg width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7 1L1 6.5L7 12" stroke="#1D388F" stroke-linecap="round"/>
        </svg>
      </div>
      <div class="filter__arrow-next">
        <svg width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 1L7 6.5L1 12" stroke="#1D388F" stroke-linecap="round"/>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AgeFilter',
  props: {
    currentSlide: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      swiperOptions: {
        slidesPerView: 1,
        //loop: true,
        spaceBetween: 20,
        watchOverflow: true,
        autoHeight: true,
        initialSlide: this.currentSlide,
        navigation: {
          prevEl: '.filter__arrow-prev',
          nextEl: '.filter__arrow-next',
          disabledClass: "is-disabled"
        }
      }
    }
  },
  computed: {
    isMobile: function () {
      return this.$vssWidth < 1024
    }
  },
  methods: {
  }
}
</script>

