<template>
  <div class="tabbed-content__tab-content experts__list">
    <a
      v-for="(item, index) in dataArray"
      :key="`item${index}`"
      class="experts__item"
      :href="item.link"
    >
      <svg
        preserveAspectRatio="none"
        viewBox="0 0 424 210"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="experts__item-back"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M26.6099 0C103.234 0 183.67 3.96379 260.294 3.96379C307.264 3.96379 350.421 0 397.39 0C412.026 0 424 9.68187 424 21.5154C424 56.8117 421.072 92.1081 421.072 127.405C421.072 147.765 424 168.125 424 188.485C424 200.318 412.026 210 397.39 210C310.317 210 225.107 205.859 138.034 205.859C101.513 205.859 63.1305 210 26.6099 210C11.9745 210 0.00012207 200.318 0.00012207 188.485C0.00012207 148.832 2.70833 110.054 2.70833 70.4008C2.70833 54.3975 0.00012207 37.5186 0.00012207 21.5154C0.00012207 9.68187 11.9745 0 26.6099 0Z"
          fill="#F2FAFF"
        />
      </svg>

      <div>
        <h3 class="experts__item-name">
          {{ item.name }}
        </h3>
        <p class="experts__item-special">
          {{ item.special_card }}
        </p>
      </div>
      <p
        :class="`experts__item-article-count btn experts__item-color--${item.colorId}`"
      >
        {{ item.articleCount }} {{ item.articlePlural }}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M2.00012 8L14.0001 8"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M9.33346 12.6693L14.0001 8.0026L9.33346 3.33594"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </p>
    </a>
  </div>
</template>

<script>
export default {
  name: "ExpertList",
  props: {
    dataArray: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    isTablet() {
      return this.$vssWidth < 1024;
    },
  },

  methods: {},
};
</script>
