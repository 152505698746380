var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{ref:"filterTrigger",staticClass:"calendar-filter__trigger"}),_c('div',{ref:"filter",class:[
      'calendar-filter',
      {
        'calendar-filter_closed': _vm.closed || _vm.isMobile,
        'calendar-filter_on-top': _vm.onTop,
      } ]},[(!_vm.isMobile)?_c('div',{staticClass:"calendar-filter__arrows"},[_c('div',{staticClass:"calendar-filter__arrow calendar-filter__arrow--prev",on:{"click":function($event){$event.preventDefault();return _vm.slideTo()}}},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"24","height":"24","viewBox":"0 0 24 24","fill":"none"}},[_c('path',{attrs:{"d":"M21 12L3 12","stroke":"#005DAA","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{attrs:{"d":"M10 19L3 12L10 5","stroke":"#005DAA","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}})])]),_c('div',{staticClass:"calendar-filter__arrow calendar-filter__arrow--next",on:{"click":function($event){$event.preventDefault();return _vm.slideTo('left')}}},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"24","height":"24","viewBox":"0 0 24 24","fill":"none"}},[_c('path',{attrs:{"d":"M3 12L21 12","stroke":"#005DAA","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{attrs:{"d":"M14 19L21 12L14 5","stroke":"#005DAA","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}})])])]):_vm._e(),(_vm.isMobile)?_c('div',{staticClass:"calendar-filter__trimester-tabs"},_vm._l((_vm.filterData),function(item,index){return _c('a',{key:("trimester-tab" + index),class:[
          'calendar-filter__trimester-title',
          {
            'calendar-filter__trimester-title_selected':
              item.name === _vm.currentTrimester,
          } ],attrs:{"href":""},on:{"click":function($event){$event.preventDefault();return _vm.scrollToTrimester(item.name)}}},[_vm._v(" "+_vm._s(_vm.getLatinNumber(item.name))+" "+_vm._s(_vm.trimesterName)+" "),_c('p',{staticClass:"calendar-filter__trimester-subtitle"},[_vm._v(" "+_vm._s(item.weeks[0].name)+" - "+_vm._s(item.weeks[item.weeks.length - 1].name)+" недели ")])])}),0):_vm._e(),_c('div',{ref:"filter-wrap",staticClass:"calendar-filter__trimester-wrap-wide"},[_c('div',{ref:"filter-wrap-inner",staticClass:"holder holder--straight calendar-filter__trimester-wrap"},_vm._l((_vm.filterData),function(item,index){return _c('div',{key:("trimester" + index),ref:("trimester" + (item.name)),refInFor:true,staticClass:"calendar-filter__trimester"},[_c('div',{staticClass:"calendar-filter__trimester-holder"},[(!_vm.isMobile)?_c('div',{staticClass:"calendar-filter__trimester-title"},[_vm._v(" "+_vm._s(_vm.getLatinNumber(item.name))+" "+_vm._s(_vm.trimesterName)+" ")]):_vm._e(),_c('p',{staticClass:"calendar-filter__trimester-subtitle"},[_vm._v(" "+_vm._s(item.weeks[0].name)+" - "+_vm._s(item.weeks[item.weeks.length - 1].name)+" недели ")])]),_c('div',{staticClass:"calendar-filter__weeks"},_vm._l((item.weeks),function(week,week_index){return _c('a',{key:("week" + week_index),ref:week.isActive === '1' ? 'week-selected' : undefined,refInFor:true,class:[
                'calendar-filter__week',
                { 'calendar-filter__week_selected': week.isActive === '1' } ],attrs:{"href":week.link}},[_vm._v(" "+_vm._s(week.name)+" ")])}),0)])}),0)])]),_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }