<template>
  <div :class="{'encyclopedia-list-wrap_state_load': store.isLoading}" class="encyclopedia-list-wrap">
    <slot/>
  </div>
</template>

<script>
import { useEncyclopediaStore } from "../stores/encyclopedia";

export default {
  name: 'ProductsInner',
  setup() {
    return {
      store: useEncyclopediaStore()
    }
  },
}
</script>
