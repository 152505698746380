<template>
  <div class="tabbed-content__top">

    <div class="tabbed-content__tabs">
      <div
        :class="[
          'products-main__tab',
          { 'products-main__tab_active': checkSelected('specialization', 'all', true) },
        ]"
        @click="setParam('specialization', 'all', true)"
      >
        Все
      </div>
      <div
        v-for="(item, index) in filterSpecializations"
        :key="`tab${index}`"
        :class="[
          `products-main__tab products-main__tab--${item.colorId}`,
          { 'products-main__tab_active': checkSelected('specialization', item.value, true) },
        ]"
        @click="setParam('specialization', item.value, true)"
      >
        {{ item.name }}
      </div>
    </div>

    <div class="tabbed-content__themes">
      <div class="article-filter">
        <!-- <div class="article-filter__mobile-handle g-visible-mobile">
          <h3 @click="mobileOpened = true">
            Фильтры
            <template v-if="filterCount > 0">
              ({{filterCount}})
            </template>
            <span>
              <img src="../assets/img/icons/filters.svg" alt="">
              <span v-if="filterCount > 0"></span>
            </span>
          </h3>
          <a class="article-filter__mobile-clear" v-if="filterCount > 0" :href="resetFilterLink">
            <img src="../assets/img/icons/cross2.svg" alt="">
          </a>
        </div> -->
        <div class="article-filter__mobile-handle g-visible-mobile" @click="toggleMobile">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M22 5C22 5.26522 21.8946 5.51957 21.7071 5.70711C21.5196 5.89464 21.2652 6 21 6L14 6C13.7348 6 13.4804 5.89464 13.2929 5.70711C13.1054 5.51957 13 5.26522 13 5C13 4.73478 13.1054 4.48043 13.2929 4.29289C13.4804 4.10536 13.7348 4 14 4L21 4C21.2652 4 21.5196 4.10536 21.7071 4.29289C21.8946 4.48043 22 4.73478 22 5ZM22 12C22 12.2652 21.8946 12.5196 21.7071 12.7071C21.5196 12.8946 21.2652 13 21 13L7 13C6.73478 13 6.48043 12.8946 6.29289 12.7071C6.10536 12.5196 6 12.2652 6 12C6 11.7348 6.10536 11.4804 6.29289 11.2929C6.48043 11.1054 6.73478 11 7 11L21 11C21.2652 11 21.5196 11.1054 21.7071 11.2929C21.8946 11.4804 22 11.7348 22 12ZM18 19C18 19.2652 17.8946 19.5196 17.7071 19.7071C17.5196 19.8946 17.2652 20 17 20L3 20C2.73478 20 2.48043 19.8946 2.29289 19.7071C2.10536 19.5196 2 19.2652 2 19C2 18.7348 2.10536 18.4804 2.29289 18.2929C2.48043 18.1054 2.73478 18 3 18L17 18C17.2652 18 17.5196 18.1054 17.7071 18.2929C17.8946 18.4804 18 18.7348 18 19ZM11 5C11 5.26522 10.8946 5.51957 10.7071 5.70711C10.5196 5.89464 10.2652 6 10 6L3 6C2.73478 6 2.48043 5.89464 2.29289 5.70711C2.10536 5.51957 2 5.26522 2 5C2 4.73478 2.10536 4.48043 2.29289 4.29289C2.48043 4.10536 2.73478 4 3 4L10 4C10.2652 4 10.5196 4.10536 10.7071 4.29289C10.8946 4.48043 11 4.73478 11 5Z" fill="#217BC4"/>
            <path d="M12 2C12.5933 2 13.1734 2.17595 13.6667 2.50559C14.1601 2.83524 14.5446 3.30377 14.7716 3.85195C14.9987 4.40013 15.0581 5.00333 14.9424 5.58527C14.8266 6.16721 14.5409 6.70176 14.1213 7.12132C13.7018 7.54088 13.1672 7.8266 12.5853 7.94236C12.0033 8.05811 11.4001 7.9987 10.8519 7.77164C10.3038 7.54458 9.83523 7.16006 9.50559 6.66671C9.17595 6.17336 9 5.59334 9 5C9 4.20435 9.31607 3.44129 9.87868 2.87868C10.4413 2.31607 11.2043 2 12 2ZM2 12C2 12.5933 2.17595 13.1734 2.50559 13.6667C2.83523 14.1601 3.30377 14.5446 3.85195 14.7716C4.40013 14.9987 5.00333 15.0581 5.58527 14.9424C6.16721 14.8266 6.70176 14.5409 7.12132 14.1213C7.54088 13.7018 7.8266 13.1672 7.94235 12.5853C8.05811 12.0033 7.9987 11.4001 7.77164 10.8519C7.54457 10.3038 7.16006 9.83524 6.66671 9.50559C6.17336 9.17595 5.59334 9 5 9C4.20435 9 3.44129 9.31607 2.87868 9.87868C2.31607 10.4413 2 11.2044 2 12ZM16 19C16 19.5933 16.1759 20.1734 16.5056 20.6667C16.8352 21.1601 17.3038 21.5446 17.8519 21.7716C18.4001 21.9987 19.0033 22.0581 19.5853 21.9424C20.1672 21.8266 20.7018 21.5409 21.1213 21.1213C21.5409 20.7018 21.8266 20.1672 21.9424 19.5853C22.0581 19.0033 21.9987 18.4001 21.7716 17.8519C21.5446 17.3038 21.1601 16.8352 20.6667 16.5056C20.1734 16.1759 19.5933 16 19 16C18.2043 16 17.4413 16.3161 16.8787 16.8787C16.3161 17.4413 16 18.2043 16 19Z" fill="#00B5ED"/>
          </svg>
          <h3>
            Фильтры
          </h3>
        </div>
        <div class="article-filter__fields-wrap" v-if="mobileOpened || !isMobile">
          <div class="article-filter__mobile-title g-visible-mobile">
            <h3>Фильтры</h3>
            <svg @click="toggleMobile" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M18 6L6 18" stroke="#00B5ED" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M6 6L18 18" stroke="#00B5ED" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <!-- <img src="../assets/img/icons/cross4.svg" alt="" @click="mobileOpened = false"> -->
          </div>
          <div :class="['article-filter__dropdown', {'article-filter__dropdown_opened': themesOpened}]">
            <!-- <h4 class="article-filter__dropdown-title g-visible-mobile">Тема</h4> -->
            <div
              :class="['article-filter__dropdown-value', { 'article-filter__dropdown-value_empty' : themesList.length === 0 }]"
              @click.stop="themesOpened = !themesOpened;"
            >
              <p>
                <span v-if="themesList.length > 0 && !isMobile">Тема:</span>
                <!-- <p>{{ themesList.length > 0 ? themesList.join(', ') : isMobile ? 'Любая' : 'Тема'}}</p> -->
                {{ isMobile ? 'Тема' : themesList.length > 0 ? themesList.join(', ') : 'Тема:'}}
              </p>
              <span
              v-if="themesList.length > 0 && !isMobile"
                @click.stop="setParam('themes', 'all', false)"
                title="Сбросить фильтр"
              >
                <img src="../assets/img/icons/cross.svg" alt="">
              </span>
            </div>
            <div class="article-filter__dropdown-options" v-if="themesOpened" v-click-outside="closeThemes">
              <div
                v-for="(item, index) in filterThemes"
                :key="`option${index}`"
                :class="['article-filter__dropdown-option', {'article-filter__dropdown-option_selected': checkSelected('themes', item.code, false)}]"
                @click="setParam('themes', item.code, false)"
              >
          <span class="article-filter__dropdown-option-box">
            <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 3.5L5 7.5L11 1.5" stroke="white" stroke-width="2"/>
            </svg>
          </span>
                <span class="article-filter__dropdown-option-label">{{ item.name }}</span>
              </div>
            </div>
          </div>
          <div class="article-filter__desktop-actions g-hidden-mobile">
            <a href="" @click.prevent="setQueryString">Применить</a>
            <a v-if="filterCount" :href="resetFilterLink" class="article-filter__clear-all g-hidden-mobile" @click.prevent="clearFilter">Сбросить</a>
          </div>
          <div class="article-filter__mobile-actions g-visible-mobile">
            <a href="" @click.prevent="setQueryString">Применить</a>
            <a v-if="filterCount" class="_clear" :href="resetFilterLink" @click.prevent="clearFilter">Сбросить</a>
          </div>
        </div>
      </div>
    </div>

    <div v-if="filterCount" class="tabbed-content__filter">
      <div class="article-filter__sort">
        <h3>Сортировать:</h3>
        <div class="article-filter__sort-select">
          <div class="article-filter__sort-select-value" @click.stop="sortOpened = !sortOpened">
            <svg v-if="isMobile" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M17 19V4M17 4L22 9.15625M17 4L12 9.15625" stroke="#00B5ED" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M7 4V19M7 19L12 13.8438M7 19L2 13.8438" stroke="#217BC4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            {{ currentSort.name }}
            <svg v-if="!isMobile" width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 1.5L5 5.5L9 1.5" stroke="#0275D8" stroke-width="2" stroke-linecap="round"/>
            </svg>
          </div>
          <ul :class="['article-filter__sort-select-options', {'article-filter__sort-select-options_state_open': sortOpened }]" v-click-outside="closeSort">
            <li
              v-for="item in sortOptions"
              :key="`sort${item.value}`"
              @click="setParam('sort', item.value, false, true); sortOpened = false;"
              :class="[{'_selected': item.value === active}]"
            >
              {{ item.name }}
            </li>
          </ul>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { useExpertsStore } from "../stores/experts";

export default {
  name: "ExpertFilter",
  components: {},
  props: {
    filterSpecializations: {
      type: Array,
      default: () => {return[]},
    },
    filterThemes: {
      type: Array,
      default: () => {return[]}
    },
    currentFilters: {
      type: Object,
      default: () => {return{}}
    },
    resetFilterLink: {
      type: String,
      default: '/experts/'
    },
    sortOptions: {
      type: Array,
      default: () => {return[]}
    },
    contentContainerId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      currentFilter: "all",
      opened: true,
      params: this.currentFilters,
      themesList: [],
      specialization: "all",
      themesOpened: false,
      mobileOpened: false,
      sortVisible: false,
      filterCount: 0,
      sortOpened: false,
      currentSort: this.sortOptions[0],
      queryString: '',
      store: useExpertsStore(),
      active: new URL(window.location).searchParams.get('sort') || this.sortOptions[0].value,
    };
  },

  computed: {
    isTablet() {
      return this.$vssWidth < 1024;
    },
    isMobile: function () {
      return this.$vssWidth < 768
    },
  },

  watch: {
    queryString() {
      this.checkValues();
    },
    mobileOpened() {
      this.params = {...this.currentFilters};
      this.checkValues();
    }
  },
  created() {
    this.queryString = `sort=${ this.currentFilters.sort }&themes=${ this.currentFilters.themes?? 'all' }`;
  },
  mounted() {
    this.checkValues();
    this.filterCount = this.themesList.length;
  },
  methods: {
    checkSelected(category, value, isRadio) {
      if (this.params[category] !== undefined) {
        if (isRadio) {
          if (this.params[category] === value) {
            return true;
          }
        } else {
          if (this.params[category].split(',').includes(value)) {
            return true;
          }
        }
      } else {
        if (value === 'all') {
          return true;
        }
      }
      return false;
    },
    setParam(category, value, isRadio, mobileReload = false) {
      if (isRadio) {
        this.params[category] = value;
      } else {
        if (value === 'all' || this.params[category] === undefined || category === 'sort') {
          this.params[category] = value;
        } else {
          let categorySet = new Set(this.params[category].split(','));
          categorySet.delete('all');
          if (categorySet.has(value)) {
            categorySet.delete(value);
            if (categorySet.size === 0) {
              categorySet.add('all');
            }
          } else {
            categorySet.add(value);
          }
          this.params[category] = [...categorySet].join(',');
        }
      }

      this.queryString = Object.keys(this.params).map(key => key + '=' + this.params[key]).join('&');
      if (mobileReload || category === 'specialization') {
        this.setQueryString()
      }

      if(category === 'sort') {
        this.active = value;
      }
    },
    closeThemes() {
      if (this.themesOpened) {
        this.themesOpened = false;
      }
    },
    closeSort() {
      if (this.sortOpened) {
        this.sortOpened = false;
      }
    },
    async setQueryString() {
      this.store
        .update(window.location.pathname + `?${this.queryString}`, window.location.pathname + '_ajax/' + `?${this.queryString}`)
        .then(() => {
          document.documentElement.classList.remove('html_state_filter');
        });
    },
    async clearFilter() {
      this.setParam('themes', 'all', false);

      this.store
        .update(window.location.pathname + `?${this.queryString}`, window.location.pathname + '_ajax/' + `?${this.queryString}`)
        .then(() => {
          document.documentElement.classList.remove('html_state_filter');
        });
    },
    checkValues() {
      if (this.params.themes) {
        const themesArray = this.params.themes.split(',');
        this.themesList = [];

        this.filterThemes.map((obj) => {
          if (themesArray.includes(obj.code)) {
            this.themesList.push(obj.name);
          }
        })
      }

      if (this.params.specialization) {
        this.filterSpecializations.map((obj) => {
          if (this.currentFilters.specialization === obj.code) {
            this.specialization = obj.value;
          }
        })
      }

      this.currentSort = this.sortOptions.find(obj => obj.value === this.currentFilters.sort);

      this.sortVisible = this.themesList.length > 0
    },
    toggleMobile() {
      this.mobileOpened = !this.mobileOpened;

      document.documentElement.classList.toggle('html_state_filter', this.mobileOpened);
    }
  }
};
</script>
