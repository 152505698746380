<template>
  <div class="characters-item">
    <div class="characters-item__picture">
      <svg
        preserveAspectRatio="none"
        viewBox="0 0 312 370"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="characters-item__back"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M28.6042 0C81.8535 0 98.9571 6.37627 141.532 6.37627C184.108 6.37627 225.102 0 283.101 0C298.833 0 311.164 12.0964 311.705 26.859C313.543 76.9684 306.191 92.5643 306.191 145.308C306.191 198.052 311.705 276.391 311.705 343.141C311.705 357.913 298.833 370 283.101 370C249.26 370 231.828 362.752 189.321 362.752C146.813 362.752 88.174 370 28.6042 370C12.8725 370 0 357.913 0 343.141C0 281.45 6.24878 266.43 6.24878 213.648C6.24878 160.865 0 102.13 0 26.859C0 12.0867 12.8727 0 28.6042 0Z"
          fill="#CAEDFF"
        />
      </svg>

      <div class="characters-item__apperance">
        <slot name="image">
          <picture
            v-if="character.background_image_webp || character.background_image"
            class="characters-item__image characters-item__image_background"
          >
            <source
              :srcset="character.background_image_webp"
              type="image/webp"
            />
            <img :src="character.background_image" loading="lazy" />
          </picture>

          <img
            class="characters-item__image characters-item__image_shadow"
            :src="require('../assets/img/characters/character-shadow.svg')"
          />

          <picture
            v-if="character.image_webp || character.image"
            class="characters-item__image characters-item__image_character"
          >
            <source :srcset="character.image_webp" type="image/webp" />
            <img :src="character.image" :alt="character.name" loading="lazy" />
          </picture>
        </slot>
      </div>
    </div>

    <div class="characters-item__name">{{ character.name }}</div>
  </div>
</template>

<script>
export default {
  name: "CharactersItem",

  props: {
    character: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss">
@import "../scss/base/includes.scss";

.characters-item {
  &__image {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  picture.characters-item__image {
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
