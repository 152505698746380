<template>
  <div
    :class="[
      'catalog-filter__option',
      { 'catalog-filter__option_selected': isActiveCategory() },
      { 'catalog-filter__option--loading': catalog.isLoading }
    ]"
  >
    <div
      @click.prevent="() => toggleType(params, false)"
      class="catalog-filter__checkbox"
    />
    <div
      :class="[
        'catalog-filter__option-title',
        { 'catalog-filter__option-title_opened': opened },
      ]"
    >
      <a
        @click.prevent="() => toggleType(params, options.length !== 0)"
      >
        {{ title }}
        <svg
          v-if="options.length !== 0"
          width="11"
          height="8"
          viewBox="0 0 11 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.05273 1.71289L5.05273 5.71289L9.05273 1.71289"
            stroke="#0275D8"
            stroke-width="2"
            stroke-linecap="round"
          />
        </svg>
      </a>
    </div>
    <ul class="catalog-filter__options" v-if="opened && options.length !== 0">
      <li
        v-for="(item, index) in options"
        :class="[
          'catalog-filter__option',
          { 'catalog-filter__option_selected': isActive(item.params.section) }
        ]"
        :key="`option${index}`"
      >
        <a
          :href="item.url"
          @click.prevent="() => toggleSection(item.params.section)"
          class="sss"
        >
          {{ item.title }}
          <span class="catalog-filter__option-box">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="10"
              viewBox="0 0 12 10"
              fill="none"
            >
              <path
                d="M1 5.2441L3.5 7.7441C3.96017 8.20427 4.7065 8.20427 5.16667 7.7441L11 1.91077"
                stroke="#00B5ED"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import { useCatalogStore } from "../stores/catalog";

export default {
  name: "FilterOptions",
  setup() {
    return {
      catalog: useCatalogStore(),
    };
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    link: {
      type: String,
      default: "",
    },
    params: {
      type: Object,
      default: () => {},
    },
    options: {
      type: Array,
      default: () => [],
    },
    isOpened: {
      type: Boolean,
      default: false,
    },
    selectedInside: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    isRoot: {
      type: Boolean,
      default: false,
    },
    ajax_url: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isCategoryOpen: false,
      opened: this.isOpened,
      activeIndexes: [],
    };
  },
  computed: {},
  methods: {
    toggleType(_params, toggle) {
      if(toggle) {
        this.opened = !this.opened;

        return false;
      }

      const sections = this.catalog.url.searchParams.get('section')?.split(',') || [];
      const typeIndexes = this.options.map(i => i.params.section);
      let activeIndexes = [];

      document.getElementsByClassName('catalog__title')[0].innerText = 'Каталог продукции';

      for (let key in _params) {
        const params = this.catalog.url.searchParams.get(key)?.split(',') || [];

        const index = params.indexOf(_params[key]);

        if(key === 'age') {
          params.splice(index, 1);

          if (index === -1) {
            params.push(_params[key]);
          }

          activeIndexes = [...new Set([...sections, ...typeIndexes])];
        } else {
          if (index === -1) {
            params.push(_params[key]);

            activeIndexes = [...new Set([...sections, ...typeIndexes])];
          } else {
            params.splice(index, 1);

            activeIndexes = sections.filter(i => !typeIndexes.includes(i));
          }
        }

        if(params.length) {
          this.catalog.setSearchParams(key, params.join(','));
        } else {
          this.catalog.deleteSearchParams(key);
        }

        if(activeIndexes.length) {
          this.catalog.setSearchParams('section', activeIndexes.join(','));

          this.opened = true;
        } else {
          this.catalog.deleteSearchParams('section');
        }

        this.catalog.deleteSearchParams('page');

        this.catalog.setHistory();
      }
    },
    toggleSection(section) {
      const sections = this.catalog.url.searchParams.get('section')?.split(',') || [];
      const index = sections.indexOf(section);

      if (index === -1) {
        sections.push(section);
      } else {
        sections.splice(index, 1);
      }

      if(sections.length) {
        this.catalog.setSearchParams('section', sections.join(','));
      } else {
        this.catalog.deleteSearchParams('section');
      }

      const params = this.catalog.url.searchParams.get('type')?.split(',') || [];
      const paramsIndex = params.indexOf(this.params.type);
      const paramsIncludes = this.options.filter(i => sections.indexOf(i.code) !== -1);

      if(!paramsIncludes.length) {
        params.splice(paramsIndex, 1);

        if(params.length) {
          this.catalog.setSearchParams('type', params.join(','));
        } else {
          this.catalog.deleteSearchParams('type');
        }
      } else if(paramsIndex === -1) {
        params.push(this.params.type);

        this.catalog.setSearchParams('type', params.join(','));
      }

      this.catalog.deleteSearchParams('page');

      this.catalog.setHistory();
    },
    isActiveCategory() {
      let active = false;

      for (let key in this.params) {
        const type = this.catalog.url.searchParams.get(key)?.split(',') || [];
        const index = type.indexOf(this.params[key]);

        if(index !== -1)
          active = true;
      }

      return active;
    },
    isActive(i) {
      const activeIndexes = this.catalog.url.searchParams.get('section')?.split(',') || [];

      return activeIndexes.indexOf(i) !== -1;
    },
  },
};
</script>
