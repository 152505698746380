<template>
  <div class="taxi-slider">
    <swiper ref="Swiper" :options="swiperOptions">
      <slot :slide-trigger="slideTrigger" />
      <swiper-slide v-for="(item, index) in dataArray" :key="index">
        <div :class="['taxi-slider__slide', `taxi-slider__slide_${index + 1}`, {'taxi-slider__slide_text-left': item.textLeft}, {'taxi-slider__slide_alt': (index + 1 === 2 && slide2Alt) || (index + 1 === 7 && slide7Alt)}]">
          <div class="taxi-slider__slide-wrap">
            <div class="taxi-slider__cols">
              <div class="taxi-slider__col taxi-slider__col_image">
                <div class="taxi-slider__switch-content">
                  <div>
                    <img :src="item.image" alt="">
                  </div>
                  <div class="_alt">
                    <img v-if="item.image2" :src="item.image2" alt="" class="_alt">
                  </div>
                </div>
              </div>
              <div class="taxi-slider__col taxi-slider__col_text">
                  <span class="taxi-slider__number">{{ index + 2 }}</span>
                  <div class="taxi-slider__switch-content">
                    <div>
                      <h2 v-html="item.title"></h2>
                      <p v-html="item.text"></p>
                      <!-- <a v-if="item.buttonText" class="button button_small" @click.stop.prevent="slideAction(index + 1)">{{ item.buttonText }}</a> -->
                    </div>
                    <div class="_alt">
                      <h2 v-if="item.title2" v-html="item.title2" class="_alt"></h2>
                      <p v-if="item.text2" v-html="item.text2" class="_alt"></p>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </swiper-slide>
    </swiper>
    <div :class="['taxi-slider__bullets']"></div>
  </div>
</template>

<script>
export default {
  name: "TaxiSlider",
  props: {
    dataArray: {
      type: Array,
      default: () => {return []}
    }
  },
  data() {
    return {
      slide2Alt: false,
      slide7Alt: false,
      swiperOptions: {
        slidesPerView: 1,
        watchOverflow: true,
        speed: 500,
        pagination: {
          el: ".taxi-slider__bullets",
          type: "bullets",
          bulletClass: "taxi-slider__bullet",
          bulletActiveClass: "taxi-slider__bullet_active",
          clickable: true
        },
      }
    };
  },
  computed: {
  },
  mounted() {
  },
  methods: {
    slideAction(index) {
      if (index === 2) {
        this.slide2Alt = true;
      }
      if (index === 7) {
        this.slide7Alt = true;
      }
    },
    slideTrigger() {
      this.$refs.Swiper.$swiper.slideTo(1, 500);
    }
  }
};
</script>

