<template>
  <div class="newborn-header__nav">
    <a href="#"
       :class="['button button_wider', {'button_bordered2': getCurrentType() !== 'tutorial'}]"
       @click.prevent="selectType('tutorial')"
    >Инструкции</a>
    <a href="#"
       :class="['button button_wider', {'button_bordered2': getCurrentType() !== 'article'}]"
       @click.prevent="selectType('article')"
    >Статьи</a>
  </div>
</template>

<script>
import axios from "axios";
import Vue from "vue";

import { useNewbornStore } from '../stores/newborn';

export default {
  name: 'NewbornTypeSelector',
  setup() {
    const newbornStore = useNewbornStore()
    return {
      newbornStore
    }
  },
  props: {
    currentType: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
    contentContainerId: {
      type: String,
      required: true,
    },
  },
  methods: {
    async selectType(selectedType) {
      const url = `${this.url}?type=${selectedType}`;
      try {
        const response = await axios.get(url);

        let AjaxComponent = Vue.extend({
          template: response.data
        })
        let compiled = new AjaxComponent().$mount()
        const container = document.getElementById(this.contentContainerId);
        container.innerHTML = compiled.$el.outerHTML;
        this.toggleType(selectedType);
      }
      catch (error) {
        console.log(error);
      }
    },
    getCurrentType() {
      if (this.currentType === 'tutorial') {
        return 'tutorial';
      }
      if (this.currentType === 'article') {
        return 'article';
      }

      return 'tutorial';
    },
    toggleType(selectedType) {
      this.currentType = selectedType;
      this.newbornStore.currentType = selectedType;
    }
  }
}
</script>
