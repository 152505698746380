<template>
  <div :class="[{'slider': `slider-${id}`}, {'slider_no-next-visible': !visibleNext}]">
    <swiper
      ref="Swiper"
      :options="swiperOptions"
      @click.native="handleClick"
    >
      <slot />
    </swiper>
    <div class="slider__controls" :class="{'g-hidden': slidesCount <= items}">
      <div :class="['slider__prev', `slider-${id}__prev`]">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path d="M21 12L3 12" stroke="#005DAA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M10 19L3 12L10 5" stroke="#005DAA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
      <div :class="['slider__next', `slider-${id}__next`]">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path d="M3 12L21 12" stroke="#005DAA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M14 19L21 12L14 5" stroke="#005DAA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import { useLikeStore } from "../stores/like";

export default {
  name: "Slider",
  setup() {
    return {
      store: useLikeStore()
    }
  },
  props: {
    id: {
      type: String,
      default: '0',
    },
    items: {
      type: Number,
      default: 3,
    },
    visibleNext: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      slidesCount: 0,
      swiperOptions: {
        slidesPerView: 1,
        spaceBetween: 0,
        watchOverflow: true,
        loop: true,
        loopAdditionalSlides: 1,
        navigation: {
          prevEl: `.slider-${this.id}__prev`,
          nextEl: `.slider-${this.id}__next`,
          disabledClass: "is-disabled"
        },
        pagination: {
          el: `.slider-${this.id}__bullets`,
          type: "bullets",
          bulletClass: "slider__bullet",
          bulletActiveClass: "slider__bullet_active",
          clickable: true
        },
        breakpoints: {
          768: {
            spaceBetween: '2,06186%',
            slidesPerView: 2,
          },
          1366: {
            // spaceBetween: '1.5625%',
            spaceBetween: '1.764705%',
            slidesPerView: 4,
          },
          // 1920: {
          //   spaceBetween: '1.7647%',
          //   slidesPerView: 4,
          // },
        },
        on: {
          slideChangeTransitionStart: function(swiper) {
            swiper.loopDestroy();
            swiper.loopCreate();
          }
        }
      }
    };
  },
  computed: {
    swiper() {
      return this.$refs.Swiper.$swiper;
    },
    isMobile: function () {
      return this.$vssWidth < 768
    }
  },
  mounted() {
    this.slidesCount = this.$refs.Swiper.$children.length;
  },
  methods: {
    handleClick(e) {
      const like = e.target.closest('.item-card__like');
      const duplicate = e.target.closest('.swiper-slide-duplicate');

      if(duplicate && like) {
        const id = like.dataset.elemId;
        const swiper = e.target.closest('.swiper-container');

        swiper.classList.add('like-load');

        this.store.submit({
          url: '/bitrix/services/main/ajax.php?action=dalee:project.vote.add',
          mode: 'like',
          type: 'element',
          iBlockId: '',
          id: id,
        }).then(() => {
          this.swiper.loopDestroy();
          this.swiper.loopCreate();

          setTimeout(() => {
            swiper.classList.remove('like-load');
          }, 100);
        })
      }
    },
  }
};
</script>

<style lang="scss">
  .like-load
  {
    & .item-card__image
    {
      & img
      {
        transition: none;
      }
    }
    & .item-card__like
    {
      pointer-events: none;
    }
  }

</style>
