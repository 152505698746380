<template>
  <div class="characters-slider__bullets-wrapper">
    <div class="characters-slider__grow">
      <div
        :class="[
          'characters-slider__bullets',
          {'characters-slider__bullets_hidden': slidesCount < 2}
        ]"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CharactersSliderBullets",

  props: {
    slidesCount: {
      type: Number,
      required: true
    }
  }
}
</script>

<style lang="scss">
@import "../scss/base/includes.scss";

.characters-slider {
  &__bullets-wrapper {
    display: flex;
    position: fixed;
    left: 0;
    // top: 0;
    right: 0;
    bottom: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // height: 100vh;
    z-index: 1;
    padding: 0 30px 30px;
  }

  &__grow {
    position: relative;
    flex-grow: 1;
    width: 100%;

    @include mobile {
      max-height: 540px;
    }
  }

  &__bullets {
    display: flex;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: -25px !important;
    justify-content: center;
    z-index: 1;
  }

  &__bullet {
    @include round(14px);
    border: 2px solid $accent;
    cursor: pointer;

    &:not(:last-child) {
      margin-right: 14px;
    }
    &_active {
      background: $accent;
    }
  }
}
</style>
