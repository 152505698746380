<template>
  <div ref="wrap">
    <slot/>
  </div>
</template>

<script>
import { useNewsStore } from "../stores/news";

export default {
  name: 'NewsPagination',
  setup() {
    return {
      store: useNewsStore(),
    }
  },
  mounted() {
    this.$refs.wrap.querySelectorAll('a').forEach(a => {
      const pageUrl = new URL(a.href);
      const newUrl = new URL(location.href);
      const page = pageUrl.searchParams.get('page');

      if(page) {
        newUrl.searchParams.set('page', page);
      }
      else {
        newUrl.searchParams.delete('page');
      }

      a.href = newUrl.href;

      a.addEventListener('click', e => {
        e.preventDefault();

        window.scrollTo(0,0);
        this.store.update(newUrl.href, this.getAjaxLink(page));
      })
    })
  },
  methods: {
    getAjaxLink(page) {
      const url = new URL(location.origin + this.store.ajaxLink + location.search);
      if(!page) {
        url.searchParams.delete('page');
      }
      else {
        url.searchParams.set('page', page);
      }
      return url.href;
    }
  }
}
</script>
