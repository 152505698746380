import axios from "axios";
import { defineStore } from "pinia";

const LIKE_KEY = 'liked';
const SESSION_KEY = 'sessionId';

export const useLikeStore = defineStore('like', {
  state: () => ({
    likes: JSON.parse(localStorage.getItem(LIKE_KEY) || '[]').reduce((acc, next) => {
      return {
        ...acc,
        [next]: {
          isActive: true
        }
      }
    }, {})
  }),
  getters: {
  },
  actions: {
    async submit({url, id, mode, type, iblockid}) {
      const sessionId = document.querySelectorAll('[name="csrf-token"]')[0].getAttribute("content");

      let liked = JSON.parse(localStorage.getItem(LIKE_KEY) || '[]');

      const savedSessionId = localStorage.getItem(SESSION_KEY);

      if (savedSessionId && sessionId !== savedSessionId) {
        liked = []
      }

      const isCancel = liked.includes(id);

      const fetchUrl = `${url}&id=${id}&mode=${mode}&sessid=${sessionId}&type=${type}&iblockid=${iblockid}&cancel=${isCancel}`;

      try {
        const response = await axios.get(fetchUrl);
        if(response.data.status === "success") {
          this.likes = {
            ...this.likes,
            [id]: {
              isActive: !isCancel,
              quantity: response.data.data.likes,
            }
          };

          if (isCancel) {
            const likesSet = new Set(liked);
            likesSet.delete(id);
            liked = Array.from(likesSet);
          }
          else {
            liked.push(id);
          }

          localStorage.setItem(SESSION_KEY, sessionId);
          localStorage.setItem(LIKE_KEY, JSON.stringify(liked));
        } else {
          alert(response.data.errors[0].message)
        }
      }
      catch (error) {
        console.log(error);
      }

    }
  },
})
