<template>
  <div class="nutritional-table" v-if="dataArrayCur.length > 0">
    <div class="nutritional-table__holder">
      <table>
        <tbody>
          <template v-for="(item, index) in dataArrayCur">
            <tr :key="`row${index}`" v-if="opened || index < 10">
              <td>
                <template>{{ item.col1 }}</template>
                <template>{{ item.col2 }}</template>
              </td>
              <td>
                <b>
                  <template>{{ item.col3 }}</template>
                </b>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
    <div
      @click="showMore"
      v-if="dataArrayCur.length > 10"
      :class="[
        'nutritional-table__more',
        { 'nutritional-table__more--active': opened },
      ]"
    >
      <span>
        Показать еще
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 7" fill="none">
          <path
            d="M0.999511 1.00043L5.99951 6.00043L10.9995 1.00043"
            stroke="#00B5ED"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </span>
      <span>
        Скрыть
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="none">
          <path
            d="M3 11.0013L8 6.00134L13 11.0013"
            stroke="#00B5ED"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "NutritionalTable",
  props: {
    dataArray: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      opened: false,
      dataArrayCur: [],
    };
  },
  computed: {},
  created() {},

  mounted() {
    this.dataArrayCur = this.arrayWithoutElements(this.dataArray);
  },

  methods: {
    showMore() {
      this.opened = !this.opened;
    },

    resizeHandler() {},

    arrayWithoutElements(arr) {
      return arr.filter(function (value) {
        return (
          value.col1 !== "Энергетическая ценность, ккал" &&
          value.col1 !== "Энергетическая ценность, кДж"
        );
      });
    },
  },
};
</script>
