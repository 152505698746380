<template>
  <div :class="['header__menu-wrap2', {'header__menu-wrap_subitems-opened': subitemsOpened}]">
    <slot :openSubitems="openSubitems" :closeSubitems="closeSubitems" :toggleSubitemsLvl2="toggleSubitemsLvl2"/>
  </div>
</template>

<script>
export default {
  name: 'HeaderMenu2',
  props: {
  },
  data() {
    return {
      subitemsOpened: false
    }
  },
  computed: {
    isTablet: function () {
      return this.$vssWidth < 1024
    }
  },
  mounted() {
  },
  methods: {
    openSubitems(e, val) {
      if (this.isTablet) {
        e.preventDefault();
        this.subitemsOpened = true;
        document.getElementById(val).classList.add('is-opened');
        document.getElementById('drop_menu').scrollTop = 0;
      }
    },
    closeSubitems(val) {
      this.subitemsOpened = false;
      setTimeout(() => document.getElementById(val).classList.remove('is-opened'), 300);
    },
    toggleSubitemsLvl2(e, val) {
      if (this.isTablet) {
        e.preventDefault();
        const item = document.getElementById(val);
        if (item.classList.contains('is-opened')) {
          document.getElementById(val).classList.remove('is-opened');
        } else {
          document.getElementById(val).classList.add('is-opened');
        }
      }
    }
  }
}
</script>
