<template>
  <div>
    <Portal :disabled="!isPopup">
      <div
        class="quiz-form-wrapper"
        :class="{ 'quiz-form-wrapper--popup': isPopup }"
        v-click-outside="closePopup"
      >
        <div
          :class="['quiz-form-floating-button', { 'quiz-form-floating-button_state_open': popupExpanded || !button}]"
          v-if="isPopup && isEnabled"
        >
          <div @click="togglePopup">
            <picture class="quiz-form-floating-button__img">
              <source
                srcset="/frontend/assets/img/quiz/quiz-form-floating-button__img_1-1920.svg"
                media="(min-width: 768px)"
              >
              <img
                src="/frontend/assets/img/quiz/quiz-form-floating-button__img_1-375.svg"
                alt="облако"
              >
            </picture>
            <span>
              Мини-опрос
            </span>
          </div>
          <button
            class="quiz-form-floating-button__close"
            @click="toggleButton"
          >
            <img
              src="/frontend/assets/img/quiz/quiz-form__close-button-icon_1-1920.svg"
              alt="крестик"
            >
          </button>
        </div>

        <div class="quiz-form" v-if="(!isPopup || popupExpanded) && activeQuestion">
          <div
            v-if="isPopup"
            class="quiz-form__close-button"
            @click="closePopup"
          >
            <picture class="quiz-form__close-button-icon">
              <source
              srcset="/frontend/assets/img/quiz/quiz-form__close-button-icon_1-1920.svg"
                media="(min-width: 768px)"
              >
              <img
              src="/frontend/assets/img/quiz/quiz-form__close-button-icon_1-375.svg"
                alt="крестик"
              >
            </picture>
          </div>

          <svg class="quiz-form__bg" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 426 540" fill="none"><g filter="url(#a)"><path fill="#fff" fill-rule="evenodd" d="M39.056 0c72.706 0 96.059 9.306 154.19 9.306C251.378 9.306 307.351 0 386.541 0c21.48 0 38.36 18.9 39.056 39.2 2.51 73.132-7.528 95.894-7.528 172.871s7.529 191.311 7.528 288.729c0 21.56-17.575 39.2-39.056 39.2-46.205 0-70.006-10.578-128.045-10.578-58.039 0-138.105 10.578-219.44 10.578C17.576 540 0 522.36 0 500.8c0-90.035 8.532-111.957 8.532-188.99C8.532 234.776 0 149.054 0 39.2 0 17.64 17.576 0 39.056 0Z" clip-rule="evenodd"/></g><defs><filter id="a" width="426" height="540" x="0" y="0" color-interpolation-filters="sRGB" filterUnits="userSpaceOnUse"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"/><feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/><feOffset/><feGaussianBlur stdDeviation="12"/><feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic"/><feColorMatrix values="0 0 0 0 0.623529 0 0 0 0 0.831373 0 0 0 0 0.937255 0 0 0 1 0"/><feBlend in2="shape" result="effect1_innerShadow_21_57"/><feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/><feOffset/><feGaussianBlur stdDeviation="8"/><feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic"/><feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"/><feBlend in2="effect1_innerShadow_21_57" result="effect2_innerShadow_21_57"/></filter></defs></svg>

          <template v-if="!isPopup && randomArt === 1">
            <picture class="quiz-form__img quiz-form__img_1">
              <source
                srcset="/frontend/assets/img/quiz/quiz-form__img_1-1920.webp, /frontend/assets/img/quiz/quiz-form__img_1-1920@2x.webp 2x"
                media="(min-width: 768px)"
              >
              <img
                srcset="/frontend/assets/img/quiz/quiz-form__img_1-375.webp, /frontend/assets/img/quiz/quiz-form__img_1-375@2x.webp 2x"
                src="/frontend/assets/img/quiz/quiz-form__img_1-375.webp"
                alt="ребенок"
              >
            </picture>
          </template>

          <template v-if="!isPopup && randomArt === 2">
            <picture class="quiz-form__img quiz-form__img_2">
              <source
                srcset="/frontend/assets/img/quiz/quiz-form__img_2-1920.webp, /frontend/assets/img/quiz/quiz-form__img_2-1920@2x.webp 2x"
                media="(min-width: 768px)"
              >
              <img
                srcset="/frontend/assets/img/quiz/quiz-form__img_4-375.webp, /frontend/assets/img/quiz/quiz-form__img_4-375@2x.webp 2x"
                src="/frontend/assets/img/quiz/quiz-form__img_4-375.webp"
                alt="цветок"
              >
            </picture>
          </template>

          <template v-if="!isPopup && randomArt === 3">
            <picture class="quiz-form__img quiz-form__img_3">
              <source
                srcset="/frontend/assets/img/quiz/quiz-form__img_3-1920.webp, /frontend/assets/img/quiz/quiz-form__img_3-1920@2x.webp 2x"
                media="(min-width: 768px)"
              >
              <img
                srcset="/frontend/assets/img/quiz/quiz-form__img_3-375.webp, /frontend/assets/img/quiz/quiz-form__img_3-375@2x.webp 2x"
                src="/frontend/assets/img/quiz/quiz-form__img_3-375.webp"
                alt="ребенок"
              >
            </picture>
          </template>

          <template v-if="!isPopup && randomArt === 4">
            <picture class="quiz-form__img quiz-form__img_4">
              <source
                srcset="/frontend/assets/img/quiz/quiz-form__img_4-1920.webp, /frontend/assets/img/quiz/quiz-form__img_4-1920@2x.webp 2x"
                media="(min-width: 768px)"
              >
              <img
                srcset="/frontend/assets/img/quiz/quiz-form__img_4-375.webp, /frontend/assets/img/quiz/quiz-form__img_4-375@2x.webp 2x"
                src="/frontend/assets/img/quiz/quiz-form__img_4-375.webp"
                alt="птица"
              >
            </picture>
          </template>

          <template v-if="!isPopup && randomArt === 5">
            <picture class="quiz-form__img quiz-form__img_5">
              <source
                srcset="/frontend/assets/img/quiz/quiz-form__img_5-1920.webp, /frontend/assets/img/quiz/quiz-form__img_5-1920@2x.webp 2x"
                media="(min-width: 768px)"
              >
              <img
                srcset="/frontend/assets/img/quiz/quiz-form__img_5-375.webp, /frontend/assets/img/quiz/quiz-form__img_5-375@2x.webp 2x"
                src="/frontend/assets/img/quiz/quiz-form__img_5-375.webp"
                alt="заяц"
              >
            </picture>
          </template>

          <template v-if="isPopup && randomArt === 1">
            <picture class="quiz-form-popup__img quiz-form-popup__img_1">
              <source
                srcset="/frontend/assets/img/quiz/quiz-form-popup__img_1-1920.webp, /frontend/assets/img/quiz/quiz-form-popup__img_1-1920@2x.webp 2x"
                media="(min-width: 768px)"
              >
              <img
                srcset="/frontend/assets/img/quiz/quiz-form-popup__img_1-375.webp, /frontend/assets/img/quiz/quiz-form-popup__img_1-375@2x.webp 2x"
                src="/frontend/assets/img/quiz/quiz-form-popup__img_1-375.webp"
                alt="ребенок"
              >
            </picture>
          </template>

          <template v-if="isPopup && randomArt === 2">
            <picture class="quiz-form-popup__img quiz-form-popup__img_2">
              <source
                srcset="/frontend/assets/img/quiz/quiz-form-popup__img_2-1920.webp, /frontend/assets/img/quiz/quiz-form-popup__img_2-1920@2x.webp 2x"
                media="(min-width: 768px)"
              >
              <img
                srcset="/frontend/assets/img/quiz/quiz-form-popup__img_2-375.webp, /frontend/assets/img/quiz/quiz-form-popup__img_2-375@2x.webp 2x"
                src="/frontend/assets/img/quiz/quiz-form-popup__img_2-375.webp"
                alt="ребенок"
              >
            </picture>
          </template>

          <template v-if="isPopup && randomArt === 3">
            <picture class="quiz-form-popup__img quiz-form-popup__img_3">
              <source
                srcset="/frontend/assets/img/quiz/quiz-form-popup__img_3-1920.webp, /frontend/assets/img/quiz/quiz-form-popup__img_3-1920@2x.webp 2x"
                media="(min-width: 768px)"
              >
              <img
                srcset="/frontend/assets/img/quiz/quiz-form-popup__img_3-1920.webp, /frontend/assets/img/quiz/quiz-form-popup__img_3-1920@2x.webp 2x"
                src="/frontend/assets/img/quiz/quiz-form-popup__img_3-1920.webp"
                alt="трава"
              >
            </picture>
            <picture class="quiz-form-popup__img quiz-form-popup__img_4">
              <source
                srcset="/frontend/assets/img/quiz/quiz-form-popup__img_4-1920.webp, /frontend/assets/img/quiz/quiz-form-popup__img_4-1920@2x.webp 2x"
                media="(min-width: 768px)"
              >
              <img
                srcset="/frontend/assets/img/quiz/quiz-form-popup__img_4-375.webp, /frontend/assets/img/quiz/quiz-form-popup__img_4-375@2x.webp 2x"
                src="/frontend/assets/img/quiz/quiz-form-popup__img_4-375.webp"
                alt="пчела"
              >
            </picture>
          </template>

          <template v-if="isPopup && randomArt === 4">
            <picture class="quiz-form-popup__img quiz-form-popup__img_5">
              <source
                srcset="/frontend/assets/img/quiz/quiz-form-popup__img_5-1920.webp, /frontend/assets/img/quiz/quiz-form-popup__img_5-1920@2x.webp 2x"
                media="(min-width: 768px)"
              >
              <img
                srcset="/frontend/assets/img/quiz/quiz-form-popup__img_5-1920.webp, /frontend/assets/img/quiz/quiz-form-popup__img_5-1920@2x.webp 2x"
                src="/frontend/assets/img/quiz/quiz-form-popup__img_5-1920.webp"
                alt="цветок"
              >
            </picture>
            <picture class="quiz-form-popup__img quiz-form-popup__img_6">
              <source
                srcset="/frontend/assets/img/quiz/quiz-form-popup__img_6-1920.webp, /frontend/assets/img/quiz/quiz-form-popup__img_6-1920@2x.webp 2x"
                media="(min-width: 768px)"
              >
              <img
                srcset="/frontend/assets/img/quiz/quiz-form-popup__img_6-1920.webp, /frontend/assets/img/quiz/quiz-form-popup__img_6-1920@2x.webp 2x"
                src="/frontend/assets/img/quiz/quiz-form-popup__img_6-1920.webp"
                alt="трава"
              >
            </picture>
            <picture class="quiz-form-popup__img quiz-form-popup__img_7">
              <source
                srcset="/frontend/assets/img/quiz/quiz-form-popup__img_7-1920.webp, /frontend/assets/img/quiz/quiz-form-popup__img_7-1920@2x.webp 2x"
                media="(min-width: 768px)"
              >
              <img
                srcset="/frontend/assets/img/quiz/quiz-form-popup__img_7-1920.webp, /frontend/assets/img/quiz/quiz-form-popup__img_7-1920@2x.webp 2x"
                src="/frontend/assets/img/quiz/quiz-form-popup__img_7-1920.webp"
                alt="божья коровка"
              >
            </picture>
          </template>

          <template v-if="isPopup && randomArt === 5">
            <picture class="quiz-form-popup__img quiz-form-popup__img_3">
              <source
                srcset="/frontend/assets/img/quiz/quiz-form-popup__img_3-1920.webp, /frontend/assets/img/quiz/quiz-form-popup__img_3-1920@2x.webp 2x"
                media="(min-width: 768px)"
              >
              <img
                srcset="/frontend/assets/img/quiz/quiz-form-popup__img_3-1920.webp, /frontend/assets/img/quiz/quiz-form-popup__img_3-1920@2x.webp 2x"
                src="/frontend/assets/img/quiz/quiz-form-popup__img_3-1920.webp"
                alt="трава"
              >
            </picture>
            <picture class="quiz-form-popup__img quiz-form-popup__img_8">
              <source
                srcset="/frontend/assets/img/quiz/quiz-form-popup__img_4-1920.webp, /frontend/assets/img/quiz/quiz-form-popup__img_4-1920@2x.webp 2x"
                media="(min-width: 768px)"
              >
              <img
                srcset="/frontend/assets/img/quiz/quiz-form-popup__img_8-375.webp, /frontend/assets/img/quiz/quiz-form-popup__img_8-375@2x.webp 2x"
                src="/frontend/assets/img/quiz/quiz-form-popup__img_8-375.webp"
                alt="солнышко"
              >
            </picture>
          </template>

          <div class="quiz-form__content">
            <div class="quiz-form__title">Поделитесь своим мнением</div>

            <div class="quiz-form__question-title">
              {{ activeQuestion.name }}
            </div>

            <template v-if="!answered">
              <p v-if="activeQuestionIsOfSingleType" class="quiz-form__question-topic">
                Выберите 1 вариант ответа
              </p>
              <p v-else class="quiz-form__question-topic">
                Выберите не&nbsp;менее 1 варианта ответа
              </p>
            </template>

            <div
              v-if="activeQuestion.count_results !== undefined && answered"
              class="quiz-form__question-subtitle"
            >
              Уже проголосовало
              <span class="quiz-form__question-subtitle-count">{{
                activeQuestion.count_results
              }}</span>
            </div>

            <div class="quiz-form__answers">
              <template v-if="!answered">
                <CustomCheckbox
                  v-for="answer of activeQuestion.answers"
                  :key="answer.id"
                  :label="answer.name"
                  :class="{
                    'quiz-single-choice': activeQuestionIsOfSingleType,
                  }"
                  name="v"
                  alternate
                  :v="$v"
                  :checked="checkAnswerSelected(answer.id)"
                  @input="(checked) => handleInput(checked, answer.id)"
                />
              </template>

              <template v-else>
                <div
                  v-for="answer of activeQuestion.answers"
                  :key="answer.id"
                  class="quiz-form__result-item"
                >
                  <div class="quiz-form__result-item-percent">
                    {{ answer.percent }}%
                  </div>

                  <div class="quiz-form__result-item-bar-container">
                    <div class="quiz-form__result-item-name-container">
                      <div class="quiz-form__result-item-name">
                        {{ answer.name }}
                      </div>
                      <svg
                        v-if="answer.answered"
                        class="quiz-form__result-item-check"
                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 10" fill="none"><path stroke="#00B5ED" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 5.244 2.5 2.5c.46.46 1.207.46 1.667 0L11 1.911"/>
                      </svg>
                    </div>
                    <div class="quiz-form__result-item-progress">
                      <div
                        class="quiz-form__result-item-bar"
                        :style="getResultBarComputedStyles(answer)"
                      ></div>
                    </div>
                  </div>
                </div>
              </template>
            </div>

            <div class="quiz-form__buttons">
              <button
                v-if="!answered"
                href="#"
                class="button quiz-form__button"
                :disabled="!voteButtonEnabled"
                @click.prevent="answerQuestion"
              >
                Проголосовать
              </button>

              <template v-else>
                <a
                  v-if="nextQuestionAvailable && !isPopup"
                  href="#"
                  class="button quiz-form__button button_bordered"
                  @click.prevent="selectNextQuestion"
                >
                  Следующий вопрос
                </a>
              </template>

              <a
                  href="#"
                  :class="['button', 'quiz-form__button', { 'quiz-form__button_mod_white': !answered }]"
                  @click.prevent="nextQuestion"
                >
                  Другой вопрос
                </a>
            </div>

            <div class="quiz-form__bullets" v-if="questions.length > 1">
              <template>
                <div
                  v-for="question of questions"
                  :key="question.id"
                  class="quiz-form__bullet"
                  :class="{
                    'quiz-form__bullet--active':
                      activeQuestion.id === question.id,
                  }"
                  @click="selectQuestion(question)"
                ></div>
              </template>
            </div>

            <div
              v-if="activeQuestion.count_results !== undefined && !answered"
              class="quiz-form__question-subtitle"
            >
              Уже проголосовало
              <span class="quiz-form__question-subtitle-count">{{
                activeQuestion.count_results
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </Portal>
  </div>
</template>

<script>
import axios from "axios";

import { Portal } from "@linusborg/vue-simple-portal";

import CustomCheckbox from "./CustomCheckbox.vue";

const FRUIT_IMAGES_BASE_PATH = "/frontend/assets/img/polls";
const POPUP_OPENED_ON_MOBILE_SESSION_KEY = "POPUP_OPENED_ON_MOBILE_SESSION_KEY";

const FRUIT_IMAGES = [
  "apple.png",
  "pear.png",
  "raspberry.png",
  "strawberry.png",
];

const getRandomArrayItem = (arr) => {
  return arr[Math.floor(Math.random() * arr.length)];
};

const getRandomInt = (min, max) => {
  return Math.floor(Math.random() * (max - min) + min);
};

const getRandomInteger = (min, max) => {
  return Math.floor(min + Math.random() * (max + 1 - min));
}

export default {
  props: {
    isPopup: {
      type: Boolean,
      default: false
    },
    pollIds: {
      type: Array,
      required: true,
      default: () => {return[]}
    },
    actionGetQuestions: {
      type: String, required: true
    },
    actionAddAnswer: {
      type: String, required: true
    },
  },

  components: {
    CustomCheckbox,
    Portal,
  },

  data() {
    return {
      popupExpandedOnce: false,
      popupExpanded: false,
      questions: [],
      selectedAnswerId: null,
      selectedAnswerIds: [],
      answered: false,
      activeQuestion: null,
      pollId: null,
      button: false,
      answeredPolls: JSON.parse(localStorage.getItem('answered-polls') || '[]'),
      startPosition: 0,
    };
  },

  async created() {
    await this.getQuestions();
  },

  mounted() {
    this.openButtonAfterTimeout();
  },

  computed: {
    randomArt() {
      return getRandomInteger(1, 5);
    },
    randomFruitImageSrc() {
      return `${FRUIT_IMAGES_BASE_PATH}/${getRandomArrayItem(FRUIT_IMAGES)}`;
    },

    voteButtonEnabled() {
      return (
        this.selectedAnswerId !== null || this.selectedAnswerIds.length !== 0
      );
    },

    activeQuestionIndex() {
      return this.questions.findIndex(
        (question) => question.id === this.activeQuestion.id
      );
    },

    activeQuestionIsOfSingleType() {
      return this.activeQuestion.type === "single";
    },

    nextQuestionAvailable() {
      return this.activeQuestionIndex !== this.questions.length - 1;
    },

    isEnabled() {
      if (this.popupExpanded) {
        return true
      }
      return this.pollIds.some(id => {
        return this.answeredPolls.indexOf(id) < 0;
      })
    },
  },

  methods: {
    getPollId() {
      let enabledPolls = [];
      this.pollIds.forEach(id => {
        if (this.answeredPolls.indexOf(id) < 0) {
          enabledPolls.push(id);
        }
      })

      if (enabledPolls.length === 0) {
        enabledPolls = this.pollIds;

        this.answeredPolls = [];
        // return null;
      }
      return getRandomArrayItem(enabledPolls);
    },
    openPopupAfterTimeout() {
      if (!this.isPopup) {
        return;
      }

      if (!sessionStorage.getItem(POPUP_OPENED_ON_MOBILE_SESSION_KEY)) {
        sessionStorage.setItem(POPUP_OPENED_ON_MOBILE_SESSION_KEY, true);
      } else return;

      const timeout = getRandomInt(5000, 7000);
      setTimeout(() => {
        if (!this.popupExpandedOnce) {
          this.popupExpanded = true;
        }
      }, timeout);
    },
    openButtonAfterTimeout() {
      if (!this.isPopup) {
        return;
      }

      const timeout = getRandomInteger(5000, 7000);
      setTimeout(() => { this.button = true }, timeout);
    },

    async getQuestions() {
      const pollId = this.getPollId();

      if (!pollId) {
        return;
      }
      const { status, data } = await axios.get(this.actionGetQuestions, {
        params: {
          pollId,
        },
      });

      if (status === 200) {
        this.pollId = pollId;
        this.questions = data.data;
        this.activeQuestion = this.questions[0];
      }
    },

    async answerQuestion() {

      const { status, data } = await axios.get(this.actionAddAnswer, {
        params: {
          questionId: this.activeQuestion.id,
          answerId: this.activeQuestionIsOfSingleType
            ? this.selectedAnswerId
            : this.selectedAnswerIds,
        },
      });

      if (status === 200) {
        this.answeredPolls.push(this.pollId);
        localStorage.setItem('answered-polls', JSON.stringify(this.answeredPolls));

        this.answered = true;
        this.activeQuestion.count_results = data.data.count_all;

        for (const answer of this.activeQuestion.answers) {
          answer.percent = data.data.answers.find(
            (a) => a.id === answer.id
          ).percent;

          answer.answered = this.activeQuestionIsOfSingleType
            ? this.selectedAnswerId === answer.id
            : this.selectedAnswerIds.includes(answer.id);
        }
      }
    },

    async reset() {
      this.pollId = null;

      if (this.answered) {
        await this.getQuestions();
      }

      this.answered = false;
      this.selectedAnswerId = null;
      this.selectedAnswerIds = [];
      for (const answer of this.activeQuestion.answers) {
        answer.answered = false;
      }
    },

    async nextQuestion() {
      this.pollId = null;

      await this.getQuestions();

      this.answered = false;
      this.selectedAnswerId = null;
      this.selectedAnswerIds = [];
      for (const answer of this.activeQuestion.answers) {
        answer.answered = false;
      }
    },

    handleInput(checked, answerId) {
      if (this.activeQuestionIsOfSingleType) {
        this.selectedAnswerId = checked ? answerId : null;
      } else {
        this.selectedAnswerIds = checked
          ? [...this.selectedAnswerIds, answerId]
          : this.selectedAnswerIds.filter((id) => id !== answerId);
      }
    },

    checkAnswerSelected(answerId) {
      return (
        this.selectedAnswerId === answerId ||
        this.selectedAnswerIds.includes(answerId)
      );
    },

    selectQuestion(question) {
      if (this.activeQuestion.id === question.id) {
        return;
      }

      this.reset();
      this.activeQuestion = question;
    },

    selectNextQuestion() {
      this.selectQuestion(this.questions[this.activeQuestionIndex + 1]);
    },

    getResultBarComputedStyles(answer) {
      // need to show a little something when 0% or 1%
      return {
        width: `${Math.max(2, answer.percent)}%`,
      };
    },

    openPopup() {
      if (!this.popupExpandedOnce) {
        this.popupExpandedOnce = true;
      }
      this.popupExpanded = true;

      document.documentElement.classList.add('html_state_quiz');

      this.startPosition = window.scrollY;
      window.addEventListener('scroll', this.onWindowScroll);
    },

    togglePopup() {
      this.popupExpanded ? this.closePopup() : this.openPopup();
    },

    toggleButton() {
      this.button = false;
    },

    closePopup() {
      if (this.isPopup && this.popupExpanded) {
        this.popupExpanded = false;
      }

      this.reset();

      document.documentElement.classList.remove('html_state_quiz');
      window.removeEventListener('scroll', this.onWindowScroll);
    },

    onWindowScroll() {
      if(Math.abs(this.startPosition - window.scrollY) > (window.innerHeight * 2)) {
        this.closePopup();
      }
    },
  },

  validations: {},
};
</script>
