<template>
  <div>
    <div v-if="this.count >= 1" class="html-bg html-bg_count_1">
      <picture class="html-bg__bg">
        <source srcset="/frontend/assets/img/html-bg/html-bg__bg-1920-1.webp, /frontend/assets/img/html-bg/html-bg__bg-1920-1@2x.webp 2x" media="(min-width: 1366px)">
        <img
          srcset="/frontend/assets/img/html-bg/html-bg__bg-1024-1.webp, /frontend/assets/img/html-bg/html-bg__bg-1024-1@2x.webp 2x"
          src="/frontend/assets/img/html-bg/html-bg__bg-1024-1.webp"
          alt="Трава"
        >
      </picture>
      <img
        :class="`html-bg__art-${ artLeft[0] }`"
        :srcset="`/frontend/assets/img/html-bg/html-bg__art-${ artLeft[0] }.webp, /frontend/assets/img/html-bg/html-bg__art-${ artLeft[0] }@2x.webp 2x`"
        :src="`/frontend/assets/img/html-bg/html-bg__art-${ artLeft[0] }.webp`"
      >
      <img
        :class="`html-bg__art-${ artRight[0] }`"
        :srcset="`/frontend/assets/img/html-bg/html-bg__art-${ artRight[0] }.webp, /frontend/assets/img/html-bg/html-bg__art-${ artRight[0] }@2x.webp 2x`"
        :src="`/frontend/assets/img/html-bg/html-bg__art-${ artRight[0] }.webp`"
      >
    </div>

    <div v-if="this.count >= 2" class="html-bg html-bg_count_2">
      <picture class="html-bg__bg">
        <source srcset="/frontend/assets/img/html-bg/html-bg__bg-1920-2.webp, /frontend/assets/img/html-bg/html-bg__bg-1920-2@2x.webp 2x" media="(min-width: 1366px)">
        <img
          srcset="/frontend/assets/img/html-bg/html-bg__bg-1024-2.webp, /frontend/assets/img/html-bg/html-bg__bg-1024-2@2x.webp 2x"
          src="/frontend/assets/img/html-bg/html-bg__bg-1024-2.webp"
          alt="Трава"
        >
      </picture>
      <img
        :class="`html-bg__art-${ artLeft[1] }`"
        :srcset="`/frontend/assets/img/html-bg/html-bg__art-${ artLeft[1] }.webp, /frontend/assets/img/html-bg/html-bg__art-${ artLeft[1] }@2x.webp 2x`"
        :src="`/frontend/assets/img/html-bg/html-bg__art-${ artLeft[1] }.webp`"
      >
      <img
        :class="`html-bg__art-${ artRight[1] }`"
        :srcset="`/frontend/assets/img/html-bg/html-bg__art-${ artRight[1] }.webp, /frontend/assets/img/html-bg/html-bg__art-${ artRight[1] }@2x.webp 2x`"
        :src="`/frontend/assets/img/html-bg/html-bg__art-${ artRight[1] }.webp`"
      >
    </div>

    <div v-if="this.count >= 3" class="html-bg html-bg_count_3">
      <picture class="html-bg__bg">
        <source srcset="/frontend/assets/img/html-bg/html-bg__bg-1920-1.webp, /frontend/assets/img/html-bg/html-bg__bg-1920-1@2x.webp 2x" media="(min-width: 1366px)">
        <img
          srcset="/frontend/assets/img/html-bg/html-bg__bg-1024-1.webp, /frontend/assets/img/html-bg/html-bg__bg-1024-1@2x.webp 2x"
          src="/frontend/assets/img/html-bg/html-bg__bg-1024-1.webp"
          alt="Трава"
        >
      </picture>
      <img
        :class="`html-bg__art-${ artLeft[2] }`"
        :srcset="`/frontend/assets/img/html-bg/html-bg__art-${ artLeft[2] }.webp, /frontend/assets/img/html-bg/html-bg__art-${ artLeft[2] }@2x.webp 2x`"
        :src="`/frontend/assets/img/html-bg/html-bg__art-${ artLeft[2] }.webp`"
      >
      <img
        :class="`html-bg__art-${ artRight[2] }`"
        :srcset="`/frontend/assets/img/html-bg/html-bg__art-${ artRight[2] }.webp, /frontend/assets/img/html-bg/html-bg__art-${ artRight[2] }@2x.webp 2x`"
        :src="`/frontend/assets/img/html-bg/html-bg__art-${ artRight[2] }.webp`"
      >
    </div>

    <div v-if="this.count >= 4" class="html-bg html-bg_count_4">
      <picture class="html-bg__bg">
        <source srcset="/frontend/assets/img/html-bg/html-bg__bg-1920-2.webp, /frontend/assets/img/html-bg/html-bg__bg-1920-2@2x.webp 2x" media="(min-width: 1366px)">
        <img
          srcset="/frontend/assets/img/html-bg/html-bg__bg-1024-2.webp, /frontend/assets/img/html-bg/html-bg__bg-1024-2@2x.webp 2x"
          src="/frontend/assets/img/html-bg/html-bg__bg-1024-2.webp"
          alt="Трава"
        >
      </picture>
      <img
        :class="`html-bg__art-${ artLeft[3] }`"
        :srcset="`/frontend/assets/img/html-bg/html-bg__art-${ artLeft[3] }.webp, /frontend/assets/img/html-bg/html-bg__art-${ artLeft[3] }@2x.webp 2x`"
        :src="`/frontend/assets/img/html-bg/html-bg__art-${ artLeft[3] }.webp`"
      >
      <img
        :class="`html-bg__art-${ artRight[3] }`"
        :srcset="`/frontend/assets/img/html-bg/html-bg__art-${ artRight[3] }.webp, /frontend/assets/img/html-bg/html-bg__art-${ artRight[3] }@2x.webp 2x`"
        :src="`/frontend/assets/img/html-bg/html-bg__art-${ artRight[3] }.webp`"
      >
    </div>

    <div v-if="this.count >= 5" class="html-bg html-bg_count_5">
      <picture class="html-bg__bg">
        <source srcset="/frontend/assets/img/html-bg/html-bg__bg-1920-1.webp, /frontend/assets/img/html-bg/html-bg__bg-1920-1@2x.webp 2x" media="(min-width: 1366px)">
        <img
          srcset="/frontend/assets/img/html-bg/html-bg__bg-1024-1.webp, /frontend/assets/img/html-bg/html-bg__bg-1024-1@2x.webp 2x"
          src="/frontend/assets/img/html-bg/html-bg__bg-1024-1.webp"
          alt="Трава"
        >
      </picture>
      <img
        :class="`html-bg__art-${ artLeft[0] }`"
        :srcset="`/frontend/assets/img/html-bg/html-bg__art-${ artLeft[0] }.webp, /frontend/assets/img/html-bg/html-bg__art-${ artLeft[0] }@2x.webp 2x`"
        :src="`/frontend/assets/img/html-bg/html-bg__art-${ artLeft[0] }.webp`"
      >
      <img
        :class="`html-bg__art-${ artRight[0] }`"
        :srcset="`/frontend/assets/img/html-bg/html-bg__art-${ artRight[0] }.webp, /frontend/assets/img/html-bg/html-bg__art-${ artRight[0] }@2x.webp 2x`"
        :src="`/frontend/assets/img/html-bg/html-bg__art-${ artRight[0] }.webp`"
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    reset: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      timer: null,
      count: null,
      artLeft: [1,3,5,7],
      artRight: [2,4,6,8],
    };
  },
  created() {
    this.artLeft = this.shuffle(this.artLeft);
    this.artRight = this.shuffle(this.artRight);
  },
  mounted() {
    window.addEventListener('load', this.onWindowLoad);

    const observer = new ResizeObserver(() => {
      this.resizeListener();
    });
    observer.observe(document.body);
  },
  beforeDestroy() {
    window.removeEventListener('load', this.onWindowLoad);
  },
  methods: {
    onWindowLoad() {
      this.changeClass();
    },
    changeClass() {
      const scrollHeight = Math.max(
        document.body.scrollHeight, document.documentElement.scrollHeight,
        document.body.offsetHeight, document.documentElement.offsetHeight,
        document.body.clientHeight, document.documentElement.clientHeight
      );

      const count = {
        1: window.innerWidth >= 1366 ? 5000 * window.innerWidth / 1920 : 4360 * window.innerWidth / 1024,
        2: window.innerWidth >= 1366 ? 7560 * window.innerWidth / 1920 : 6260 * window.innerWidth / 1024,
        3: window.innerWidth >= 1366 ? 9840 * window.innerWidth / 1920 : 8160 * window.innerWidth / 1024,
        4: window.innerWidth >= 1366 ? 12120 * window.innerWidth / 1920 : 10060 * window.innerWidth / 1024,
        5: window.innerWidth >= 1366 ? 14400 * window.innerWidth / 1920 : 11960 * window.innerWidth / 1024,
      }

      if(scrollHeight >= count[1] && scrollHeight < count[2] && window.innerWidth >= 768) {
        this.count = 1;
      } else if (scrollHeight >= count[2] && scrollHeight < count[3] && window.innerWidth >= 768) {
        this.count = 2;
      } else if (scrollHeight >= count[3] && scrollHeight < count[4] && window.innerWidth >= 768) {
        this.count = 3;
      } else if (scrollHeight >= count[4] && scrollHeight < count[5] && window.innerWidth >= 768) {
        this.count = 4;
      } else if (scrollHeight >= count[5] && window.innerWidth >= 768) {
        this.count = 5;
      } else {
        this.count = null;
      }

      document.querySelector('html').classList.toggle('html_count_1', this.count === 1);
      document.querySelector('html').classList.toggle('html_count_2', this.count === 2);
      document.querySelector('html').classList.toggle('html_count_3', this.count === 3);
      document.querySelector('html').classList.toggle('html_count_4', this.count === 4);
      document.querySelector('html').classList.toggle('html_count_5', this.count === 5);
    },
    debounce(func, timeout = 300) {
      const _this = this;

      return (...args) => {
        clearTimeout(_this.timer);
        _this.timer = setTimeout(() => { func.apply(this, args) }, timeout);
      };
    },
    resizeListener() {
      if(this.reset) {
        document.querySelector('html').classList.remove('html_count_1');
        document.querySelector('html').classList.remove('html_count_2');
        document.querySelector('html').classList.remove('html_count_3');
        document.querySelector('html').classList.remove('html_count_4');
        document.querySelector('html').classList.remove('html_count_5');
      }

      const _this = this;

      const processChange = _this.debounce(_this.changeClass);

      processChange();
    },
    shuffle(arr) {
      for (let i = arr.length - 1; i > 0; i--) {
        let j = Math.floor(Math.random() * (i + 1));
        [arr[i], arr[j]] = [arr[j], arr[i]];
      }

      return arr;
    },
  }
};
</script>

<style lang="scss">
@import '../scss/base/includes.scss';

.html-bg
{
  position: absolute;
  left: 0;

  display: none;

  width: 100%;

  @include fluid('margin-top', 0, -480px, -640px, -640px);

  &_count
  {
    &_1
    {
      @include bp-from($sm)
      {
        .html_count_1 &,
        .html_count_2 &,
        .html_count_3 &,
        .html_count_4 &,
        .html_count_5 &
        {
          display: block;
        }
      }
      .html_count_1 &
      {
        @include fluid('top', 50%);
      }
      .html_count_2 &
      {
        @include fluid('top', 33%);
      }
      .html_count_3 &
      {
        @include fluid('top', 25%);
      }
      .html_count_4 &
      {
        @include fluid('top', 20%);
      }
      .html_count_5 &
      {
        @include fluid('top', 16.67%);
      }
    }
    &_2
    {
      @include bp-from($sm)
      {
        .html_count_2 &,
        .html_count_3 &,
        .html_count_4 &,
        .html_count_5 &
        {
          display: block;
        }
      }
      .html_count_2 &
      {
        @include fluid('top', 66%);
      }
      .html_count_3 &
      {
        @include fluid('top', 50%);
      }
      .html_count_4 &
      {
        @include fluid('top', 40%);
      }
      .html_count_5 &
      {
        @include fluid('top', 33.34%);
      }
    }
    &_3
    {
      @include bp-from($sm)
      {
        .html_count_3 &,
        .html_count_4 &,
        .html_count_5 &
        {
          display: block;
        }
      }
      .html_count_3 &
      {
        @include fluid('top', 75%);
      }
      .html_count_4 &
      {
        @include fluid('top', 60%);
      }
      .html_count_5 &
      {
        @include fluid('top', 50%);
      }
    }
    &_4
    {
      @include bp-from($sm)
      {
        .html_count_4 &,
        .html_count_5 &
        {
          display: block;
        }
      }
      .html_count_4 &
      {
        @include fluid('top', 80%);
      }
      .html_count_5 &
      {
        @include fluid('top', 66.68%);
      }
    }
    &_5
    {
      @include bp-from($sm)
      {
        .html_count_5 &
        {
          display: block;

          @include fluid('top', 83.35%);
        }
      }
    }
  }
  &__bg
  {
    & img
    {
      display: block;

      width: 100%;
    }
  }
  &__art-1
  {
    position: absolute;
    z-index: 2;
    left: 0;

    display: block;

    @include fluid('top', 0, 199px, -72px, -72px);
    @include fluid('width', 0, 90px, 238px, 238px);
  }
  &__art-2
  {
    position: absolute;
    z-index: 2;
    right: 0;

    display: block;

    @include fluid('bottom', 0, 205px, 42px, 42px);
    @include fluid('width', 0, 90px, 238px, 238px);
  }
  &__art-3
  {
    position: absolute;
    z-index: 2;
    left: 0;

    display: block;

    @include fluid('top', 0, 214px, 193px, 193px);
    @include fluid('width', 0, 90px, 240px, 240px);
  }
  &__art-4
  {
    position: absolute;
    z-index: 2;
    right: 0;

    display: block;

    @include fluid('bottom', 0, 198px, 50px, 50px);
    @include fluid('width', 0, 90px, 238px, 238px);
  }
  &__art-5
  {
    position: absolute;
    z-index: 2;
    left: 0;

    display: block;

    @include fluid('top', 0, 160px, -1px, -1px);
    @include fluid('width', 0, 90px, 238px, 238px);
  }
  &__art-6
  {
    position: absolute;
    z-index: 2;
    right: 0;

    display: block;

    @include fluid('bottom', 0, 182px, 80px, 80px);
    @include fluid('width', 0, 90px, 238px, 238px);
  }
  &__art-7
  {
    position: absolute;
    z-index: 2;
    left: 0;

    display: block;

    @include fluid('top', 0, 208px, 358px, 358px);
    @include fluid('width', 0, 90px, 240px, 240px);
  }
  &__art-8
  {
    position: absolute;
    z-index: 2;
    right: 0;

    display: block;

    @include fluid('bottom', 0, 200px, 204px, 204px);
    @include fluid('width', 0, 90px, 238px, 238px);
  }
}

</style>
